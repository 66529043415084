


import React from 'react';
import Navigation from './Navigation';
import PropTypes from 'prop-types';



class Header extends React.Component {

	constructor() {
		super();
	}


	render() {
		return (
			<div className="c-site-header">
				<div className="l-content-width">
					<h2>Header</h2>
					<Navigation userDetails={this.props.userDetails} history={this.props.history} logOut={this.props.logOut} updateState={this.props.updateState} />
				</div>
			</div>
		);
	}

}

Header.contextTypes = {
	router: PropTypes.object
}


export default Header;