import { serverCall } from '../modules/ServerComms';
import Dialogs from '../modules/Dialogs';


function createJob(theJob, theCallback, theJWTToken) {

	serverCall("POST", '/api/job/createjob', theJob, function (resStatus, resJson) {

		var responseString;


		if (resStatus == "400" || resStatus == 400) {

			if (resJson.errorString == "nouserdata") {
				responseString = "ERROR: NO USER DATA";
			} else if (resJson.errorString == "nouserwiththatemail") {
				responseString = "ERROR: USER NOT FOUND";
			} else if (resJson.errorString == "novalidjobspecdate") {
				responseString = "ERROR: NO VALID JOB SPEC DATE";
			} else if (resJson.errorString == "jobspecdatepast") {
				responseString = "ERROR: JOB SPEC DATE IN THE PAST";
			} else if (resJson.errorString == "jobspecdatemorethanninetydays") {
				responseString = "ERROR: JOB SPEC DATE MORE THAN 90 DAYS AHEAD";
			} else if (resJson.errorString == "novalidjobspectime") {
				responseString = "ERROR: NO VALID JOB SPEC TIME";
			} else if (resJson.errorString == "novalidjobspecitemtype") {
				responseString = "ERROR: NO VALID JOB SPEC ITEM TYPE";

			} else if (resJson.errorString == "novalidjobspecservicetype") {
				responseString = "ERROR: NO VALID JOB SPEC SERVICE TYPE";
			} else if (resJson.errorString == "novalidjobspecsubservicetype") {
				responseString = "ERROR: NO VALID JOB SPEC SUB SERVICE TYPE";
			} else if (resJson.errorString == "novalidjobspecserviceescription") {
				responseString = "ERROR: NO VALID JOB SERVICE DESCRIPTION";
			} else if (resJson.errorString == "invalidexpectedprice") {
				responseString = "ERROR: NO VALID EXPECTED PRICE";

			} else if (resJson.errorString == "novalidjobspecitemname") {
				responseString = "ERROR: NO VALID JOB SPEC ITEM NAME";
			} else if (resJson.errorString == "novalidjobspecitemweight") {
				responseString = "ERROR: NO VALID JOB SPEC ITEM WEIGHT";
			} else if (resJson.errorString == "novalidjobspecitemweightextra") {
				responseString = "ERROR: NO VALID JOB SPEC ITEM WEIGHT EXTRA";
			} else if (resJson.errorString == "novalidjobspecitemdescription") {
				responseString = "ERROR: NO VALID JOB SPEC DESCRIPTION";
			} else if (resJson.errorString == "novalidjobspecadditionalinfo") {
				responseString = "ERROR: NO VALID JOB SPEC ADDITIONAL INFO";
			} else if (resJson.errorString == "nodestinationaddress") {
				responseString = "ERROR: NO ORIGIN ADDRESS";
			} else if (resJson.errorString == "invalidoriginaddress") {
				responseString = "ERROR: INVALID ORIGIN ADDRESS";
			} else if (resJson.errorString == "nodestinationaddress") {
				responseString = "ERROR: NO DESTINATION ADDRESS";
			} else if (resJson.errorString == "invaliddestinationaddress") {
				responseString = "ERROR: INVALID DESTINATION ADDRESS";
			} else if (resJson.errorString == "unauthorised") {
				responseString = "ERROR: USER NOT AUTHORISED";
			} else if (resJson.errorString == "usernotactive") {
				responseString = "ERROR: USER NOT ACTIVE";
			} else if (resJson.errorString == "mavscantcreatejob") {
				responseString = "ERROR: MAVS CANT CREATE JOBS";
			} else if (resJson.errorString == "adminscantcreatejob") {
				responseString = "ERROR: ADMINS CANT CREATE JOBS";
			} else if (resJson.errorString == "jobnotsaved") {
				responseString = "ERROR: JOB NOT SAVED";
			} else if (resJson.errorString == "nojobsfound") {
				responseString = "ERROR: NO JOBS FOUND";
			} else if (resJson.errorString == "usernotsaved") {
				responseString = "ERROR: USER NOT SAVED";
			} else {
				responseString = "ERROR: UNKNOWN";
			}

			return theCallback(resStatus, responseString, resJson);

		} else if (resStatus == "401" || resStatus == 401) {

			if (resJson.errorString == "unauthorised") {
				responseString = "ERROR: USER NOT AUTHORISED";
			} else if (resJson.errorString == "userblocked") {
				responseString = "ERROR: USER BLOCKED";
			} else if (resJson.errorString == "outdatedappversion") {
				responseString = "ERROR: OUTDATED VERSION";
			} else {
				responseString = "ERROR: UNKNOWN";
			}

			return theCallback(resStatus, responseString, resJson);

		} else if (resStatus == "504" || resStatus == 504 || resStatus == "502" || resStatus == 502) {

			responseString = "ERROR: TIMEOUT";
			return theCallback(resStatus, responseString, false);

		} else if (resStatus == "200" || resStatus == 200) {

			if (resJson.successString == "jobcreated") {
				responseString = "SUCCESS: JOB CREATED";
			}

			return theCallback(resStatus, responseString, resJson);
		}



	}, theJWTToken);

}

function retrieveJob(theJob, theCallback, theJWTToken) {//"59e8e6ecf7a8fa140848c744"

	serverCall("POST", '/api/job/retrievejob', theJob, function (resStatus, resJson) {

		var responseString;


		if (resStatus == "400" || resStatus == 400) {

			if (resJson.errorString == "nouserdata") {
				responseString = "ERROR: NO USER DATA";
			} else if (resJson.errorString == "novalidjobid") {
				responseString = "ERROR: NO VALID JOB ID";
			} else if (resJson.errorString == "nojobfound") {
				responseString = "ERROR: NO JOB FOUND";
			} else if (resJson.errorString == "jobclosed") {
				responseString = "ERROR: JOB CLOSED";
			} else if (resJson.errorString == "unauthorised") {
				responseString = "ERROR: USER NOT AUTHORISED";
			} else if (resJson.errorString == "userblocked") {
				responseString = "ERROR: USER BLOCKED";
			} else if (resJson.errorString == "outdatedappversion") {
				responseString = "ERROR: OUTDATED VERSION";
			} else {
				responseString = "ERROR: UNKNOWN";
			}

			return theCallback(resStatus, responseString, resJson);

		} else if (resStatus == "401" || resStatus == 401) {

			if (resJson.errorString == "unauthorised") {
				responseString = "ERROR: USER NOT AUTHORISED";
			} else if (resJson.errorString == "userblocked") {
				responseString = "ERROR: USER BLOCKED";
			} else if (resJson.errorString == "outdatedappversion") {
				responseString = "ERROR: OUTDATED VERSION";
			} else {
				responseString = "ERROR: UNKNOWN";
			}

			return theCallback(resStatus, responseString, resJson);

		} else if (resStatus == "504" || resStatus == 504 || resStatus == "502" || resStatus == 502) {

			responseString = "ERROR: TIMEOUT";
			return theCallback(resStatus, responseString, false);

		} else if (resStatus == "200" || resStatus == 200) {
			if (resJson.successString == "jobretrieved") {
				responseString = "SUCCESS: JOB RETRIEVED";
			}

			return theCallback(resStatus, responseString, resJson);
		}



	}, theJWTToken);

}

function updateJob(theJob, theCallback, theJWTToken, theCallbackCallback) {//jobalreadycomplete

	serverCall("POST", '/api/job/updatejob', theJob, function (resStatus, resJson) {

		var responseString;


		if (resStatus == "400" || resStatus == 400) {

			if (resJson.errorString == "nodata") {
				responseString = "ERROR: NO DATA";
			} else if (resJson.errorString == "nouserdata") {
				responseString = "ERROR: NO USER DATA";
			} else if (resJson.errorString == "novalidjobid") {
				responseString = "ERROR: NO VALID JOB ID";
			} else if (resJson.errorString == "novalidjobstatus") {
				responseString = "ERROR: NO VALID JOB STATUS";
			} else if (resJson.errorString == "novalidjobspecdate") {
				responseString = "ERROR: NO VALID JOB SPEC DATE";
			} else if (resJson.errorString == "jobspecdatepast") {
				responseString = "ERROR: JOB SPEC DATE IN THE PAST";
			} else if (resJson.errorString == "novalidjobspectime") {
				responseString = "ERROR: NO VALID JOB SPEC TIME";
			} else if (resJson.errorString == "jobspecdatemorethanninetydays") {
				responseString = "ERROR: JOB SPEC DATE MORE THAN 90 DAYS AHEAD";
			} else if (resJson.errorString == "novalidjobspecitemtype") {
				responseString = "ERROR: NO VALID JOB SPEC ITEM TYPE";

			} else if (resJson.errorString == "novalidjobspecservicetype") {
				responseString = "ERROR: NO VALID JOB SPEC SERVICE TYPE";
			} else if (resJson.errorString == "novalidjobspecsubservicetype") {
				responseString = "ERROR: NO VALID JOB SPEC SUB SERVICE TYPE";
			} else if (resJson.errorString == "novalidjobspecserviceescription") {
				responseString = "ERROR: NO VALID JOB SERVICE DESCRIPTION";
			} else if (resJson.errorString == "invalidexpectedprice") {
				responseString = "ERROR: NO VALID EXPECTED PRICE";


			} else if (resJson.errorString == "novalidjobspecitemname") {
				responseString = "ERROR: NO VALID JOB SPEC ITEM NAME";
			} else if (resJson.errorString == "novalidjobspecitemweight") {
				responseString = "ERROR: NO VALID JOB SPEC ITEM WEIGHT";
			} else if (resJson.errorString == "novalidjobspecitemweightextra") {
				responseString = "ERROR: NO VALID JOB SPEC ITEM WEIGHT EXTRA";
			} else if (resJson.errorString == "novalidjobspecitemdescription") {
				responseString = "ERROR: NO VALID JOB SPEC DESCRIPTION";
			} else if (resJson.errorString == "novalidjobspecadditionalinfo") {
				responseString = "ERROR: NO VALID JOB SPEC ADDITIONAL INFO";
			} else if (resJson.errorString == "invalidoriginaddress") {
				responseString = "ERROR: INVALID ORIGIN ADDRESS";
			} else if (resJson.errorString == "invaliddestinationaddress") {
				responseString = "ERROR: INVALID DESTINATION ADDRESS";
			} else if (resJson.errorString == "unauthorised") {
				responseString = "ERROR: USER NOT AUTHORISED";
			} else if (resJson.errorString == "usernotactive") {
				responseString = "ERROR: USER NOT ACTIVE";
			} else if (resJson.errorString == "jobalreadycomplete") {
				responseString = "ERROR: JOB ALREADY COMPLETE";
			} else if (resJson.errorString == "jobagreed") {
				responseString = "ERROR: JOB ALREADY AGREED";
			} else if (resJson.errorString == "jobnotsaved") {
				responseString = "ERROR: JOB NOT SAVED";
			} else if (resJson.errorString == "nojobfound") {
				responseString = "ERROR: NO JOB FOUND";
			} else {
				responseString = "ERROR: UNKNOWN";
			}

			return theCallback(resStatus, responseString, resJson, theCallbackCallback);

		} else if (resStatus == "401" || resStatus == 401) {

			if (resJson.errorString == "unauthorised") {
				responseString = "ERROR: USER NOT AUTHORISED";
			} else if (resJson.errorString == "userblocked") {
				responseString = "ERROR: USER BLOCKED";
			} else if (resJson.errorString == "outdatedappversion") {
				responseString = "ERROR: OUTDATED VERSION";
			} else {
				responseString = "ERROR: UNKNOWN";
			}

			return theCallback(resStatus, responseString, resJson, theCallbackCallback);

		} else if (resStatus == "504" || resStatus == 504 || resStatus == "502" || resStatus == 502) {

			responseString = "ERROR: TIMEOUT";
			return theCallback(resStatus, responseString, false, theCallbackCallback);

		} else if (resStatus == "200" || resStatus == 200) {

			if (resJson.successString == "jobupdated") {
				responseString = "SUCCESS: JOB UPDATED";
			}

			return theCallback(resStatus, responseString, resJson, theCallbackCallback);
		}



	}, theJWTToken);

}

function cancelJob(theJob, theCallback, theJWTToken) {

	serverCall("POST", '/api/job/canceljob', theJob, function (resStatus, resJson) {

		var responseString;

		if (resStatus == "400" || resStatus == 400) {

			if (resJson.errorString == "nouserdata") {
				responseString = "ERROR: NO USER DATA";
			} else if (resJson.errorString == "novalidjobid") {
				responseString = "ERROR: NO VALID JOB ID";
			} else if (resJson.errorString == "unauthorised") {
				responseString = "ERROR: USER NOT AUTHORISED";
			} else if (resJson.errorString == "usernotactive") {
				responseString = "ERROR: USER NOT ACTIVE";
			} else if (resJson.errorString == "jobalreadycomplete") {
				responseString = "ERROR: JOB ALREADY COMPLETE";
			} else if (resJson.errorString == "jobalreadyclosed") {
				responseString = "ERROR: JOB ALREADY CLOSED";
			} else if (resJson.errorString == "jobnotdeleted") {
				responseString = "ERROR: JOB NOT DELETED";
			} else if (resJson.errorString == "nojobfound") {
				responseString = "ERROR: NO JOB FOUND";
			} else {
				responseString = "ERROR: UNKNOWN";
			}

			return theCallback(resStatus, responseString, resJson);

		} else if (resStatus == "401" || resStatus == 401) {

			if (resJson.errorString == "unauthorised") {
				responseString = "ERROR: USER NOT AUTHORISED";
			} else if (resJson.errorString == "userblocked") {
				responseString = "ERROR: USER BLOCKED";
			} else if (resJson.errorString == "outdatedappversion") {
				responseString = "ERROR: OUTDATED VERSION";
			} else {
				responseString = "ERROR: UNKNOWN";
			}

			return theCallback(resStatus, responseString, resJson);

		} else if (resStatus == "504" || resStatus == 504 || resStatus == "502" || resStatus == 502) {

			responseString = "ERROR: TIMEOUT";
			return theCallback(resStatus, responseString, false);

		} else if (resStatus == "200" || resStatus == 200) {

			if (resJson.successString == "jobcancelled") {
				responseString = "SUCCESS: JOB CANCELLED";
			}

			return theCallback(resStatus, responseString, resJson);
		}



	}, theJWTToken);

}

function deleteJob(theJob, theCallback, theJWTToken) {

	serverCall("POST", '/api/job/deletejob', theJob, function (resStatus, resJson) {


		var responseString;


		if (resStatus == "400" || resStatus == 400) {

			if (resJson.errorString == "nouserdata") {
				responseString = "ERROR: NO USER DATA";
			} else if (resJson.errorString == "novalidjobid") {
				responseString = "ERROR: NO VALID JOB ID";
			} else if (resJson.errorString == "unauthorised") {
				responseString = "ERROR: USER NOT AUTHORISED";
			} else if (resJson.errorString == "usernotactive") {
				responseString = "ERROR: USER NOT ACTIVE";
			} else if (resJson.errorString == "jobalreadycomplete") {
				responseString = "ERROR: JOB ALREADY COMPLETE";
			} else if (resJson.errorString == "jobagreed") {
				responseString = "ERROR: JOB ALREADY AGREED";
			} else if (resJson.errorString == "jobnotdeleted") {
				responseString = "ERROR: JOB NOT DELETED";
			} else if (resJson.errorString == "nojobfound") {
				responseString = "ERROR: NO JOB FOUND";
			} else if (resJson.errorString == "nojobsfound") {
				responseString = "ERROR: NO JOBS FOUND";
			} else {
				responseString = "ERROR: UNKNOWN";
			}

			return theCallback(resStatus, responseString, resJson);

		} else if (resStatus == "401" || resStatus == 401) {

			if (resJson.errorString == "unauthorised") {
				responseString = "ERROR: USER NOT AUTHORISED";
			} else if (resJson.errorString == "userblocked") {
				responseString = "ERROR: USER BLOCKED";
			} else if (resJson.errorString == "outdatedappversion") {
				responseString = "ERROR: OUTDATED VERSION";
			} else {
				responseString = "ERROR: UNKNOWN";
			}

			return theCallback(resStatus, responseString, resJson);

		} else if (resStatus == "504" || resStatus == 504 || resStatus == "502" || resStatus == 502) {

			responseString = "ERROR: TIMEOUT";
			return theCallback(resStatus, responseString, false);

		} else if (resStatus == "200" || resStatus == 200) {

			if (resJson.successString == "jobdeleted") {
				responseString = "SUCCESS: JOB DELETED";
			}

			return theCallback(resStatus, responseString, resJson);
		}



	}, theJWTToken);

}

function archiveJob(theJob, theCallback, theJWTToken) {

	serverCall("POST", '/api/job/archivejob', theJob, function (resStatus, resJson) {


		var responseString;


		if (resStatus == "400" || resStatus == 400) {

			if (resJson.errorString == "nouserdata") {
				responseString = "ERROR: NO USER DATA";
			} else if (resJson.errorString == "novalidjobid") {
				responseString = "ERROR: NO VALID JOB ID";
			} else if (resJson.errorString == "unauthorised") {
				responseString = "ERROR: USER NOT AUTHORISED";
			} else if (resJson.errorString == "usernotactive") {
				responseString = "ERROR: USER NOT ACTIVE";
			} else if (resJson.errorString == "jobnotcompleteorcancelled") {
				responseString = "ERROR: JOB NOT COMPLETE / CANCELLED";
			} else if (resJson.errorString == "nojobfound") {
				responseString = "ERROR: NO JOB FOUND";
			} else {
				responseString = "ERROR: UNKNOWN";
			}

			return theCallback(resStatus, responseString, resJson);

		} else if (resStatus == "401" || resStatus == 401) {

			if (resJson.errorString == "unauthorised") {
				responseString = "ERROR: USER NOT AUTHORISED";
			} else if (resJson.errorString == "userblocked") {
				responseString = "ERROR: USER BLOCKED";
			} else if (resJson.errorString == "outdatedappversion") {
				responseString = "ERROR: OUTDATED VERSION";
			} else {
				responseString = "ERROR: UNKNOWN";
			}

			return theCallback(resStatus, responseString, resJson);

		} else if (resStatus == "504" || resStatus == 504 || resStatus == "502" || resStatus == 502) {

			responseString = "ERROR: TIMEOUT";
			return theCallback(resStatus, responseString, false);

		} else if (resStatus == "200" || resStatus == 200) {

			if (resJson.successString == "jobarchived") {
				responseString = "SUCCESS: JOB ARCHIVED";
			}

			return theCallback(resStatus, responseString, resJson);
		}



	}, theJWTToken);

}

function unArchiveJob(theJob, theCallback, theJWTToken) {

	serverCall("POST", '/api/job/unarchivejob', theJob, function (resStatus, resJson) {


		var responseString;


		if (resStatus == "400" || resStatus == 400) {

			if (resJson.errorString == "nouserdata") {
				responseString = "ERROR: NO USER DATA";
			} else if (resJson.errorString == "novalidjobid") {
				responseString = "ERROR: NO VALID JOB ID";
			} else if (resJson.errorString == "unauthorised") {
				responseString = "ERROR: USER NOT AUTHORISED";
			} else if (resJson.errorString == "usernotactive") {
				responseString = "ERROR: USER NOT ACTIVE";
			} else if (resJson.errorString == "jobnotcompleteorcancelled") {
				responseString = "ERROR: JOB NOT COMPLETE / CANCELLED";
			} else if (resJson.errorString == "nojobfound") {
				responseString = "ERROR: NO JOB FOUND";
			} else {
				responseString = "ERROR: UNKNOWN";
			}

			return theCallback(resStatus, responseString, resJson);

		} else if (resStatus == "401" || resStatus == 401) {

			if (resJson.errorString == "unauthorised") {
				responseString = "ERROR: USER NOT AUTHORISED";
			} else if (resJson.errorString == "userblocked") {
				responseString = "ERROR: USER BLOCKED";
			} else if (resJson.errorString == "outdatedappversion") {
				responseString = "ERROR: OUTDATED VERSION";
			} else {
				responseString = "ERROR: UNKNOWN";
			}

			return theCallback(resStatus, responseString, resJson);

		} else if (resStatus == "504" || resStatus == 504 || resStatus == "502" || resStatus == 502) {

			responseString = "ERROR: TIMEOUT";
			return theCallback(resStatus, responseString, false);

		} else if (resStatus == "200" || resStatus == 200) {

			if (resJson.successString == "jobunarchived") {
				responseString = "SUCCESS: JOB UNARCHIVED";
			}

			return theCallback(resStatus, responseString, resJson);
		}



	}, theJWTToken);

}

function archiveMavJob(theJob, theCallback, theJWTToken) {

	serverCall("POST", '/api/job/archivemavjob', theJob, function (resStatus, resJson) {


		var responseString;


		if (resStatus == "400" || resStatus == 400) {

			if (resJson.errorString == "nouserdata") {
				responseString = "ERROR: NO USER DATA";
			} else if (resJson.errorString == "novalidjobid") {
				responseString = "ERROR: NO VALID JOB ID";
			} else if (resJson.errorString == "unauthorised") {
				responseString = "ERROR: USER NOT AUTHORISED";
			} else if (resJson.errorString == "usernotactive") {
				responseString = "ERROR: USER NOT ACTIVE";
			} else if (resJson.errorString == "nojobfound") {
				responseString = "ERROR: NO JOB FOUND";
			} else {
				responseString = "ERROR: UNKNOWN";
			}

			return theCallback(resStatus, responseString, resJson);

		} else if (resStatus == "401" || resStatus == 401) {

			if (resJson.errorString == "unauthorised") {
				responseString = "ERROR: USER NOT AUTHORISED";
			} else if (resJson.errorString == "userblocked") {
				responseString = "ERROR: USER BLOCKED";
			} else if (resJson.errorString == "outdatedappversion") {
				responseString = "ERROR: OUTDATED VERSION";
			} else {
				responseString = "ERROR: UNKNOWN";
			}

			return theCallback(resStatus, responseString, resJson);

		} else if (resStatus == "504" || resStatus == 504 || resStatus == "502" || resStatus == 502) {

			responseString = "ERROR: TIMEOUT";
			return theCallback(resStatus, responseString, false);

		} else if (resStatus == "200" || resStatus == 200) {

			if (resJson.successString == "jobarchived") {
				responseString = "SUCCESS: JOB ARCHIVED";
			}

			return theCallback(resStatus, responseString, resJson);
		}



	}, theJWTToken);

}

function unArchiveMavJob(theJob, theCallback, theJWTToken) {

	serverCall("POST", '/api/job/unarchivemavjob', theJob, function (resStatus, resJson) {


		var responseString;


		if (resStatus == "400" || resStatus == 400) {

			if (resJson.errorString == "nouserdata") {
				responseString = "ERROR: NO USER DATA";
			} else if (resJson.errorString == "novalidjobid") {
				responseString = "ERROR: NO VALID JOB ID";
			} else if (resJson.errorString == "unauthorised") {
				responseString = "ERROR: USER NOT AUTHORISED";
			} else if (resJson.errorString == "usernotactive") {
				responseString = "ERROR: USER NOT ACTIVE";
			} else if (resJson.errorString == "nojobfound") {
				responseString = "ERROR: NO JOB FOUND";
			} else {
				responseString = "ERROR: UNKNOWN";
			}

			return theCallback(resStatus, responseString, resJson);

		} else if (resStatus == "401" || resStatus == 401) {

			if (resJson.errorString == "unauthorised") {
				responseString = "ERROR: USER NOT AUTHORISED";
			} else if (resJson.errorString == "userblocked") {
				responseString = "ERROR: USER BLOCKED";
			} else if (resJson.errorString == "outdatedappversion") {
				responseString = "ERROR: OUTDATED VERSION";
			} else {
				responseString = "ERROR: UNKNOWN";
			}

			return theCallback(resStatus, responseString, resJson);

		} else if (resStatus == "504" || resStatus == 504 || resStatus == "502" || resStatus == 502) {

			responseString = "ERROR: TIMEOUT";
			return theCallback(resStatus, responseString, false);

		} else if (resStatus == "200" || resStatus == 200) {

			if (resJson.successString == "jobunarchived") {
				responseString = "SUCCESS: JOB UNARCHIVED";
			}

			return theCallback(resStatus, responseString, resJson);
		}



	}, theJWTToken);

}

function createQuote(theQuote, theCallback, theJWTToken) {// quotecreated | nouserdata nouserwiththatemail novalidjobid jobnotopen jobnotfound

	serverCall("POST", '/api/job/createquote', theQuote, function (resStatus, resJson) {

		var responseString;


		if (resStatus == "400" || resStatus == 400) {

			if (resJson.errorString == "nouserdata") {
				responseString = "ERROR: NO USER DATA";
			} else if (resJson.errorString == "nouserwiththatemail") {
				responseString = "ERROR: USER NOT FOUND";
			} else if (resJson.errorString == "novalidjobid") {
				responseString = "ERROR: NO VALID JOB ID";
			} else if (resJson.errorString == "novalidquotespecprice") {
				responseString = "ERROR: NO VALID QUOTE SPEC PRICE";
			} else if (resJson.errorString == "novalidquotespecnumbermen") {
				responseString = "ERROR: NO VALID QUOTE SPEC NUMBER OF MEN";
			} else if (resJson.errorString == "novalidquotespecnumbervans") {
				responseString = "ERROR: NO VALID QUOTE SPEC NUMBER OF VANS";
			} else if (resJson.errorString == "invalidquotespecitemdescription") {
				responseString = "ERROR: INVALID QUOTE SPEC DESCRIPTION";
			} else if (resJson.errorString == "invalidquotemessage") {
				responseString = "ERROR: INVALID MESSAGE";
			} else if (resJson.errorString == "unauthorised") {
				responseString = "ERROR: USER NOT AUTHORISED";
			} else if (resJson.errorString == "usernotactive") {
				responseString = "ERROR: USER NOT ACTIVE";
			} else if (resJson.errorString == "userscantcreatequote") {
				responseString = "ERROR: USERS CANT CREATE QUOTES";
			} else if (resJson.errorString == "adminscantcreatequote") {
				responseString = "ERROR: ADMINS CANT CREATE QUOTES";
			} else if (resJson.errorString == "mavalreadyquoted") {
				responseString = "ERROR: MAV ALREADY QUOTED";
			} else if (resJson.errorString == "jobnotopen") {
				responseString = "ERROR: JOB NOT OPEN";
			} else if (resJson.errorString == "jobnotfound") {
				responseString = "ERROR: JOB NOT FOUND";
			} else if (resJson.errorString == "quotenotsaved") {
				responseString = "ERROR: QUOTE NOT SAVED";
			} else if (resJson.errorString == "noquotesfound") {
				responseString = "ERROR: NO QUOTES FOUND";
			} else if (resJson.errorString == "jobnotsaved") {
				responseString = "ERROR: JOB NOT SAVED";
			} else if (resJson.errorString == "usernotsaved") {
				responseString = "ERROR: USER NOT SAVED";
			} else if (resJson.errorString == "userownsjob") {
				responseString = "ERROR: USER CAN NOT QUOTE ON THEIR OWN JOB";
			} else if (resJson.errorString == "expiredcommission") {
				responseString = "ERROR: COMMISSION EXPIRED";
			} else {
				responseString = "ERROR: UNKNOWN";
			}

			return theCallback(resStatus, responseString, resJson);

		} else if (resStatus == "401" || resStatus == 401) {

			if (resJson.errorString == "unauthorised") {
				responseString = "ERROR: USER NOT AUTHORISED";
			} else if (resJson.errorString == "userblocked") {
				responseString = "ERROR: USER BLOCKED";
			} else if (resJson.errorString == "outdatedappversion") {
				responseString = "ERROR: OUTDATED VERSION";
			} else {
				responseString = "ERROR: UNKNOWN";
			}

			return theCallback(resStatus, responseString, resJson);

		} else if (resStatus == "504" || resStatus == 504 || resStatus == "502" || resStatus == 502) {

			responseString = "ERROR: TIMEOUT";
			return theCallback(resStatus, responseString, false);

		} else if (resStatus == "200" || resStatus == 200) {

			if (resJson.successString == "quotecreated") {
				responseString = "SUCCESS: QUOTE CREATED";
			}

			return theCallback(resStatus, responseString, resJson);
		}



	}, theJWTToken);

}

function retrieveQuote(theQuote, theCallback, theJWTToken) {

	serverCall("POST", '/api/job/retrievequote', theQuote, function (resStatus, resJson) {

		var responseString;// quoteretrieved novalidquoteid noquotefound


		if (resStatus == "400" || resStatus == 400) {

			if (resJson.errorString == "nouserdata") {
				responseString = "ERROR: NO USER DATA";
			} else if (resJson.errorString == "novalidquoteid") {
				responseString = "ERROR: NO VALID QUOTE ID";
			} else if (resJson.errorString == "noquotefound") {
				responseString = "ERROR: NO QUOTE FOUND";
			} else if (resJson.errorString == "jobclosed") {
				responseString = "ERROR: JOB CLOSED";
			} else if (resJson.errorString == "quoteclosed") {
				responseString = "ERROR: QUOTE CLOSED";
			} else if (resJson.errorString == "usernotinvolved") {
				responseString = "ERROR: USER CANNOT VIEW THIS QUOTE";
			} else if (resJson.errorString == "unauthorised") {
				responseString = "ERROR: USER NOT AUTHORISED";
			} else if (resJson.errorString == "userblocked") {
				responseString = "ERROR: USER BLOCKED";
			} else if (resJson.errorString == "outdatedappversion") {
				responseString = "ERROR: OUTDATED VERSION";
			} else {
				responseString = "ERROR: UNKNOWN";
			}

			return theCallback(resStatus, responseString, resJson);

		} else if (resStatus == "401" || resStatus == 401) {

			if (resJson.errorString == "unauthorised") {
				responseString = "ERROR: USER NOT AUTHORISED";
			} else if (resJson.errorString == "userblocked") {
				responseString = "ERROR: USER BLOCKED";
			} else if (resJson.errorString == "outdatedappversion") {
				responseString = "ERROR: OUTDATED VERSION";
			} else {
				responseString = "ERROR: UNKNOWN";
			}

			return theCallback(resStatus, responseString, resJson);

		} else if (resStatus == "504" || resStatus == 504 || resStatus == "502" || resStatus == 502) {

			responseString = "ERROR: TIMEOUT";
			return theCallback(resStatus, responseString, false);

		} else if (resStatus == "200" || resStatus == 200) {

			if (resJson.successString == "quoteretrieved") {
				responseString = "SUCCESS: QUOTE RETRIEVED";
			}

			return theCallback(resStatus, responseString, resJson);
		}



	}, theJWTToken);

}

function updateQuote(theQuote, theCallback, theJWTToken) {

	serverCall("POST", '/api/job/updatequote', theQuote, function (resStatus, resJson) {

		var responseString;


		if (resStatus == "400" || resStatus == 400) {

			if (resJson.errorString == "nodata") {
				responseString = "ERROR: NO DATA";
			} else if (resJson.errorString == "nouserdata") {
				responseString = "ERROR: NO USER DATA";
			} else if (resJson.errorString == "novalidquoteid") {
				responseString = "ERROR: NO VALID QUOTE ID";
			} else if (resJson.errorString == "novalidquotestatus") {
				responseString = "ERROR: NO VALID QUOTE STATUS";
			} else if (resJson.errorString == "invalidquotespecitemdescription") {
				responseString = "ERROR: INVALID QUOTE SPEC DESCRIPTION";
			} else if (resJson.errorString == "novalidquotespecprice") {
				responseString = "ERROR: INVALID QUOTE SPEC PRICE";
			} else if (resJson.errorString == "novalidquotespecnumbervans") {
				responseString = "ERROR: NO VALID QUOTE SPEC NUMBER OF VANS";
			} else if (resJson.errorString == "novalidquotespecnumbermen") {
				responseString = "ERROR: NO VALID QUOTE SPEC NUMBER OF MEN";
			} else if (resJson.errorString == "unauthorised") {
				responseString = "ERROR: USER NOT AUTHORISED";
			} else if (resJson.errorString == "usernotactive") {
				responseString = "ERROR: USER NOT ACTIVE";
			} else if (resJson.errorString == "jobalreadycomplete") {
				responseString = "ERROR: JOB ALREADY COMPLETE";
			} else if (resJson.errorString == "jobclosed") {
				responseString = "ERROR: JOB CLOSED";
			} else if (resJson.errorString == "jobagreed") {
				responseString = "ERROR: JOB ALREADY AGREED";
			} else if (resJson.errorString == "quotenotsaved") {
				responseString = "ERROR: QUOTE NOT SAVED";
			} else if (resJson.errorString == "noquotefound") {
				responseString = "ERROR: NO QUOTE FOUND";
			} else {
				responseString = "ERROR: UNKNOWN";
			}

			return theCallback(resStatus, responseString, resJson);

		} else if (resStatus == "401" || resStatus == 401) {

			if (resJson.errorString == "unauthorised") {
				responseString = "ERROR: USER NOT AUTHORISED";
			} else if (resJson.errorString == "userblocked") {
				responseString = "ERROR: USER BLOCKED";
			} else if (resJson.errorString == "outdatedappversion") {
				responseString = "ERROR: OUTDATED VERSION";
			} else {
				responseString = "ERROR: UNKNOWN";
			}

			return theCallback(resStatus, responseString, resJson);

		} else if (resStatus == "504" || resStatus == 504 || resStatus == "502" || resStatus == 502) {

			responseString = "ERROR: TIMEOUT";
			return theCallback(resStatus, responseString, false);

		} else if (resStatus == "200" || resStatus == 200) {

			if (resJson.successString == "quoteupdated") {
				responseString = "SUCCESS: QUOTE UPDATED";
			}

			return theCallback(resStatus, responseString, resJson);
		}



	}, theJWTToken);

}

function cancelQuote(theQuote, theCallback, theJWTToken) {

	serverCall("POST", '/api/job/cancelquote', theQuote, function (resStatus, resJson) {

		var responseString;


		if (resStatus == "400" || resStatus == 400) {

			if (resJson.errorString == "nouserdata") {
				responseString = "ERROR: NO USER DATA";
			} else if (resJson.errorString == "novalidquoteid") {
				responseString = "ERROR: NO VALID QUOTE ID";
			} else if (resJson.errorString == "unauthorised") {
				responseString = "ERROR: USER NOT AUTHORISED";
			} else if (resJson.errorString == "usernotactive") {
				responseString = "ERROR: USER NOT ACTIVE";
			} else if (resJson.errorString == "quotealreadycomplete") {
				responseString = "ERROR: QUOTE ALREADY COMPLETE";
			} else if (resJson.errorString == "quotealreadyclosed") {
				responseString = "ERROR: QUOTE ALREADY CLOSED";
			} else if (resJson.errorString == "quotenotdeleted") {
				responseString = "ERROR: QUOTE NOT DELETED";
			} else if (resJson.errorString == "noquotefound") {
				responseString = "ERROR: NO QUOTE FOUND";
			} else if (resJson.errorString == "jobnotsaved") {
				responseString = "ERROR: JOB NOT SAVED";
			} else {
				responseString = "ERROR: UNKNOWN";
			}

			return theCallback(resStatus, responseString, resJson);

		} else if (resStatus == "401" || resStatus == 401) {

			if (resJson.errorString == "unauthorised") {
				responseString = "ERROR: USER NOT AUTHORISED";
			} else if (resJson.errorString == "userblocked") {
				responseString = "ERROR: USER BLOCKED";
			} else if (resJson.errorString == "outdatedappversion") {
				responseString = "ERROR: OUTDATED VERSION";
			} else {
				responseString = "ERROR: UNKNOWN";
			}

			return theCallback(resStatus, responseString, resJson);

		} else if (resStatus == "504" || resStatus == 504 || resStatus == "502" || resStatus == 502) {

			responseString = "ERROR: TIMEOUT";
			return theCallback(resStatus, responseString, false);

		} else if (resStatus == "200" || resStatus == 200) {

			if (resJson.successString == "quotecancelled") {
				responseString = "SUCCESS: QUOTE CANCELLED";
			}

			return theCallback(resStatus, responseString, resJson);
		}



	}, theJWTToken);

}

function deleteQuote(theQuote, theCallback, theJWTToken) {

	serverCall("POST", '/api/job/deletequote', theQuote, function (resStatus, resJson) {

		var responseString;


		if (resStatus == "400" || resStatus == 400) {

			if (resJson.errorString == "nouserdata") {
				responseString = "ERROR: NO USER DATA";
			} else if (resJson.errorString == "novalidquoteid") {
				responseString = "ERROR: NO VALID QUOTE ID";
			} else if (resJson.errorString == "unauthorised") {
				responseString = "ERROR: USER NOT AUTHORISED";
			} else if (resJson.errorString == "usernotactive") {
				responseString = "ERROR: USER NOT ACTIVE";
			} else if (resJson.errorString == "quotealreadycomplete") {
				responseString = "ERROR: QUOTE ALREADY COMPLETE";
			} else if (resJson.errorString == "quoteagreed") {
				responseString = "ERROR: QUOTE ALREADY AGREED";
			} else if (resJson.errorString == "quotenotdeleted") {
				responseString = "ERROR: QUOTE NOT DELETED";
			} else if (resJson.errorString == "noquotefound") {
				responseString = "ERROR: NO QUOTE FOUND";
			} else if (resJson.errorString == "noquotesfound") {
				responseString = "ERROR: NO QUOTES FOUND";
			} else {
				responseString = "ERROR: UNKNOWN";
			}

			return theCallback(resStatus, responseString, resJson);

		} else if (resStatus == "401" || resStatus == 401) {

			if (resJson.errorString == "unauthorised") {
				responseString = "ERROR: USER NOT AUTHORISED";
			} else if (resJson.errorString == "userblocked") {
				responseString = "ERROR: USER BLOCKED";
			} else if (resJson.errorString == "outdatedappversion") {
				responseString = "ERROR: OUTDATED VERSION";
			} else {
				responseString = "ERROR: UNKNOWN";
			}

			return theCallback(resStatus, responseString, resJson);

		} else if (resStatus == "504" || resStatus == 504 || resStatus == "502" || resStatus == 502) {

			responseString = "ERROR: TIMEOUT";
			return theCallback(resStatus, responseString, false);

		} else if (resStatus == "200" || resStatus == 200) {

			if (resJson.successString == "quotedeleted") {
				responseString = "SUCCESS: QUOTE DELETED";
			}

			return theCallback(resStatus, responseString, resJson);
		}



	}, theJWTToken);

}

function archiveQuote(theQuote, theCallback, theJWTToken) {

	serverCall("POST", '/api/job/archivequote', theQuote, function (resStatus, resJson) {

		var responseString;


		if (resStatus == "400" || resStatus == 400) {

			if (resJson.errorString == "nouserdata") {
				responseString = "ERROR: NO USER DATA";
			} else if (resJson.errorString == "novalidquoteid") {
				responseString = "ERROR: NO VALID QUOTE ID";
			} else if (resJson.errorString == "quotestatusnotarchivable") {
				responseString = "ERROR: QUOTE NOT COMPLETE / CANCELLED";
			} else if (resJson.errorString == "unauthorised") {
				responseString = "ERROR: USER NOT AUTHORISED";
			} else if (resJson.errorString == "usernotactive") {
				responseString = "ERROR: USER NOT ACTIVE";
			} else if (resJson.errorString == "noquotefound") {
				responseString = "ERROR: NO QUOTE FOUND";
			} else {
				responseString = "ERROR: UNKNOWN";
			}

			return theCallback(resStatus, responseString, resJson);

		} else if (resStatus == "401" || resStatus == 401) {

			if (resJson.errorString == "unauthorised") {
				responseString = "ERROR: USER NOT AUTHORISED";
			} else if (resJson.errorString == "userblocked") {
				responseString = "ERROR: USER BLOCKED";
			} else if (resJson.errorString == "outdatedappversion") {
				responseString = "ERROR: OUTDATED VERSION";
			} else {
				responseString = "ERROR: UNKNOWN";
			}

			return theCallback(resStatus, responseString, resJson);

		} else if (resStatus == "504" || resStatus == 504 || resStatus == "502" || resStatus == 502) {

			responseString = "ERROR: TIMEOUT";
			return theCallback(resStatus, responseString, false);

		} else if (resStatus == "200" || resStatus == 200) {

			if (resJson.successString == "quotearchived") {
				responseString = "SUCCESS: QUOTE ARCHIVED";
			}

			return theCallback(resStatus, responseString, resJson);
		}



	}, theJWTToken);

}

function unArchiveQuote(theQuote, theCallback, theJWTToken) {

	serverCall("POST", '/api/job/unarchivequote', theQuote, function (resStatus, resJson) {

		var responseString;


		if (resStatus == "400" || resStatus == 400) {

			if (resJson.errorString == "nouserdata") {
				responseString = "ERROR: NO USER DATA";
			} else if (resJson.errorString == "novalidquoteid") {
				responseString = "ERROR: NO VALID QUOTE ID";
			} else if (resJson.errorString == "quotestatusnotarchivable") {
				responseString = "ERROR: QUOTE NOT COMPLETE / CANCELLED";
			} else if (resJson.errorString == "unauthorised") {
				responseString = "ERROR: USER NOT AUTHORISED";
			} else if (resJson.errorString == "usernotactive") {
				responseString = "ERROR: USER NOT ACTIVE";
			} else if (resJson.errorString == "noquotefound") {
				responseString = "ERROR: NO QUOTE FOUND";
			} else {
				responseString = "ERROR: UNKNOWN";
			}

			return theCallback(resStatus, responseString, resJson);

		} else if (resStatus == "401" || resStatus == 401) {

			if (resJson.errorString == "unauthorised") {
				responseString = "ERROR: USER NOT AUTHORISED";
			} else if (resJson.errorString == "userblocked") {
				responseString = "ERROR: USER BLOCKED";
			} else if (resJson.errorString == "outdatedappversion") {
				responseString = "ERROR: OUTDATED VERSION";
			} else {
				responseString = "ERROR: UNKNOWN";
			}

			return theCallback(resStatus, responseString, resJson);

		} else if (resStatus == "504" || resStatus == 504 || resStatus == "502" || resStatus == 502) {

			responseString = "ERROR: TIMEOUT";
			return theCallback(resStatus, responseString, false);

		} else if (resStatus == "200" || resStatus == 200) {

			if (resJson.successString == "quoteunarchived") {
				responseString = "SUCCESS: QUOTE UNARCHIVED";
			}

			return theCallback(resStatus, responseString, resJson);
		}



	}, theJWTToken);

}

function retrieveJobQuotes(theJob, theCallback, theJWTToken) {

	serverCall("POST", '/api/job/retrievejobquotes', theJob, function (resStatus, resJson) {

		var responseString;

		if (resStatus == "400" || resStatus == 400) {

			if (resJson.errorString == "nouserdata") {
				responseString = "ERROR: NO USER DATA";
			} else if (resJson.errorString == "unauthorised") {
				responseString = "ERROR: USER NOT AUTHORISED";
			} else if (resJson.errorString == "novalidjobid") {
				responseString = "ERROR: NO VALID JOB ID";
			} else if (resJson.errorString == "nojobfound") {
				responseString = "ERROR: NO JOB FOUND";
			} else {
				responseString = "ERROR: UNKNOWN";
			}

			return theCallback(resStatus, responseString, resJson);

		} else if (resStatus == "401" || resStatus == 401) {

			if (resJson.errorString == "unauthorised") {
				responseString = "ERROR: USER NOT AUTHORISED";
			} else if (resJson.errorString == "userblocked") {
				responseString = "ERROR: USER BLOCKED";
			} else if (resJson.errorString == "outdatedappversion") {
				responseString = "ERROR: OUTDATED VERSION";
			} else {
				responseString = "ERROR: UNKNOWN";
			}

			return theCallback(resStatus, responseString, resJson);

		} else if (resStatus == "504" || resStatus == 504 || resStatus == "502" || resStatus == 502) {

			responseString = "ERROR: TIMEOUT";
			return theCallback(resStatus, responseString, false);

		} else if (resStatus == "200" || resStatus == 200) {

			if (resJson.successString == "jobsquotesfound") {
				responseString = "SUCCESS: JOB QUOTES FOUND";
			}

			return theCallback(resStatus, responseString, resJson);
		}



	}, theJWTToken);

}

function acceptQuote(theQuote, theCallback, theJWTToken) {

	serverCall("POST", '/api/job/acceptquote', theQuote, function (resStatus, resJson) {

		var responseString;

		if (resStatus == "400" || resStatus == 400) {

			if (resJson.errorString == "nouserdata") {
				responseString = "ERROR: NO USER DATA";
			} else if (resJson.errorString == "novalidquoteid") {
				responseString = "ERROR: NO VALID QUOTE ID";
			} else if (resJson.errorString == "unauthorised") {
				responseString = "ERROR: USER NOT AUTHORISED";
			} else if (resJson.errorString == "usernotactive") {
				responseString = "ERROR: USER NOT ACTIVE";
			} else if (resJson.errorString == "quoteclosed") {
				responseString = "ERROR: QUOTE CLOSED";
			} else if (resJson.errorString == "noquotefound") {
				responseString = "ERROR: NO QUOTE FOUND";
			} else if (resJson.errorString == "jobalreadycomplete") {
				responseString = "ERROR: JOB ALREADY COMPLETE";
			} else if (resJson.errorString == "jobagreed") {
				responseString = "ERROR: JOB ALREADY AGREED";
			} else if (resJson.errorString == "jobalreadypaid") {
				responseString = "ERROR: JOB ALREADY PAID";
			} else if (resJson.errorString == "jobagreedthisquote") {
				responseString = "ERROR: JOB AND QUOTE ALREADY AGREED";
			} else if (resJson.errorString == "nojobfound") {
				responseString = "ERROR: NO JOB FOUND";
			} else if (resJson.errorString == "jobnotsaved") {
				responseString = "ERROR: JOB NOT SAVED";
			} else if (resJson.errorString == "quotenotsaved") {
				responseString = "ERROR: QUOTE NOT SAVED";
			} else if (resJson.errorString == "nojobsfound") {
				responseString = "ERROR: NO JOBS FOUND";
			} else if (resJson.errorString == "userownsjob") {
				responseString = "ERROR: USER CAN NOT ACCEPT QUOTE ON THEIR OWN JOB";
			} else {
				responseString = "ERROR: UNKNOWN";
			}

			return theCallback(resStatus, responseString, resJson);

		} else if (resStatus == "401" || resStatus == 401) {

			if (resJson.errorString == "unauthorised") {
				responseString = "ERROR: USER NOT AUTHORISED";
			} else if (resJson.errorString == "userblocked") {
				responseString = "ERROR: USER BLOCKED";
			} else if (resJson.errorString == "outdatedappversion") {
				responseString = "ERROR: OUTDATED VERSION";
			} else {
				responseString = "ERROR: UNKNOWN";
			}

			return theCallback(resStatus, responseString, resJson);

		} else if (resStatus == "504" || resStatus == 504 || resStatus == "502" || resStatus == 502) {

			responseString = "ERROR: TIMEOUT";
			return theCallback(resStatus, responseString, false);

		} else if (resStatus == "200" || resStatus == 200) {

			if (resJson.successString == "quoteaccepted") {
				responseString = "SUCCESS: QUOTE ACCEPTED";
			}

			return theCallback(resStatus, responseString, resJson);
		}



	}, theJWTToken);

}

function declineQuote(theQuote, theCallback, theJWTToken) {

	serverCall("POST", '/api/job/declinequote', theQuote, function (resStatus, resJson) {

		var responseString;

		if (resStatus == "400" || resStatus == 400) {

			if (resJson.errorString == "nouserdata") {
				responseString = "ERROR: NO USER DATA";
			} else if (resJson.errorString == "novalidquoteid") {
				responseString = "ERROR: NO VALID QUOTE ID";
			} else if (resJson.errorString == "unauthorised") {
				responseString = "ERROR: USER NOT AUTHORISED";
			} else if (resJson.errorString == "usernotactive") {
				responseString = "ERROR: USER NOT ACTIVE";
			} else if (resJson.errorString == "quoteclosed") {
				responseString = "ERROR: QUOTE CLOSED";
			} else if (resJson.errorString == "noquotefound") {
				responseString = "ERROR: NO QUOTE FOUND";
			} else if (resJson.errorString == "jobalreadycomplete") {
				responseString = "ERROR: JOB ALREADY COMPLETE";
			} else if (resJson.errorString == "jobagreed") {
				responseString = "ERROR: JOB ALREADY AGREED";
			} else if (resJson.errorString == "jobalreadypaid") {
				responseString = "ERROR: JOB ALREADY PAID";
			} else if (resJson.errorString == "jobagreedthisquote") {
				responseString = "ERROR: JOB AND QUOTE ALREADY AGREED";
			} else if (resJson.errorString == "nojobfound") {
				responseString = "ERROR: NO JOB FOUND";
			} else if (resJson.errorString == "jobnotsaved") {
				responseString = "ERROR: QUOTE NOT SAVED";
			} else {
				responseString = "ERROR: UNKNOWN";
			}

			return theCallback(resStatus, responseString, resJson);

		} else if (resStatus == "401" || resStatus == 401) {

			if (resJson.errorString == "unauthorised") {
				responseString = "ERROR: USER NOT AUTHORISED";
			} else if (resJson.errorString == "userblocked") {
				responseString = "ERROR: USER BLOCKED";
			} else if (resJson.errorString == "outdatedappversion") {
				responseString = "ERROR: OUTDATED VERSION";
			} else {
				responseString = "ERROR: UNKNOWN";
			}

			return theCallback(resStatus, responseString, resJson);

		} else if (resStatus == "504" || resStatus == 504 || resStatus == "502" || resStatus == 502) {

			responseString = "ERROR: TIMEOUT";
			return theCallback(resStatus, responseString, false);

		} else if (resStatus == "200" || resStatus == 200) {

			if (resJson.successString == "quotedeclined") {
				responseString = "SUCCESS: QUOTE DECLINED";
			}

			return theCallback(resStatus, responseString, resJson);
		}



	}, theJWTToken);

}

function retrieveJobs(theUser, theCallback, theJWTToken) {

	serverCall("POST", '/api/job/retrievejobs', theUser, function (resStatus, resJson) {

		var responseString;


		if (resStatus == "400" || resStatus == 400) {// jobsfound | nouserdata nouserwiththatemail unauthorised nojobsfound usernotactive

			if (resJson.errorString == "nouserdata") {
				responseString = "ERROR: NO USER DATA";
			} else if (resJson.errorString == "nouserwiththatemail") {
				responseString = "ERROR: USER NOT FOUND";
			} else if (resJson.errorString == "usernotactive") {
				responseString = "ERROR: USER NOT ACTIVE";
			} else if (resJson.errorString == "unauthorised") {
				responseString = "ERROR: USER NOT AUTHORISED";
			} else if (resJson.errorString == "nojobsfound") {
				responseString = "ERROR: NO JOBS FOUND";
			} else if (resJson.errorString == "invalidfilter") {
				responseString = "ERROR: INVALID FILTER";
			} else if (resJson.errorString == "invalidsearch") {
				responseString = "ERROR: INVALID SEARCH";
			} else if (resJson.errorString == "invalidsort") {
				responseString = "ERROR: INVALID SORT";
			} else if (resJson.errorString == "invalidpage") {
				responseString = "ERROR: INVALID PAGE";
			} else {
				responseString = "ERROR: UNKNOWN";
			}

			return theCallback(resStatus, responseString, resJson);

		} else if (resStatus == "401" || resStatus == 401) {

			if (resJson.errorString == "unauthorised") {
				responseString = "ERROR: USER NOT AUTHORISED";
			} else if (resJson.errorString == "userblocked") {
				responseString = "ERROR: USER BLOCKED";
			} else if (resJson.errorString == "outdatedappversion") {
				responseString = "ERROR: OUTDATED VERSION";
			} else {
				responseString = "ERROR: UNKNOWN";
			}

			return theCallback(resStatus, responseString, resJson);

		} else if (resStatus == "504" || resStatus == 504 || resStatus == "502" || resStatus == 502) {

			responseString = "ERROR: TIMEOUT";
			return theCallback(resStatus, responseString, false);

		} else if (resStatus == "200" || resStatus == 200) {

			if (resJson.successString == "jobsfound") {
				responseString = "SUCCESS: JOBS FOUND";
			}

			return theCallback(resStatus, responseString, resJson);
		}



	}, theJWTToken);

}

function retrieveQuotes(theUser, theCallback, theJWTToken) {

	serverCall("POST", '/api/job/retrievequotes', theUser, function (resStatus, resJson) {

		var responseString;


		if (resStatus == "400" || resStatus == 400) {// jobsfound | nouserdata nouserwiththatemail unauthorised nojobsfound usernotactive

			if (resJson.errorString == "nouserdata") {
				responseString = "ERROR: NO USER DATA";
			} else if (resJson.errorString == "nouserwiththatemail") {
				responseString = "ERROR: USER NOT FOUND";
			} else if (resJson.errorString == "usernotactive") {
				responseString = "ERROR: USER NOT ACTIVE";
			} else if (resJson.errorString == "unauthorised") {
				responseString = "ERROR: USER NOT AUTHORISED";
			} else if (resJson.errorString == "noquotesfound") {
				responseString = "ERROR: NO QUOTES FOUND";
			} else if (resJson.errorString == "invalidfilter") {
				responseString = "ERROR: INVALID FILTER";
			} else if (resJson.errorString == "invalidsearch") {
				responseString = "ERROR: INVALID SEARCH";
			} else if (resJson.errorString == "invalidsort") {
				responseString = "ERROR: INVALID SORT";
			} else if (resJson.errorString == "invalidpage") {
				responseString = "ERROR: INVALID PAGE";
			} else {
				responseString = "ERROR: UNKNOWN";
			}

			return theCallback(resStatus, responseString, resJson);

		} else if (resStatus == "401" || resStatus == 401) {

			if (resJson.errorString == "unauthorised") {
				responseString = "ERROR: USER NOT AUTHORISED";
			} else if (resJson.errorString == "userblocked") {
				responseString = "ERROR: USER BLOCKED";
			} else if (resJson.errorString == "outdatedappversion") {
				responseString = "ERROR: OUTDATED VERSION";
			} else {
				responseString = "ERROR: UNKNOWN";
			}

			return theCallback(resStatus, responseString, resJson);

		} else if (resStatus == "504" || resStatus == 504 || resStatus == "502" || resStatus == 502) {

			responseString = "ERROR: TIMEOUT";
			return theCallback(resStatus, responseString, false);

		} else if (resStatus == "200" || resStatus == 200) {

			if (resJson.successString == "quotesfound") {
				responseString = "SUCCESS: QUOTES FOUND";
			}

			return theCallback(resStatus, responseString, resJson);
		}



	}, theJWTToken);

}

function findJobs(theUser, theCallback, theJWTToken) {

	serverCall("POST", '/api/job/findjobs', theUser, function (resStatus, resJson) {

		var responseString;


		if (resStatus == "400" || resStatus == 400) {// jobsfound | nouserdata nouserwiththatemail unauthorised nojobsfound usernotactive

			if (resJson.errorString == "nouserdata") {
				responseString = "ERROR: NO USER DATA";
			} else if (resJson.errorString == "novalidcoordinates") {
				responseString = "ERROR: NO VALID COORDINATES";
			} else if (resJson.errorString == "novalidrange") {
				responseString = "ERROR: NO VALID RANGE";
			} else if (resJson.errorString == "nouserwiththatemail") {
				responseString = "ERROR: USER NOT FOUND";
			} else if (resJson.errorString == "usernotactive") {
				responseString = "ERROR: USER NOT ACTIVE";
			} else if (resJson.errorString == "unauthorised") {
				responseString = "ERROR: USER NOT AUTHORISED";
			} else if (resJson.errorString == "nojobsfound") {
				responseString = "ERROR: NO JOBS FOUND";
			} else if (resJson.errorString == "invalidfilter") {
				responseString = "ERROR: INVALID FILTER";
			} else if (resJson.errorString == "invalidsearch") {
				responseString = "ERROR: INVALID SEARCH";
			} else if (resJson.errorString == "invalidsort") {
				responseString = "ERROR: INVALID SORT";
			} else if (resJson.errorString == "invalidpage") {
				responseString = "ERROR: INVALID PAGE";
			} else {
				responseString = "ERROR: UNKNOWN";
			}

			return theCallback(resStatus, responseString, resJson);

		} else if (resStatus == "401" || resStatus == 401) {

			if (resJson.errorString == "unauthorised") {
				responseString = "ERROR: USER NOT AUTHORISED";
			} else if (resJson.errorString == "userblocked") {
				responseString = "ERROR: USER BLOCKED";
			} else if (resJson.errorString == "outdatedappversion") {
				responseString = "ERROR: OUTDATED VERSION";
			} else {
				responseString = "ERROR: UNKNOWN";
			}

			return theCallback(resStatus, responseString, resJson);

		} else if (resStatus == "504" || resStatus == 504 || resStatus == "502" || resStatus == 502) {

			responseString = "ERROR: TIMEOUT";
			return theCallback(resStatus, responseString, false);

		} else if (resStatus == "200" || resStatus == 200) {

			if (resJson.successString == "jobsfound") {
				responseString = "SUCCESS: JOBS FOUND";
			}

			return theCallback(resStatus, responseString, resJson);
		}



	}, theJWTToken);

}

function completeJob(theJob, theCallback, theJWTToken) {

	serverCall("POST", '/api/job/completejob', theJob, function (resStatus, resJson) {

		var responseString;


		if (resStatus == "400" || resStatus == 400) {

			if (resJson.errorString == "nouserdata") {
				responseString = "ERROR: NO USER DATA";
			} else if (resJson.errorString == "novalidjobid") {
				responseString = "ERROR: NO VALID JOB ID";
			} else if (resJson.errorString == "nouserwiththatemail") {
				responseString = "ERROR: USER NOT FOUND";
			} else if (resJson.errorString == "novalidjobspecdate") {
				responseString = "ERROR: NO VALID JOB SPEC DATE";
			} else if (resJson.errorString == "invalidstars") {
				responseString = "ERROR: INVALID STAR RATING";
			} else if (resJson.errorString == "invalidcomment") {
				responseString = "ERROR: INVALID COMMENT";
			} else if (resJson.errorString == "unauthorised") {
				responseString = "ERROR: USER NOT AUTHORISED";
			} else if (resJson.errorString == "usernotactive") {
				responseString = "ERROR: USER NOT ACTIVE";
			} else if (resJson.errorString == "nojobfound") {
				responseString = "ERROR: NO JOB FOUND";
			} else if (resJson.errorString == "jobstillopen") {
				responseString = "ERROR: JOB STILL OPEN (NO QUOTE AGREED)";
			} else if (resJson.errorString == "jobalreadycomplete") {
				responseString = "ERROR: JOB ALREADY COMPLETE";
			} else if (resJson.errorString == "jobalreadyclosed") {
				responseString = "ERROR: JOB CLOSED";
			} else if (resJson.errorString == "jobnotpaid") {
				responseString = "ERROR: JOB AGREED BUT NOT PAID";
			} else if (resJson.errorString == "noquotefound") {
				responseString = "ERROR: NO QUOTE FOUND";
			} else if (resJson.errorString == "quotenotsaved") {
				responseString = "ERROR: QUOTE NOT SAVED";
			} else if (resJson.errorString == "jobnotsaved") {
				responseString = "ERROR: JOB NOT SAVED";
			} else if (resJson.errorString == "nojobsfound") {
				responseString = "ERROR: NO JOBS FOUND";
			} else if (resJson.errorString == "usernotsaved") {
				responseString = "ERROR: USER NOT SAVED";
			} else {
				responseString = "ERROR: UNKNOWN";
			}

			return theCallback(resStatus, responseString, resJson);

		} else if (resStatus == "401" || resStatus == 401) {

			if (resJson.errorString == "unauthorised") {
				responseString = "ERROR: USER NOT AUTHORISED";
			} else if (resJson.errorString == "userblocked") {
				responseString = "ERROR: USER BLOCKED";
			} else if (resJson.errorString == "outdatedappversion") {
				responseString = "ERROR: OUTDATED VERSION";
			} else {
				responseString = "ERROR: UNKNOWN";
			}

			return theCallback(resStatus, responseString, resJson);

		} else if (resStatus == "504" || resStatus == 504 || resStatus == "502" || resStatus == 502) {

			responseString = "ERROR: TIMEOUT";
			return theCallback(resStatus, responseString, false);

		} else if (resStatus == "200" || resStatus == 200) {

			if (resJson.successString == "jobcompleted") {
				responseString = "SUCCESS: JOB COMPLETED";
			}

			return theCallback(resStatus, responseString, resJson);
		}



	}, theJWTToken);

}

function completeQuote(theJob, theCallback, theJWTToken) {

	serverCall("POST", '/api/job/completequote', theJob, function (resStatus, resJson) {

		var responseString;


		if (resStatus == "400" || resStatus == 400) {

			if (resJson.errorString == "nouserdata") {
				responseString = "ERROR: NO USER DATA";
			} else if (resJson.errorString == "novalidjobid") {
				responseString = "ERROR: NO VALID JOB ID";
			} else if (resJson.errorString == "nouserwiththatemail") {
				responseString = "ERROR: USER NOT FOUND";
			} else if (resJson.errorString == "novalidjobspecdate") {
				responseString = "ERROR: NO VALID JOB SPEC DATE";
			} else if (resJson.errorString == "invalidstars") {
				responseString = "ERROR: INVALID STAR RATING";
			} else if (resJson.errorString == "invalidcomment") {
				responseString = "ERROR: INVALID COMMENT";
			} else if (resJson.errorString == "unauthorised") {
				responseString = "ERROR: USER NOT AUTHORISED";
			} else if (resJson.errorString == "usernotactive") {
				responseString = "ERROR: USER NOT ACTIVE";
			} else if (resJson.errorString == "nojobfound") {
				responseString = "ERROR: NO JOB FOUND";
			} else if (resJson.errorString == "jobnotcomplete") {
				responseString = "ERROR: JOB NOT COMPLETE";
			} else if (resJson.errorString == "quoptealreadycomplete") {
				responseString = "ERROR: JOB CLOSED";
			} else if (resJson.errorString == "jobnotpaid") {
				responseString = "ERROR: JOB AGREED BUT NOT PAID";
			} else if (resJson.errorString == "noquotefound") {
				responseString = "ERROR: NO QUOTE FOUND";
			} else if (resJson.errorString == "quotenotsaved") {
				responseString = "ERROR: QUOTE NOT SAVED";
			} else if (resJson.errorString == "jobnotsaved") {
				responseString = "ERROR: JOB NOT SAVED";
			} else if (resJson.errorString == "nojobsfound") {
				responseString = "ERROR: NO JOBS FOUND";
			} else if (resJson.errorString == "usernotsaved") {
				responseString = "ERROR: USER NOT SAVED";
			} else {
				responseString = "ERROR: UNKNOWN";
			}

			return theCallback(resStatus, responseString, resJson);

		} else if (resStatus == "401" || resStatus == 401) {

			if (resJson.errorString == "unauthorised") {
				responseString = "ERROR: USER NOT AUTHORISED";
			} else if (resJson.errorString == "userblocked") {
				responseString = "ERROR: USER BLOCKED";
			} else if (resJson.errorString == "outdatedappversion") {
				responseString = "ERROR: OUTDATED VERSION";
			} else {
				responseString = "ERROR: UNKNOWN";
			}

			return theCallback(resStatus, responseString, resJson);

		} else if (resStatus == "504" || resStatus == 504 || resStatus == "502" || resStatus == 502) {

			responseString = "ERROR: TIMEOUT";
			return theCallback(resStatus, responseString, false);

		} else if (resStatus == "200" || resStatus == 200) {

			if (resJson.successString == "quotecompleted") {
				responseString = "SUCCESS: QUOTE COMPLETED";
			}

			return theCallback(resStatus, responseString, resJson);
		}



	}, theJWTToken);

}

function rateJob(theJob, theCallback, theJWTToken) {

	serverCall("POST", '/api/job/ratejob', theJob, function (resStatus, resJson) {

		var responseString;


		if (resStatus == "400" || resStatus == 400) {

			if (resJson.errorString == "nouserdata") {
				responseString = "ERROR: NO USER DATA";
			} else if (resJson.errorString == "novalidjobid") {
				responseString = "ERROR: NO VALID JOB ID";
			} else if (resJson.errorString == "nouserwiththatemail") {
				responseString = "ERROR: USER NOT FOUND";
			} else if (resJson.errorString == "invalidstars") {
				responseString = "ERROR: INVALID STAR RATING";
			} else if (resJson.errorString == "invalidcomment") {
				responseString = "ERROR: INVALID COMMENT";
			} else if (resJson.errorString == "unauthorised") {
				responseString = "ERROR: USER NOT AUTHORISED";
			} else if (resJson.errorString == "usernotactive") {
				responseString = "ERROR: USER NOT ACTIVE";
			} else if (resJson.errorString == "nojobfound") {
				responseString = "ERROR: NO JOB FOUND";
			} else if (resJson.errorString == "norateablejobfound") {
				responseString = "ERROR: NO RATABLE JOB FOUND";
			} else if (resJson.errorString == "jobnotsaved") {
				responseString = "ERROR: JOB NOT SAVED";
			} else if (resJson.errorString == "nojobsfound") {
				responseString = "ERROR: NO JOBS FOUND";
			} else if (resJson.errorString == "usernotsaved") {
				responseString = "ERROR: USER NOT SAVED";
			} else {
				responseString = "ERROR: UNKNOWN";
			}

			return theCallback(resStatus, responseString, resJson);

		} else if (resStatus == "401" || resStatus == 401) {

			if (resJson.errorString == "unauthorised") {
				responseString = "ERROR: USER NOT AUTHORISED";
			} else if (resJson.errorString == "userblocked") {
				responseString = "ERROR: USER BLOCKED";
			} else if (resJson.errorString == "outdatedappversion") {
				responseString = "ERROR: OUTDATED VERSION";
			} else {
				responseString = "ERROR: UNKNOWN";
			}

			return theCallback(resStatus, responseString, resJson);

		} else if (resStatus == "504" || resStatus == 504 || resStatus == "502" || resStatus == 502) {

			responseString = "ERROR: TIMEOUT";
			return theCallback(resStatus, responseString, false);

		} else if (resStatus == "200" || resStatus == 200) {

			if (resJson.successString == "jobrated") {
				responseString = "SUCCESS: JOB RATED";
			}

			return theCallback(resStatus, responseString, resJson);
		}



	}, theJWTToken);

}

function rateQuote(theQuote, theCallback, theJWTToken) {

	serverCall("POST", '/api/job/ratequote', theQuote, function (resStatus, resJson) {

		var responseString;


		if (resStatus == "400" || resStatus == 400) {

			if (resJson.errorString == "nouserdata") {
				responseString = "ERROR: NO USER DATA";
			} else if (resJson.errorString == "novalidquoteid") {
				responseString = "ERROR: NO VALID QUOTE ID";
			} else if (resJson.errorString == "nouserwiththatemail") {
				responseString = "ERROR: USER NOT FOUND";
			} else if (resJson.errorString == "invalidstars") {
				responseString = "ERROR: INVALID STAR RATING";
			} else if (resJson.errorString == "invalidcomment") {
				responseString = "ERROR: INVALID COMMENT";
			} else if (resJson.errorString == "unauthorised") {
				responseString = "ERROR: USER NOT AUTHORISED";
			} else if (resJson.errorString == "usernotactive") {
				responseString = "ERROR: USER NOT ACTIVE";
			} else if (resJson.errorString == "noquotefound") {
				responseString = "ERROR: NO QUOTE FOUND";
			} else if (resJson.errorString == "norateablequotefound") {
				responseString = "ERROR: NO RATABLE QUOTE FOUND";
			} else if (resJson.errorString == "jobnotsaved") {
				responseString = "ERROR: JOB NOT SAVED";
			} else if (resJson.errorString == "noquotesfound") {
				responseString = "ERROR: NO QUOTES FOUND";
			} else if (resJson.errorString == "usernotsaved") {
				responseString = "ERROR: USER NOT SAVED";
			} else {
				responseString = "ERROR: UNKNOWN";
			}

			return theCallback(resStatus, responseString, resJson);

		} else if (resStatus == "401" || resStatus == 401) {

			if (resJson.errorString == "unauthorised") {
				responseString = "ERROR: USER NOT AUTHORISED";
			} else if (resJson.errorString == "userblocked") {
				responseString = "ERROR: USER BLOCKED";
			} else if (resJson.errorString == "outdatedappversion") {
				responseString = "ERROR: OUTDATED VERSION";
			} else {
				responseString = "ERROR: UNKNOWN";
			}

			return theCallback(resStatus, responseString, resJson);

		} else if (resStatus == "504" || resStatus == 504 || resStatus == "502" || resStatus == 502) {

			responseString = "ERROR: TIMEOUT";
			return theCallback(resStatus, responseString, false);

		} else if (resStatus == "200" || resStatus == 200) {

			if (resJson.successString == "quoterated") {
				responseString = "SUCCESS: QUOTE RATED";
			}

			return theCallback(resStatus, responseString, resJson);
		}



	}, theJWTToken);

}

function saveJobLS(jobType, theJobState, theCallback) {

	if (jobType == 'guestJob') {

		localStorage.setItem('guestJob', JSON.stringify(theJobState));

		if (theCallback) {
			return theCallback()
		} else {
			return
		}

	} else if (theCallback) {
		return theCallback()
	} else {
		return
	}

}

export {
	createJob,
	retrieveJob,
	updateJob,
	cancelJob,
	deleteJob,
	archiveJob,
	unArchiveJob,
	archiveMavJob,
	unArchiveMavJob,
	createQuote,
	retrieveQuote,
	updateQuote,
	deleteQuote,
	cancelQuote,
	archiveQuote,
	unArchiveQuote,
	retrieveJobQuotes,
	acceptQuote,
	declineQuote,
	retrieveJobs,
	retrieveQuotes,
	findJobs,
	completeJob,
	completeQuote,
	rateJob,
	rateQuote,
	saveJobLS
}