import React from 'react';
import $ from 'jquery';
// import TopNav from './TopNav';

class NotFound extends React.Component{
	componentDidMount () {
		$('body').addClass('l-no-header l-no-footer');
	}

	componentWillUnmount () {
		$('body').removeClass('l-no-header l-no-footer');
	}
		render (){
		return (
				<div className="l-content-width l-main-content-padding">

					<h1 className="c-page-title">Not Found</h1>

					<p>Page not found. Please navigate to another page.</p>
				</div>
			);	
	}
}

export default NotFound; 