import React from 'react';
import ReactDOM from 'react-dom/client';
// import './index.scss';
import '../src/scss/app.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('main'));
const appMode = { mode: 'web' };

root.render(

  <App appMode={appMode.mode} />

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
