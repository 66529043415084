import React from 'react';
import $ from 'jquery';
import TopNav from './TopNav';
import { getHome } from '../modules/Admin';
import {
	showSpinner,
	closeSpinner,
	modal,
	alert
} from '../modules/Dialogs';
import { sessionExpired } from '../config/GeneralConfig';
import { toScreen } from '../modules/Routing';
import PropTypes from 'prop-types';
import withNavigate from './withNavigate';

class Home extends React.Component {

	constructor(props) {
		super(props);

		this.getHome = this.getHome.bind(this);
		this.getHome_done = this.getHome_done.bind(this);

		this.state = {
			jobs: {
				total: '',
				quoted: '',
				quoteless: ''
			},
			clients: {
				total: '',
				active: '',
				blocked: ''
			},
			mavs: {
				total: '',
				pending: '',
				blocked: ''
			},
			messages: {
				total: 0,
				readStandard: 0,
				readDisputes: 0,
				unreadStandard: 0,
				unreadDisputes: 0
			},
			lastLogin: ''
		}
	}

	getHome(event) {

		showSpinner();

		if (event) {
			event.preventDefault();
		}

		const theUser = {
			accountInfo: {
				username: this.props.userDetails.accountInfo.username,
				email: this.props.userDetails.accountInfo.email
			}
		}

		getHome(theUser, this.getHome_done, this.props.userDetails.jwttoken);

	}

	getHome_done(resStatus, responseString, resJson) {

		closeSpinner();

		if (resStatus == 401) {

			modal(sessionExpired);
			toScreen(false, this.props.navigate, '/logout');

		} else if (resStatus == "400") {

			alert(responseString);

		} else if (resStatus == 200) {

			this.setState({
				...this.state,
				jobs: resJson.jobs,
				clients: resJson.clients,
				mavs: resJson.mavs,
				messages: { ...resJson.messages },
				lastLogin: resJson.lastLogin
			});

		}

	}

	componentWillMount() {
		//
	}
	componentDidMount() {
		this.getHome();
	}
	componentWillUnmount() {
		//
		const userDetails = {
			...this.props.userDetails,
			messages: this.state.messages
		};

		this.props.updateState("userDetails", userDetails);
	}

	render() {
		return (
			<div className="l-base-layout__content">
				<h1>Admin / Home</h1>
				<div className="c-hr"></div>

				<div className="l-row">
					<div className="l-col-33">
						<div className="c-info-block" onClick={(e) => toScreen(e, this.props.navigate, "/jobs")}>
							Open Jobs:
	                        <div className="c-info-block__large">
								{this.state?.jobs?.total}
							</div>
							Quoted: {this.state?.jobs?.quoted} <br />
							Not quoted: {this.state?.jobs?.quoteless}
						</div>
					</div>
					<div className="l-col-33">
						<div className="c-info-block c-info-block--green" onClick={(e) => toScreen(e, this.props.navigate, "/clients")}>
							Clients:
	                        <div className="c-info-block__large">
								{this.state?.clients?.total}
							</div>
							Active: {this.state?.clients?.active} <br />
							Blocked: {this.state?.clients?.blocked}
						</div>
					</div>
					<div className="l-col-33">
						<div className="c-info-block c-info-block--teal" onClick={(e) => toScreen(e, this.props.navigate, "/mavs")}>
							MAVs:
	                        <div className="c-info-block__large">
								{this.state?.mavs?.total}
							</div>
							Pending: {this.state?.mavs?.pending} <br />
							Blocked: {this.state?.mavs?.blocked}
						</div>
					</div>
				</div>

				<div className="c-hr"></div>
				<div className="l-row l-row--no-vertical-padding">
					<div className="l-col-33">
						<h2>Tasks</h2>
					</div>
					<div className="l-col-66">

						<div className="c-list-entry c-list-entry--large-text">
							<div className="c-list-entry__primary">
								Unread Messages : <span className="u-color-2 u-font-semi-bold">{this.state?.messages?.unreadStandard}</span>
							</div>
							<button type="button" className="c-btn c-btn--3 c-btn--small" onClick={(e) => toScreen(e, this.props.navigate, "/messages")}>view</button>
						</div>

						<div className="c-list-entry c-list-entry--large-text">
							<div className="c-list-entry__primary">
								Unread Disputes : <span className="u-color-4 u-font-semi-bold">{this.state?.messages?.unreadDisputes}</span>
							</div>
							<button type="button" className="c-btn c-btn--3 c-btn--small" onClick={(e) => toScreen(e, this.props.navigate, "/messages")}>view</button>
						</div>


					</div>
				</div>

				<div className="c-hr"></div>

				<div className="u-clearfix">
					<p className="u-float-right">Last login: <b>{this.state?.lastLogin}</b></p>
				</div>

			</div>
		);
	}

}

Home.contextTypes = {
	router: PropTypes.object
};


export default withNavigate(Home);
