import { serverCall } from '../modules/ServerComms';
import { select } from '../modules/Dialogs';


function retrieveRatingsMav(theMav, theCallback, theJWTToken) {//"59e8e6ecf7a8fa140848c744"

    serverCall("POST", '/api/ratings/mav', theMav, function (resStatus, resJson) {

        var responseString;


        if (resStatus == "400" || resStatus == 400) {

            // novalidcontentmode
            // novalidprofilemode

            if (resJson.errorString == "novaliduserid") {
                responseString = "ERROR: NO USER ID";
            } else if (resJson.errorString == "mavnotfound") {
                responseString = "ERROR: MAV NOT FOUND";
            } else if (resJson.errorString == "unauthorised") {
                responseString = "ERROR: USER NOT AUTHORISED";
            } else if (resJson.errorString == "userblocked") {
                responseString = "ERROR: USER BLOCKED";
            } else if (resJson.errorString == "outdatedappversion") {
                responseString = "ERROR: OUTDATED VERSION";
            } else {
                responseString = "ERROR: UNKNOWN";
            }

            return theCallback(resStatus, responseString, resJson);

        } else if (resStatus == "401" || resStatus == 401) {

            if (resJson.errorString == "unauthorised") {
                responseString = "ERROR: USER NOT AUTHORISED";
            } else if (resJson.errorString == "userblocked") {
                responseString = "ERROR: USER BLOCKED";
            } else if (resJson.errorString == "outdatedappversion") {
                responseString = "ERROR: OUTDATED VERSION";
            } else {
                responseString = "ERROR: UNKNOWN";
            }

            return theCallback(resStatus, responseString, resJson);

        } else if (resStatus == "504" || resStatus == 504 || resStatus == "502" || resStatus == 502) {

            responseString = "ERROR: TIMEOUT";
            return theCallback(resStatus, responseString, false);

        } else if (resStatus == "200" || resStatus == 200) {

            if (resJson.successString == "mavratingsretrieved") {
                responseString = "SUCCESS: MAV RATINGS RETRIEVED";
            }

            return theCallback(resStatus, responseString, resJson);
        }



    }, theJWTToken);

}

function rateApp(event) {

    if (event) {
        event.preventDefault();
    }

    if (typeof device != "undefined") {

        let appId;
        let appStoreLabel;

        if (device.platform == 'iOS' || device.version == 'iPhone') {

            appId = '1455174908';
            appStoreLabel = 'App Store';

        } else {

            appId = 'uk.co.makeonline.manandvan';
            appStoreLabel = 'Play Store';

        }

        if (LaunchReview.isRatingSupported()) {

            LaunchReview.rating(function () {
            }, function (err) {
                console.log(err);
            });
        } else {

            LaunchReview.launch(function () {
            }, function (err) {
                console.log(err);
            }, appId);
        }

    }

}

function rateAppModal(event) {

    if (event) {
        event.preventDefault();
    }

    if (typeof device != "undefined") {

        let appId;
        let appStoreLabel;

        if (device.platform == 'iOS' || device.version == 'iPhone') {

            appId = '1455174908';
            appStoreLabel = 'App Store';

        } else {

            appId = 'uk.co.makeonline.manandvan';
            appStoreLabel = 'Play Store';

        }

        if (LaunchReview.isRatingSupported()) {
            rateApp(false);
        } else {
            select('Enjoying MAN & VAN?', 'Why not let us know what you think with a review on the ' + appStoreLabel + '.', 'Not now', 'Review app', () => { }, () => { rateApp(false) }, 'c-btn--grey');
        }



    }


}

export {
    retrieveRatingsMav,
    rateApp,
    rateAppModal
}