import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { toScreen } from '../modules/Routing';
import withNavigate from './withNavigate';

const Logout = (props) => {

  useEffect(() => {
    toScreen(false, props.navigate, '/signin');

    return () => {
      props.logOut();
    };
  }, [props]);

  return <div></div>;
};

Logout.contextTypes = {
  router: PropTypes.object
};

export default withNavigate(Logout);
