import React from "react";
import Dropdown from "./customSelect";
import PropTypes from 'prop-types';
import infoIcon from '../../img/app/icons/info.svg'
class PushNotifications extends React.Component {
    constructor(props) {
        super(props);
        // Build all props here

        this.state = {
            // States initial values
            selectedUserType: "clients",
            selectedCategory: "",
            selectedCity: "",
            notificationTitle: "",
            message: "",
            selectedDeepLinkOption: ""
        };
    }

    componentWillMount() {
        //
    }

    componentDidMount() {
        //
    }

    componentWillUnmount() {
        //
    }

    handleSelectedUserTypeChange = (event) => {
        this.setState({ selectedUserType: event.target.value });
    }

    handleSelectedCategoryChange = (event) => {
        this.setState({ selectedCategory: event.target.value });
    }

    handleSelectedCityChange = (event) => {
        this.setState({ selectedCity: event.target.value });
    }

    handleTitleInputChange = (event) => {
        this.setState({ notificationTitle: event.target.value });
    }

    handleMessageChange = (event) => {
        this.setState({ message: event.target.value });
    }

    handleDeepLinkOptionChange = (event) => {
        this.setState({ selectedDeepLinkOption: event.target.value });
    }


    render() {
        return (
            <div className="l-base-layout__content">
                <h1>Admin / Send Push Notifications</h1>
                <div className="c-hr"></div>

                <div className="l-col-33">
                    <div className="user-filter-section mb-30">
                        <span className="notifications-label">Select user type</span>
                        <div className="c-checkbox-radio-block">
                            <input
                                type="radio"
                                id="clients"
                                name="user-filter-section"
                                value="clients"
                                onChange={this.handleSelectedUserTypeChange}
                                checked={this.state.selectedUserType === "clients"}
                            />
                            <label htmlFor="clients">Clients</label>
                        </div>
                        <div className="c-checkbox-radio-block">
                            <input
                                type="radio"
                                id="drivers"
                                name="user-filter-section"
                                value="drivers"
                                onChange={this.handleSelectedUserTypeChange}
                                checked={this.state.selectedUserType === "drivers"}
                            />
                            <label htmlFor="drivers">Drivers</label>
                        </div>
                    </div>
                    <div className="category-filter-section mb-30">
                        <span className="notifications-label">Select a category</span>
                        {this.state.selectedUserType === "clients" &&
                            <div className="select-dropdown">
                                <select className="c-input-wide__input" value={this.state.selectedCategory} onChange={this.handleSelectedCategoryChange} name="category-filter-section">
                                    <option value="">Please select</option>
                                    <option value="All Clients (3579)">All Clients (3579)</option>
                                    <option value="Active (2250)">Active (2250)</option>
                                    <option value="Inactive (1181)">Inactive (1181)</option>
                                    <option value="First timers (150)">First timers (150)</option>
                                    <option value="Completed jobs (1881)">Completed jobs (1881)</option>
                                    <option value="Repeat clients (485)">Repeat clients (485)</option>
                                </select>
                            </div>}
                        {this.state.selectedUserType === "drivers" &&
                            <div className="select-dropdown">
                                <select className="c-input-wide__input" value={this.state.selectedCategory} onChange={this.handleSelectedCategoryChange} name="category-filter-section">
                                    <option value="">Please select</option>
                                    <option value="All Drivers (3581)">All All Drivers (3581)</option>
                                    <option value="Active (2250)">Active (2250)</option>
                                    <option value="Idel (1235)">Idel (1235)</option>
                                    <option value="Inactive (2250)">Inactive (2250)</option>
                                    <option value="Haven't quoted (150)">Haven't quoted (150)</option>
                                    <option value="Zero jobs (1881)">Zero jobs (1881)</option>
                                    <option value="Experienced (1296)">Experienced (1296)</option>
                                </select>
                            </div>}
                    </div>
                    <div className="city-filter-section mb-30">
                        <span className="notifications-label">Select a city</span>
                        <div className="select-dropdown">
                            <select className="c-input-wide__input" value={this.state.selectedCity} onChange={this.handleSelectedCityChange} name="category-filter-section">
                                <option value="">Please select</option>
                                <option value="All Cities (3579)">All Cities (20)</option>
                                <option value="London">London</option>
                                <option value="Birmingham">Birmingham</option>
                                <option value="Manchester">Manchester</option>
                                <option value="Glasgow">Glasgow</option>
                                <option value="Liverpool">Liverpool</option>
                                <option value="Leeds">Leeds</option>
                                <option value="Newcastle upon Tyne">Newcastle upon Tyne</option>
                                <option value="Sheffield">Sheffield</option>
                                <option value="Bristol">Bristol</option>
                                <option value="Edinburgh">Edinburgh</option>
                                <option value="Cardiff">Cardiff</option>
                                <option value="Belfast">Belfast</option>
                                <option value="Nottingham">Nottingham</option>
                                <option value="Southampton">Southampton</option>
                                <option value="Leicester">Leicester</option>
                                <option value="Coventry">Coventry</option>
                                <option value="Hull">Hull</option>
                                <option value="Bradford">Bradford</option>
                                <option value="Stoke-on-Trent">Stoke-on-Trent</option>
                                <option value="Wolverhampton">Wolverhampton</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div className="l-col-33">
                    <div className="title-input-section mb-30">
                        <label className="notifications-label">Title <span>65 chars max — only available on Android</span></label>
                        <input
                            type="text"
                            value={this.state.notificationTitle}
                            onChange={this.handleTitleInputChange}
                            name="notificationTitle"
                            placeholder="Type your title here"
                            className="c-input-wide__input"
                        />
                    </div>
                    <div className="message-input-section mb-30">
                        <label className="notifications-label">Message <span>178 chars max</span></label>
                        <textarea
                            type="text"
                            value={this.state.message}
                            onChange={this.handleMessageChange}
                            name="message"
                            placeholder="Type your message here"
                            className="c-input-wide__input"
                        />
                    </div>
                    <div className="deeplink-filter-section mb-30">
                        <label className="notifications-label">Deeplink to screen</label>
                        {this.state.selectedUserType === "clients" && <Dropdown />}
                        {this.state.selectedUserType === "drivers" && <Dropdown />}
                    </div>
                    <div className="send-notification-button">
                        <button className="c-btn c-btn--small c-btn--full-width  c-btn--yellow c-btn--black-text u-margin-bottom-0">Send Push Notification to 3579 Clients</button>
                    </div>
                </div>

                <div className="l-col-33">
                    <div className="tag-notification">
                        <img src={infoIcon} alt="Info Icon" />
                        <p>Tags you can use in your notification:<span>{`{{ first_name }}, {{ last_name }}`}</span></p>
                    </div>

                </div>
            </div>
        );
    }
}

PushNotifications.contextTypes = {
    router: PropTypes.object,
};

PushNotifications.propTypes = {
    userDetails: PropTypes.object.isRequired,
    updateState: PropTypes.func.isRequired,
};

export default PushNotifications;
