import { serverCall } from '../modules/ServerComms';

function getMonthlyRpt(theYear,theCallback, theJWTToken) {

	serverCall("POST", '/api/rptmonthly?crYear=' + theYear, {}, function (resStatus, resJson) {

		let responseString = "ERROR: UNKNOWN";

		if (resStatus == "400" || resStatus == 400) {

			if (resJson.errorString == "nouserdata") {
				responseString = "ERROR: NO USER DATA";
			} else if (resJson.errorString == "novalidjobid") {
				responseString = "ERROR: NO VALID JOB ID";
			} else if (resJson.errorString == "nojobfound") {
				responseString = "ERROR: NO JOB FOUND";
			} else if (resJson.errorString == "jobclosed") {
				responseString = "ERROR: JOB CLOSED";
			} else if (resJson.errorString == "unauthorised") {
				responseString = "ERROR: USER NOT AUTHORISED";
			} else if (resJson.errorString == "userblocked") {
				responseString = "ERROR: USER BLOCKED";
			} else if (resJson.errorString == "outdatedappversion") {
				responseString = "ERROR: OUTDATED VERSION";
			} 

			return theCallback(resStatus, responseString, resJson);

		} else if (resStatus == "401" || resStatus == 401) {

			if (resJson.errorString == "unauthorised") {
				responseString = "ERROR: USER NOT AUTHORISED";
			} else if (resJson.errorString == "userblocked") {
				responseString = "ERROR: USER BLOCKED";
			} else if (resJson.errorString == "outdatedappversion") {
				responseString = "ERROR: OUTDATED VERSION";
			} 

			return theCallback(resStatus, responseString, resJson);

		} else if (resStatus == "504" || resStatus == 504 || resStatus == "502" || resStatus == 502) {

			responseString = "ERROR: TIMEOUT";
			return theCallback(resStatus, responseString, false);

		} else if (resStatus == "200" || resStatus == 200) {

			if (resJson.successString == "jobretrieved") {
				responseString = "SUCCESS: JOB RETRIEVED";
			}

			return theCallback(resStatus, responseString, resJson);
		}



	}, theJWTToken);

}

export {
	getMonthlyRpt
}