import { scrollAnimTime, mixpanelEnabled } from "../config/GeneralConfig";
import $ from "jquery";
import { select, modal, countMyModals, closeModal } from "../modules/Dialogs";
import { rateAppModal } from "../modules/Ratings";
import { mixpanelTrack } from "../modules/MixpanelTracking";
import Version from "../version/Version";
import { resumeUser, updateUser } from "../modules/Authentication";

var _ = require("lodash");

// function toScreen(event, theContext, theUrl, theTransition) {
//   if (event) {
//     event.preventDefault();
//   }

//   const toScreen_now = () => {
//     document.lastRoute = theContext.router.history.location.pathname;

//     if (theTransition) {
//       document.startSlideOff(theTransition, false, () => {
//         theContext.router.history.push(theUrl);
//       });
//     } else {
//       theContext.router.history.push(theUrl);
//     }
//   };

//   if (theContext.router.history.location.pathname != theUrl) {
//     if (document.leavePageInter) {
//       document.leavePageInter(() => {
//         toScreen_now();
//       }, theUrl);
//     } else {
//       toScreen_now();
//     }
//   }
// }

function toScreen(event, navigate, theUrl, theTransition, extraAttributes) {
  if (event) {
    event.preventDefault();
  }

  const toScreen_now = () => {
    document.lastRoute = window.location.pathname;

    if (theTransition) {
      document.startSlideOff(theTransition, false, () => {
        navigate(theUrl);
      });
    } else {
      if(extraAttributes){
        navigate(theUrl,{
          state: {
            ...extraAttributes
          }
        });  
      } else {
        navigate(theUrl);
      }
    }
  };

  if (window.location.pathname !== theUrl) {
    if (document.leavePageInter) {
      document.leavePageInter(() => {
        toScreen_now();
      }, theUrl);
    } else {
      toScreen_now();
    }
  }
}


// function goBack(event, navigate, multipleBack) {
//   if (event) {
//     event.preventDefault();
//   }

//   const goBack_now = () => {
//     if (document.closeModalExtra) {
//       document.closeModalExtra();
//     }

//     if (document.closeFilterMenu) {
//       document.closeFilterMenu(false, false, true);
//     }

//     document.lastRoute = null;

//     document.startSlideOff("toRight", false, () => {
//       if (multipleBack) {
//         navigate(multipleBack);
//       } else {
//         goBack();
//       }
//     });
//   };

//   if (
//     theContext.router.route.location.search != "?noback" &&
//     theContext.router.history.location &&
//     theContext.router.history.location.pathname &&
//     document.leavePageInter &&
//     !multipleBack
//   ) {
//     document.leavePageInter(() => {
//       goBack_now();
//     }, document.lastRoute);
//   } else if (
//     theContext.router.route.location.search != "?noback" &&
//     theContext.router.history.location &&
//     theContext.router.history.location.pathname
//   ) {
//     goBack_now();
//   } else {
//     if (document.clearTopNavButtons) {
//       document.clearTopNavButtons();
//     }
//   }
// }

function goBack(event, navigate, multipleBack) {
  if (event) {
    event.preventDefault();
  }

  const navigateBack = () => {
    if (document.closeModalExtra) {
      document.closeModalExtra();
    }

    if (document.closeFilterMenu) {
      document.closeFilterMenu(false, false, true);
    }

    document.lastRoute = null;

    document.startSlideOff("toRight", false, () => {
      if (multipleBack) {
        navigate(multipleBack);
      } else {
        navigate(-1);
      }
    });
  };

  if (
    navigate &&
    !navigate.location?.search?.includes("?noback") &&
    document.leavePageInter &&
    !multipleBack
  ) {
    document.leavePageInter(() => {
      navigateBack();
    }, document.lastRoute);
  } else if (navigate && !navigate.location?.search?.includes("?noback")) {
    navigateBack();
  } else {
    if (document.clearTopNavButtons) {
      document.clearTopNavButtons();
    }
  }
}

function appPause(event) {
  if (event) {
    event.preventDefault();
  }

  trackEvent("apppaused", false, this.props.userDetails.jwttoken);
}

function appResume(event) {
  if (event) {
    event.preventDefault();
  }

  trackEvent("appresumed", false, this.props.userDetails.jwttoken);

  window.handleOpenURLNow();

  checkOnlineOffline();

  const theUser = {
    accountInfo: {
      username: this.props.userDetails.accountInfo.username,
      email: this.props.userDetails.accountInfo.email,
    },
  };

  const userResumeNow = () => {
    resumeUser(
      theUser,
      (resStatus, resString, resJson) => {
        if (resStatus == "200") {
          if (resJson.resumeAction && !_.isEqual(resJson.resumeAction, {})) {
            if (resJson.resumeAction.type == "route") {
              select(
                resJson.resumeAction.title,
                resJson.resumeAction.message,
                resJson.resumeAction.additionalData.cancelLabel || "Close",
                resJson.resumeAction.additionalData.confirmLabel || "View",
                () => {},
                () => {
                  toScreen(false, this.context, resJson.resumeAction.route);
                }
              );
            } else if (resJson.resumeAction.type == "notification") {
              modal(
                resJson.resumeAction.message,
                resJson.resumeAction.title,
                () => {}
              );
            } else if (resJson.resumeAction.type == "quotereview") {
              document.reviewQuote(
                false,
                resJson.resumeAction.quote,
                resJson.resumeAction.title,
                resJson.resumeAction.message
              );
            }
          }
        }
      },
      this.props.userDetails.jwttoken
    );
  };

  const userResume = () => {
    setTimeout(() => {
      if (countMyModals() > 0) {
        window.setComponentsCallback = () => {
          userResumeNow();
        };
      } else {
        userResumeNow();
      }
    }, 1000);
  };

  if (this.props.appMode == "mobile") {
    if (
      this.props.userDetails.jwttoken != null &&
      this.props.userDetails.accountInfo.username != null
    ) {
      var regId = localStorage.getItem("registrationId");
      var regType = localStorage.getItem("registrationType");
      var regPlatform = localStorage.getItem("registrationPlatform");

      // NOTE : Server checks size and contents

      let theUser = {
        accountInfo: {
          username: this.props.userDetails.accountInfo.username,
          email: this.props.userDetails.accountInfo.email,
        },
        user: {
          short_id: this.props.userDetails.short_id,
          pushRegistrationId: "",
          pushRegistrationType: "",
          pushRegistrationPlatform: "",
          AppVersion: Version.AppVersion,
        },
      };

      if (regId) {
        theUser.user.pushRegistrationId = regId;
      }

      if (regType) {
        theUser.user.pushRegistrationType = regType;
      }

      if (regPlatform) {
        theUser.user.pushRegistrationPlatform = regPlatform;
      }

      updateUser(
        theUser,
        (resStatus, resString, resJson) => {
          if (resStatus == "200") {
            // Note : Messages now only cleared on tap
            // FirebasePlugin.setBadgeNumber(0);
            // FirebasePlugin.clearAllNotifications();

            userResume();
          }
        },
        this.props.userDetails.jwttoken
      );
    } else {
      // Note : Messages now only cleared on tap
      // FirebasePlugin.setBadgeNumber(0);
      // FirebasePlugin.clearAllNotifications();

      userResume();
    }
  } else {
    userResume();
  }
}

var prevScrollpos = 0;

function handleScroll(e) {
  var currentScrollPos = window.pageYOffset;
  if (prevScrollpos > currentScrollPos) {
    showNav();
  } else if (prevScrollpos < currentScrollPos) {
    hideNav();
  }
  prevScrollpos = currentScrollPos;
}

function hideNav(e) {
  $(".c-bottom-nav").addClass("is-hidden");

  document.invisibleBottomNavTimeout = setTimeout(function () {
    $(".c-bottom-nav").addClass("is-invisible");
  }, 300);
}

function showNav(e) {
  if (
    typeof device === "undefined" ||
    (device.platform != "iOS" && device.version != "iPhone") ||
    !Keyboard.isVisible
  ) {
    if (document.invisibleBottomNavTimeout) {
      clearTimeout(document.invisibleBottomNavTimeout);
    }
    $(".c-bottom-nav").removeClass("is-invisible");
    $(".c-bottom-nav").removeClass("is-hidden");
  }
}

function scrollBottom() {
  document
    .getElementsByTagName("body")[0]
    .scrollTo(0, document.getElementsByTagName("body")[0].scrollHeight);
}

function scrollToID(theId, isIos) {
  if (isIos) {
    if (
      $("body").scrollTop() <
      $("#" + theId).offset().top - ($("#top-nav").height() + 10)
    ) {
      setTimeout(() => {
        $("body").animate(
          {
            scrollTop:
              $("#" + theId).offset().top - ($("#top-nav").height() + 10),
          },
          scrollAnimTime
        );
      }, 100);
    }
  } else {
    if (
      $("html").scrollTop() <
      $("#" + theId).offset().top - ($("#top-nav").height() + 10)
    ) {
      setTimeout(() => {
        $("html").animate(
          {
            scrollTop:
              $("#" + theId).offset().top - ($("#top-nav").height() + 10),
          },
          scrollAnimTime
        );
      }, 100);
    }
  }
}

function trackEvent(theMode, theContext, theJWT) {
  if (mixpanelEnabled) {
    var mixpanelEvent = { eventName: "", eventProps: {} };

    const mixPanelTrackNow = () => {
      mixpanelTrack(
        mixpanelEvent,
        (resStatus, responseString, resJson) => {},
        theJWT
      );
    };

    if (theJWT) {
      if (theMode == "screenview") {
        clearTimeout(document.trackTimeOut);

        document.trackTimeOut = setTimeout(() => {
          var curRoute =
            theContext.router.route.location.pathname.split("/")[1];
          var curSubRoute =
            theContext.router.route.location.pathname.split("/")[2];
          var curSubSubRoute =
            theContext.router.route.location.pathname.split("/")[3];

          mixpanelEvent.eventName = "Screen View";

          mixpanelEvent.eventProps.screen_name = curRoute;

          if (curRoute == "book" && curSubRoute) {
            mixpanelEvent.eventProps.screen_name += "-" + curSubRoute;
          }

          if (curRoute == "booking" && curSubRoute) {
            mixpanelEvent.eventProps.jobId = curSubRoute;
          }

          if (curRoute == "quote" && curSubRoute) {
            mixpanelEvent.eventProps.quoteId = curSubRoute;
          }

          if (curRoute == "message" && curSubRoute) {
            mixpanelEvent.eventProps.conversationType = curSubRoute;

            if (curSubSubRoute) {
              mixpanelEvent.eventProps.conversationWith = curSubSubRoute;
            }
          }

          mixPanelTrackNow();
        }, 100);
      } else if (theMode == "signin") {
        mixpanelEvent.eventName = "Sign In";
        mixPanelTrackNow();
      } else if (theMode == "logout") {
        mixpanelEvent.eventName = "Logout";
        mixPanelTrackNow();
      } else if (theMode == "apppaused") {
        mixpanelEvent.eventName = "App Paused";
        mixPanelTrackNow();
      } else if (theMode == "appresumed") {
        mixpanelEvent.eventName = "App Resumed";
        mixPanelTrackNow();
      }
    }
  }
}

function setComponents(
  theContext,
  noHeader,
  noFooter,
  noTopNav,
  noBottomNav,
  hasNavHide,
  cancelScroll,
  theCallback,
  skipLeavePageInterClear
) {
  var addClass = "";

  if (noHeader) {
    if (addClass != "") {
      addClass += " ";
    }
    addClass += "l-no-header";
  }

  if (noFooter) {
    if (addClass != "") {
      addClass += " ";
    }
    addClass += "l-no-footer";
  }

  if (noTopNav) {
    if (addClass != "") {
      addClass += " ";
    }
    addClass += "l-no-top-nav";
  }

  if (noBottomNav) {
    if (addClass != "") {
      addClass += " ";
    }
    addClass += "l-no-bottom-nav";
  } else {
    $("#wrapper").removeClass("l-no-bottom-nav");
    $("#wrapper .c-bottom-nav").removeClass("is-hidden");
  }

  if (hasNavHide) {
    window.addEventListener("scroll", handleScroll);
    //window.addEventListener('keyboardDidHide', showNav);//keyboardDidShow
  } else {
    window.removeEventListener("scroll", handleScroll);
    $("#wrapper .c-bottom-nav").removeClass("is-hidden");
    //window.removeEventListener('keyboardDidHide', showNav);
    showNav(false);
  }

  $("#wrapper").addClass(addClass);

  $("body").removeClass("u-overflow-hidden");
  document.scrollBlocked = false;

  window.handleOpenURLNow = () => {
    const openUrlRef = localStorage.getItem("openUrl");
    if (openUrlRef && openUrlRef != "undefined") {
      var openUrlRefSplit = openUrlRef.split("manandvanapp:/");

      localStorage.removeItem("openUrl");

      //theContext.router.history.push(openUrlRefSplit[1]);

      toScreen(false, theContext, openUrlRefSplit[1]);
    }
  };

  if (!skipLeavePageInterClear) {
    document.leavePageInter = null;
  }

  document.closeModalExtra = null;

  //window.handleOpenURLNow();

  document.addEventListener("offline", offline, false);
  document.addEventListener("online", online, false);

  //window.addEventListener('keyboardDidShow', hideHeader);
  //window.addEventListener('keyboardDidHide', showHeader);

  document.backButtonHit = (e) => {
    goBack(e, theContext);
  };

  showHeader();

  var networkState;

  if (navigator && navigator.connection && navigator.connection.type) {
    networkState = navigator.connection.type;
  }

  if (
    navigator &&
    navigator.connection &&
    navigator.connection.type &&
    networkState == "Connection.NONE"
  ) {
    offline();
  }

  document.intercomUnreadConversationCount();

  if (window.setComponentsCallback) {
    window.setComponentsCallback();
    window.setComponentsCallback = null;
  }

  if (document.finishSlideOff) {
    document.finishSlideOff(false, false, () => {
      if (!cancelScroll) {
        window.scroll(0, 0);
      }

      if (theCallback) {
        theCallback();
      }
    });
  } else {
    if (document.clearSlideOff) {
      document.clearSlideOff();
    }

    if (!cancelScroll) {
      window.scroll(0, 0);
    }

    if (theCallback) {
      theCallback();
    }
  }
}

function hideHeader() {
  var theDomObject = document.getElementById("top-nav");
  theDomObject.classList.add("is-hidden");
}

function showHeader() {
  var theDomObject = document.getElementById("top-nav");
  if (theDomObject) {
    theDomObject.classList.remove("is-hidden");
  }
}

function online() {
  if (window.wentOffline) {
    closeModal();
  }
}

function offline() {
  window.wentOffline = true;
  modal(
    "Please connect to a mobile or Wi-Fi network to use this app.",
    "You are offline",
    false,
    true
  );
}

function checkOnlineOffline() {
  var networkState;

  if (navigator && navigator.connection && navigator.connection.type) {
    networkState = navigator.connection.type;
  }

  if (
    navigator &&
    navigator.connection &&
    navigator.connection.type &&
    networkState != "Connection.NONE" &&
    window.wentOffline
  ) {
    closeModal();
  }
}

function unSetComponents(
  theContext,
  noHeader,
  noFooter,
  noTopNav,
  noBottomNav,
  hasNavHide,
  cancelScroll
) {
  var removeClass = "";

  if (noHeader) {
    if (removeClass != "") {
      removeClass += " ";
    }
    removeClass += "l-no-header";
  }

  if (noFooter) {
    if (removeClass != "") {
      removeClass += " ";
    }
    removeClass += "l-no-footer";
  }

  if (noTopNav) {
    if (removeClass != "") {
      removeClass += " ";
    }
    removeClass += "l-no-top-nav";
  }

  if (noBottomNav) {
    if (removeClass != "") {
      removeClass += " ";
    }
    removeClass += "l-no-bottom-nav";
  }

  if (hasNavHide) {
    window.removeEventListener("scroll", handleScroll);
    //window.removeEventListener('keyboardDidHide', showNav);
    $(".c-bottom-nav").removeClass("is-hidden");
  }

  $("#wrapper").removeClass(removeClass);
  if (!cancelScroll) {
    window.scrollTo(0, 0);
  }

  window.handleOpenURLNow = () => {};

  document.removeEventListener("offline", offline, false);
  document.removeEventListener("online", online, false);
  //window.removeEventListener('keyboardDidShow', hideHeader);
  //window.removeEventListener('keyboardDidHide', showHeader);

  document.backButtonHit = (e) => {};
}

function componentLoaded(theContext) {
  if (document.appReviewNudge) {
    document.appReviewNudge = false;
    rateAppModal(false);
  }
}

export {
  toScreen,
  goBack,
  appPause,
  appResume,
  trackEvent,
  setComponents,
  unSetComponents,
  hideNav,
  showNav,
  scrollBottom,
  scrollToID,
  checkOnlineOffline,
  componentLoaded,
};
