import $ from "jquery";
import moment from "moment-timezone";
import _ from "lodash";
import {
  quoteDeclineFeedback,
  messageRulesBlocks
} from "../config/GeneralConfig";
import { validate_message, validate_longcopy } from "../modules/Generic";
// close js generated modals
$(document).on("click", ".js-close-modal", function () {
  if (document.closeModalExtra) {
    document.closeModalExtra(false);
  }
  var $this = $(this);
  $this.closest(".js-js-modal").removeClass("c-modal--visible");
  setTimeout(function () {
    if (document.theCallback) {
      document.theCallback();
      document.theCallback = null;
    }
    $this.closest(".js-js-modal").remove();
  }, 300);
});

$(document).on("click", ".js-image-close-modal", function () {
  if (document.closeModalExtra) {
    document.closeModalExtra(false);
  }
  var $this = $(this);
  $this.closest(".js-js-modal").removeClass("c-modal--visible");
  setTimeout(function () {
    $this.closest(".js-js-modal").remove();
  }, 300);
});

$(document).on("click", ".js-accept-modal", function () {
  var $this = $(this);
  $this.closest(".js-js-modal").removeClass("c-modal--visible");
  setTimeout(function () {
    if (document.timeoutCallback) {
      document.timeoutCallback();
      document.timeoutCallback = null;
    }
    $this.closest(".js-js-modal").remove();
  }, 300);
});

$(document).on("click", ".js-accept-modal-choicea", function () {
  var $this = $(this);
  $this.closest(".js-js-modal").removeClass("c-modal--visible");
  setTimeout(function () {
    if (document.choiceACallback) {
      document.choiceACallback();
      document.choiceACallback = null;
      // document.choiceBCallback = null;
      // document.choiceCCallback = null;
    }
    $this.closest(".js-js-modal").remove();
  }, 300);
});

$(document).on("click", ".js-accept-modal-choiceb", function () {
  var $this = $(this);
  $this.closest(".js-js-modal").removeClass("c-modal--visible");
  setTimeout(function () {
    if (document.choiceBCallback) {
      document.choiceBCallback();
      //document.choiceACallback = null;
      document.choiceBCallback = null;
      //document.choiceCCallback = null;
    }
    $this.closest(".js-js-modal").remove();
  }, 300);
});

$(document).on("click", ".js-accept-modal-choicec", function () {
  var $this = $(this);
  $this.closest(".js-js-modal").removeClass("c-modal--visible");
  setTimeout(function () {
    if (document.choiceCCallback) {
      document.choiceCCallback();
      // document.choiceACallback = null;
      // document.choiceBCallback = null;
      document.choiceCCallback = null;
    }
    $this.closest(".js-js-modal").remove();
  }, 300);
});

$(document).on("click", ".js-accept-modal-choicedecline", function () {
  const declineNow = (theReason, theComment) => {
    var $this = $(this);
    $this.closest(".js-js-modal").removeClass("c-modal--visible");
    setTimeout(() => {
      if (document.choiceDeclineCallback) {
        document.choiceDeclineCallback(theReason, theComment);
        // document.choiceACallback = null;
        // document.choiceBCallback = null;
        document.choiceDeclineCallback = null;
      }
      $this.closest(".js-js-modal").remove();
    }, 300);
  };

  var canSubmit;
  var theReason;
  var theValue;

  _.forEach(quoteDeclineFeedback, (value, key) => {
    if ($("#decline" + key).prop("checked")) {
      theReason = key;

      if (key == _.size(quoteDeclineFeedback)) {
        canSubmit = "validate";
      } else {
        canSubmit = "yes";
      }
    }
  });

  if (canSubmit == "yes") {
    declineNow(theReason, false);
  } else if (canSubmit == "validate") {
    var isValid = true;

    theValue = $("#theFeedback").val();

    validate_message(theValue, "feedback", (updatedFlags, updatedBlocks) => {
      isValid = validate_longcopy(theValue);

      if (isValid && _.size(updatedBlocks) <= 0) {
        declineNow(theReason, theValue);
      }
    });
  }
});

//remove modals if back or forward is pressed
$(document).ready(function () {
  if (window.history && window.history.pushState) {
    $(window).on("popstate", function () {
      $(".js-js-modal").remove();
      document.theCallback = null;
      document.choiceACallback = null;
      document.choiceBCallback = null;
      document.choiceCCallback = null;
      document.choiceDeclineCallback = null;
      if (document.closeModalExtra) {
        document.closeModalExtra(false);
      }
    });
  }
});

function closeModal() {
  $(".js-js-modal-loader").remove();
  $(".js-js-modal").remove();
  document.theCallback = null;
  document.choiceACallback = null;
  document.choiceBCallback = null;
  document.choiceCCallback = null;
  document.choiceDeclineCallback = null;
  if (document.closeModalExtra) {
    document.closeModalExtra(false);
  }
}

function countMyModals() {
  let visibleModals = document.getElementsByClassName("js-js-modal");
  return _.size(visibleModals);
}

function modal(theMessage, theHeading, theCallback, blockClose) {
  $(".js-js-modal-loader").remove();
  $(".js-js-modal").remove();
  document.theCallback = null;
  document.choiceACallback = null;
  document.choiceBCallback = null;
  document.choiceCCallback = null;
  document.choiceDeclineCallback = null;
  if (document.closeModalExtra) {
    document.closeModalExtra(false);
  }

  if (theCallback) {
    document.theCallback = () => {
      theCallback();
    };
  }

  var modalHTML;

  var headingString = "";

  var closeString = "";

  var okString = "";

  if (theHeading) {
    headingString = '<p class="c-modal__title">' + theHeading + "</p>";
  }

  if (!blockClose) {
    closeString = " js-close-modal";
    okString =
      '<button class="c-btn c-btn--small c-btn--teal js-close-modal">OK</button>';
  }

  modalHTML =
    '<div class="c-modal c-modal--visible js-js-modal"><div class="c-modal__bg' +
    closeString +
    '"></div><div class="c-modal__inner">' +
    headingString +
    '<p class="c-modal__copy">' +
    theMessage +
    "</p>" +
    okString +
    "</div></div>";

  $("body").append(modalHTML);
  setTimeout(function () {
    $(".js-js-modal").addClass("c-modal--visible");
  }, 0);
}

function updateApp(theMessage, theHeading, theCallback, blockClose) {
  $(".js-js-modal-loader").remove();
  $(".js-js-modal").remove();
  document.theCallback = null;
  document.choiceACallback = null;
  document.choiceBCallback = null;
  document.choiceCCallback = null;
  document.choiceDeclineCallback = null;
  if (document.closeModalExtra) {
    document.closeModalExtra(false);
  }

  if (theCallback) {
    document.theCallback = () => {
      theCallback();
    };
  }

  var modalHTML;

  var headingString = "";

  var closeString = "";

  var okString = "";

  if (theHeading) {
    headingString = '<p class="c-modal__title">' + theHeading + "</p>";
  }

  if (!blockClose) {
    closeString = " js-close-modal";
    okString =
      '<button class="c-btn c-btn--small c-btn--teal js-close-modal">Update</button>';
  }

  modalHTML =
    '<div class="c-modal c-modal--visible js-js-modal"><div class="c-modal__bg' +
    closeString +
    '"></div><div class="c-modal__inner">' +
    headingString +
    '<p class="c-modal__copy">' +
    theMessage +
    "</p>" +
    okString +
    "</div></div>";

  $("body").append(modalHTML);
  setTimeout(function () {
    $(".js-js-modal").addClass("c-modal--visible");
  }, 0);
}

function modalwithtip(theMessage, theHeading, theCallback, blockClose) {
  //"Hint: Make sure you have the readies ready!",
  var theTips = [
    "Hint: Remember the first quote you get might not be the best quote!",
    "Hint: Pay the Driver the balance on completion of the job",

    "Hint: Have everything ready before they arrive!",
    "Hint: Remember to review your Man and Van afterwards!",
    "Hint: Remember we have Live Support!",
    "Hint: Let us know how we're doing?! Leave the app a Review",
  ];

  $(".js-js-modal-loader").remove();
  $(".js-js-modal").remove();
  document.theCallback = null;
  document.choiceACallback = null;
  document.choiceBCallback = null;
  document.choiceCCallback = null;
  document.choiceDeclineCallback = null;
  if (document.closeModalExtra) {
    document.closeModalExtra(false);
  }

  if (theCallback) {
    document.theCallback = () => {
      theCallback();
    };
  }

  var modalHTML;

  var headingString = "";

  var tipString = "";

  var closeString = "";

  var okString = "";

  if (theHeading) {
    headingString =
      '<p class="c-modal__title u-after-icon-check u-color-teal u-font-size-24">' +
      theHeading +
      "</p>";
  } else {
    headingString =
      '<p class="c-modal__title u-after-icon-check u-color-teal u-font-size-24"></p>';
  }

  var tipRandom = Math.floor(Math.random() * theTips.length);

  tipString =
    '<p class="u-color-teal u-font-size-14">' + theTips[tipRandom] + "</p>";

  if (!blockClose) {
    closeString = " js-close-modal";
    okString =
      '<button class="c-btn c-btn--small c-btn--teal js-close-modal">CLOSE</button>';
  }

  modalHTML =
    '<div class="c-modal c-modal--visible js-js-modal"><div class="c-modal__bg' +
    closeString +
    '"></div><div class="c-modal__inner">' +
    headingString +
    '<p class="c-modal__copy">' +
    theMessage +
    "</p>" +
    tipString +
    okString +
    "</div></div>";

  $("body").append(modalHTML);
  setTimeout(function () {
    $(".js-js-modal").addClass("c-modal--visible");
  }, 0);
}

function modalwithnote(
  theMessage,
  theHeading,
  theNote,
  theCallback,
  blockClose
) {
  $(".js-js-modal-loader").remove();
  $(".js-js-modal").remove();
  document.theCallback = null;
  document.choiceACallback = null;
  document.choiceBCallback = null;
  document.choiceCCallback = null;
  document.choiceDeclineCallback = null;
  if (document.closeModalExtra) {
    document.closeModalExtra(false);
  }

  if (theCallback) {
    document.theCallback = () => {
      theCallback();
    };
  }

  var modalHTML;

  var headingString = "";

  var tipString = "";

  var closeString = "";

  var okString = "";

  if (theHeading) {
    headingString =
      '<p class="c-modal__title u-after-icon-check u-color-teal u-font-size-24">' +
      theHeading +
      "</p>";
  } else {
    headingString =
      '<p class="c-modal__title u-after-icon-check u-color-teal u-font-size-24"></p>';
  }

  tipString = '<p class="u-color-teal u-font-size-14">' + theNote + "</p>";

  if (!blockClose) {
    closeString = " js-close-modal";
    okString =
      '<button class="c-btn c-btn--small c-btn--teal js-close-modal">CLOSE</button>';
  }

  modalHTML =
    '<div class="c-modal c-modal--visible js-js-modal"><div class="c-modal__bg' +
    closeString +
    '"></div><div class="c-modal__inner">' +
    headingString +
    '<p class="c-modal__copy">' +
    theMessage +
    "</p>" +
    tipString +
    okString +
    "</div></div>";

  $("body").append(modalHTML);
  setTimeout(function () {
    $(".js-js-modal").addClass("c-modal--visible");
  }, 0);
}

function gallery(imageUrl, swipeLeftCallBack, swipeRightCallBack) {
  $(".js-js-modal-loader").remove();
  $(".js-js-modal").remove();
  document.theCallback = null;
  document.choiceACallback = null;
  document.choiceBCallback = null;
  document.choiceCCallback = null;
  document.choiceDeclineCallback = null;
  if (document.closeModalExtra) {
    document.closeModalExtra(false);
  }

  //var modalHTML = '<div class="c-modal c-modal--visible js-js-modal"><div class="c-modal__bg js-close-modal"></div><div class="c-modal__inner"><button class="c-modal__close-modal js-close-modal"><span class="u-visually-hidden">X</span></button><img class="js-image-close-modal" src="'+imageUrl+'"/></div></div>';

  if (swipeLeftCallBack && swipeRightCallBack) {
    var modalHTML =
      '<div class="c-modal c-modal--visible js-js-modal"><div class="c-modal__bg js-close-modal"></div><div class="c-modal__inner" id="gallery-swipe"><img id="gallery-image" class="js-image" src="' +
      imageUrl +
      '"/><span class="u-before-icon-angle-left u-after-icon-angle-right">&nbsp;Swipe to view</span></br><span class="u-after-icon-cancel">&nbsp;Tap to close&nbsp;</span></div></div>';

    $("body").append(modalHTML);
    setTimeout(function () {
      $(".js-js-modal").addClass("c-modal--visible");
    }, 0);

    var startX = 0;
    var startY = 0;
    var startTime;
    var endTime;

    function handleTouchstart(e) {
      var touchobj = e.changedTouches[0];
      startX = parseInt(touchobj.clientX);
      startY = parseInt(touchobj.clientY);
      startTime = moment.tz(new Date(), "Europe/London");
      //e.preventDefault();
    }

    function handleTouchend(e) {
      var touchobj = e.changedTouches[0];
      var diffX = parseInt(touchobj.clientX) - startX;
      var diffY = parseInt(touchobj.clientY) - startY;
      endTime = moment.tz(new Date(), "Europe/London");
      if (
        endTime.diff(startTime) < 50 &&
        diffX < 20 &&
        diffX > -20 &&
        diffY < 20 &&
        diffY > -20
      ) {
        $(".js-js-modal").removeClass("c-modal--visible");
        setTimeout(function () {
          $(".js-js-modal").remove();
          document.theCallback = null;
          document.choiceACallback = null;
          document.choiceBCallback = null;
          document.choiceCCallback = null;
          document.choiceDeclineCallback = null;
        }, 300);
      } else if (endTime.diff(startTime) < 200) {
        if (diffX < -50 && diffY < 50 && diffY > -50) {
          swipeLeftCallBack();
        } else if (diffX > 50 && diffY < 50 && diffY > -50) {
          swipeRightCallBack();
        }
      }

      //e.preventDefault()
    }

    document
      .getElementById("gallery-swipe")
      .addEventListener("touchstart", handleTouchstart, false);
    document
      .getElementById("gallery-swipe")
      .addEventListener("touchend", handleTouchend, false);
  } else {
    var modalHTML =
      '<div class="c-modal c-modal--visible js-js-modal"><div class="c-modal__bg js-close-modal"></div><div class="c-modal__inner"><img class="js-image-close-modal" src="' +
      imageUrl +
      '"/><span class="u-after-icon-cancel">&nbsp;Tap to close&nbsp;</span></div></div>';

    $("body").append(modalHTML);
    setTimeout(function () {
      $(".js-js-modal").addClass("c-modal--visible");
    }, 0);
  }
}

function switchGalleryImage(imageUrl) {
  document.getElementById("gallery-image").src = imageUrl;
}

function confirm(
  theHeading,
  theMessage,
  theCancelLabel,
  theConfirmLabel,
  theCallback
) {
  $(".js-js-modal-loader").remove();
  $(".js-js-modal").remove();
  document.theCallback = null;
  document.choiceACallback = null;
  document.choiceBCallback = null;
  document.choiceCCallback = null;
  document.choiceDeclineCallback = null;
  if (document.closeModalExtra) {
    document.closeModalExtra(false);
  }

  document.timeoutCallback = () => {
    theCallback();
  };

  var headingCopy;
  var messageCopy;

  if (theHeading) {
    headingCopy = '<p class="c-modal__title">' + theHeading + "</p>";
  } else {
    headingCopy = "";
  }

  if (theMessage) {
    messageCopy = '<p class="c-modal__copy">' + theMessage + "</p>";
  } else {
    messageCopy = "";
  }

  //var modalHTML = '<div class="c-modal c-modal--visible js-js-modal"><div class="c-modal__bg js-close-modal"></div><div class="c-modal__inner"><button class="c-modal__close-modal js-close-modal"><span class="u-visually-hidden">X</span></button><p class="c-modal__copy">' + theMessage + '</p><button class="c-btn c-btn--small c-btn--grey js-close-modal">'+theCancelLabel+'</button><button class="c-btn c-btn--small c-btn--red u-margin-right-0 js-accept-modal">'+theConfirmLabel+'</button></div></div>';
  var modalHTML =
    '<div class="c-modal c-modal--visible js-js-modal"><div class="c-modal__bg js-close-modal"></div><div class="c-modal__inner">' +
    headingCopy +
    messageCopy +
    '<button class="c-btn c-btn--small c-btn--grey js-close-modal">' +
    theCancelLabel +
    '</button><button class="c-btn c-btn--small c-btn--red u-margin-right-0 js-accept-modal">' +
    theConfirmLabel +
    "</button></div></div>";
  $("body").append(modalHTML);
  setTimeout(function () {
    $(".js-js-modal").addClass("c-modal--visible");
  }, 0);
}

function select(
  theHeading,
  theMessage,
  theChoiceALabel,
  theChoiceBLabel,
  theChoiceACallback,
  theChoiceBCallback,
  theChoiceAStyle,
  theChoiceBStyle,
  theBigIconStyle
) {
  $(".js-js-modal-loader").remove();
  $(".js-js-modal").remove();
  document.theCallback = null;
  document.choiceACallback = null;
  document.choiceBCallback = null;
  document.choiceCCallback = null;
  document.choiceDeclineCallback = null;
  if (document.closeModalExtra) {
    document.closeModalExtra(false);
  }

  document.choiceACallback = () => {
    theChoiceACallback();
  };
  document.choiceBCallback = () => {
    theChoiceBCallback();
  };

  var headingCopy;

  if (theHeading) {
    headingCopy = '<p class="c-modal__title">' + theHeading + "</p>";
  } else {
    headingCopy = "";
  }

  var messageCopy;

  if (theMessage) {
    messageCopy = '<p class="c-modal__copy">' + theMessage + "</p>";
  } else {
    messageCopy = "";
  }

  var bigIcon;

  if (theBigIconStyle) {
    bigIcon =
      '<p class="u-font-size-36 u-margin-bottom-s ' +
      theBigIconStyle +
      '"></p>';
  } else {
    bigIcon = "";
  }

  //var modalHTML = '<div class="c-modal c-modal--visible js-js-modal"><div class="c-modal__bg js-close-modal"></div><div class="c-modal__inner"><button class="c-modal__close-modal js-close-modal"><span class="u-visually-hidden">X</span></button>'+headingCopy+'<p class="c-modal__copy">' + theMessage + '</p><button class="c-btn c-btn--small c-btn--yellow js-accept-modal-choicea">'+theChoiceALabel+'</button><button class="c-btn c-btn--small c-btn--green u-margin-right-0 js-accept-modal-choiceb">'+theChoiceBLabel+'</button></div></div>';
  var modalHTML =
    '<div class="c-modal c-modal--visible js-js-modal"><div class="c-modal__bg js-close-modal"></div><div class="c-modal__inner">' +
    headingCopy +
    bigIcon +
    messageCopy +
    '<button class="c-btn c-btn--small ' +
    (theChoiceAStyle || "c-btn--yellow c-btn--black-text") +
    ' js-accept-modal-choicea">' +
    theChoiceALabel +
    '</button><button class="c-btn c-btn--small ' +
    (theChoiceBStyle || "c-btn--green") +
    ' u-margin-right-0 js-accept-modal-choiceb">' +
    theChoiceBLabel +
    "</button></div></div>";

  $("body").append(modalHTML);
  setTimeout(function () {
    $(".js-js-modal").addClass("c-modal--visible");
  }, 0);
}

function selectAlt(
  theHeading,
  theMessage,
  theChoiceALabel,
  theChoiceBLabel,
  theChoiceACallback,
  theChoiceBCallback,
  theChoiceAStyle,
  theChoiceBStyle,
  theBigIconStyle
) {
  $(".js-js-modal-loader").remove();
  $(".js-js-modal").remove();
  document.theCallback = null;
  document.choiceACallback = null;
  document.choiceBCallback = null;
  document.choiceCCallback = null;
  document.choiceDeclineCallback = null;
  if (document.closeModalExtra) {
    document.closeModalExtra(false);
  }

  document.choiceACallback = () => {
    theChoiceACallback();
  };
  document.choiceBCallback = () => {
    theChoiceBCallback();
  };

  var headingCopy;

  if (theHeading) {
    headingCopy =
      '<p class="c-modal__title-alt u-margin-bottom-s">' + theHeading + "</p>";
  } else {
    headingCopy = "";
  }

  var messageCopy;

  if (theMessage) {
    messageCopy = '<p class="c-modal__copy-alt">' + theMessage + "</p>";
  } else {
    messageCopy = "";
  }

  var bigIcon;

  if (theBigIconStyle) {
    bigIcon =
      '<p class="u-font-size-36 u-margin-bottom-s ' +
      theBigIconStyle +
      '"></p>';
  } else {
    bigIcon = "";
  }

  //var modalHTML = '<div class="c-modal c-modal--visible js-js-modal"><div class="c-modal__bg js-close-modal"></div><div class="c-modal__inner"><button class="c-modal__close-modal js-close-modal"><span class="u-visually-hidden">X</span></button>'+headingCopy+'<p class="c-modal__copy">' + theMessage + '</p><button class="c-btn c-btn--small c-btn--yellow js-accept-modal-choicea">'+theChoiceALabel+'</button><button class="c-btn c-btn--small c-btn--green u-margin-right-0 js-accept-modal-choiceb">'+theChoiceBLabel+'</button></div></div>';
  var modalHTML =
    '<div class="c-modal c-modal--visible js-js-modal"><div class="c-modal__bg js-close-modal"></div><div class="c-modal__inner">' +
    headingCopy +
    bigIcon +
    messageCopy +
    '<button class="c-btn c-btn--full-width c-btn--small-font ' +
    (theChoiceAStyle || "c-btn--yellow c-btn--black-text") +
    ' js-accept-modal-choicea">' +
    theChoiceALabel +
    '</button><button class="c-btn c-btn--full-width c-btn--small-font ' +
    (theChoiceBStyle || "c-btn--green") +
    ' u-margin-right-0 js-accept-modal-choiceb">' +
    theChoiceBLabel +
    "</button></div></div>";

  $("body").append(modalHTML);
  setTimeout(function () {
    $(".js-js-modal").addClass("c-modal--visible");
  }, 0);
}

function declinefeedback(theHeading, theMessage, theDeclineCallback) {
  $(".js-js-modal-loader").remove();
  $(".js-js-modal").remove();
  document.theCallback = null;
  document.choiceACallback = null;
  document.choiceBCallback = null;
  document.choiceCCallback = null;
  document.choiceDeclineCallback = null;
  if (document.closeModalExtra) {
    document.closeModalExtra(false);
  }

  document.choiceACallback = () => { };
  document.choiceDeclineCallback = (theReason, theComment) => {
    theDeclineCallback(theReason, theComment);
  };

  var headingCopy;

  if (theHeading) {
    headingCopy = '<p class="c-modal__title">' + theHeading + "</p>";
  } else {
    headingCopy = "";
  }

  var messageCopy;

  if (theMessage) {
    messageCopy = '<p class="c-modal__copy">' + theMessage + "</p>";
  } else {
    messageCopy = "";
  }

  var feedbackRadios = "";

  _.forEach(quoteDeclineFeedback, (value, key) => {
    if (key > 0) {
      //c-check-box-radio--small
      feedbackRadios +=
        `<div class="c-check-box-radio  u-text-align-left u-margin-top-0 u-margin-bottom-s">
			<input class="c-check-box-radio-radiostyle__input" type="radio" name="declineReason" value="decline` +
        key +
        `" id="decline` +
        key +
        `">
			<label class="c-check-box-radio__label" for="decline` +
        key +
        `">
				<div class="c-check-box-radio-radiostyle__label-indicator"></div>
				<div class="c-check-box-radio__label-text c-check-box-radio__label-text-modal u-line-height-125">${value}</div>
			</label>
		</div>`;
    }
  });

  var modalHTML =
    `<div class="c-modal c-modal--visible js-js-modal">
	<div class="c-modal__bg js-close-modal"></div>
	<div class="c-modal__inner">` +
    headingCopy +
    messageCopy +
    `<form id="declineform" class="c-modal__form u-margin-bottom-m" target="_self">` +
    feedbackRadios +
    `<div class="c-input c-input--textarea u-display-none" id="theFeedbackWrapper">
				<label class="c-input__inner-wrap u-color-white-bg">
					<span class="c-input__label">Reasons / Comments</span>
					<textarea type="text" value="" name="theFeedback" id="theFeedback" placeholder="" class="c-input__textarea"></textarea>
				</label>
				<div class="c-input__validation-msg c-input__validation-msg-nopad u-text-align-left u-margin-bottom-0 u-margin-left-0">Must be valid</div>
				<p class="u-color-red u-font-size-12 u-text-align-left u-line-height-15 u-margin-top-s" id="blocksWrapper">
					</p>
			</div>
				</form>` +
    `<button class="c-btn c-btn--small c-btn--green js-accept-modal-choicea">Cancel</button>
	 <button class="c-btn c-btn--small c-btn--red u-margin-right-0 js-accept-modal-choicedecline  c-btn--is-disabled" id="declineButton">Decline</button>
	 </div></div>`;

  $("body").append(modalHTML);
  setTimeout(function () {
    $(".js-js-modal").addClass("c-modal--visible");
  }, 0);

  $("#declineform input").change(function (e) {
    _.forEach(quoteDeclineFeedback, (value, key) => {
      if (key > 0) {
        if (e.target && e.target.id && e.target.id == "decline" + key) {
          $("#decline" + key).prop("checked", true);
        } else {
          $("#decline" + key).prop("checked", false);
        }
      }

      if (
        key == _.size(quoteDeclineFeedback) &&
        e.target.id == "decline" + _.size(quoteDeclineFeedback)
      ) {
        var isValid = true;
        var newFeedbackBlocks = [];

        var theValue = $("#theFeedbackWrapper").val();

        validate_message(theValue, "quote", (updatedFlags, updatedBlocks) => {
          newFeedbackBlocks = updatedBlocks;

          isValid = validate_longcopy(theValue);

          $("#theFeedbackWrapper").removeClass("u-display-none");

          if (isValid && _.size(newFeedbackBlocks) <= 0) {
            $("#declineButton").removeClass("c-btn--is-disabled");
          } else {
            $("#declineButton").addClass("c-btn--is-disabled");
          }
        });
      } else {
        $("#declineButton").removeClass("c-btn--is-disabled");
        $("#theFeedbackWrapper").addClass("u-display-none");
      }
    });
  });

  $("#declineform #theFeedback").bind("input propertychange", function (e) {
    var isValid = true;
    var newFeedbackBlocks = [];

    if (e.target && e.target.value) {
      validate_message(
        e.target.value,
        "feedback",
        (updatedFlags, updatedBlocks) => {
          newFeedbackBlocks = updatedBlocks;

          isValid = validate_longcopy(e.target.value);

          if (isValid) {
            $("#theFeedbackWrapper").removeClass("is-invalid");
          } else {
            $("#theFeedbackWrapper").addClass("is-invalid");
          }

          if (_.size(newFeedbackBlocks) > 0) {
            var newFeedbackBlocksHtml =
              '<span class="u-before-icon-exclamation u-font-semi-bold  u-margin-top-s u-margin-bottom-0">&nbsp;Your message is prohibited and can not be sent because:</span>';

            _.forEach(newFeedbackBlocks, (value, key) => {
              newFeedbackBlocksHtml += `<span class="u-margin-top-0 u-margin-bottom-0"><br />${messageRulesBlocks[newFeedbackBlocks[key]].string
                }</span>`;
            });

            $("#blocksWrapper").html(newFeedbackBlocksHtml);
          } else {
            $("#blocksWrapper").html("");
          }

          if (isValid && _.size(newFeedbackBlocks) <= 0) {
            $("#declineButton").removeClass("c-btn--is-disabled");
          } else {
            $("#declineButton").addClass("c-btn--is-disabled");
          }
        }
      );
    }
  });
}

function adminModerateMessage(
  theConversationMessage,
  theChoiceACallback,
  theChoiceBCallback,
  theChoiceCCallback
) {
  $(".js-js-modal-loader").remove();
  $(".js-js-modal").remove();
  document.theCallback = null;
  document.choiceACallback = null;
  document.choiceBCallback = null;
  document.choiceCCallback = null;
  document.choiceDeclineCallback = null;
  if (document.closeModalExtra) {
    document.closeModalExtra(false);
  }

  document.choiceACallback = () => {
    theChoiceACallback();
  };
  document.choiceBCallback = () => {
    theChoiceBCallback();
  };
  document.choiceCCallback = () => {
    theChoiceCCallback();
  };

  document.choiceACallback = () => {
    theChoiceACallback();
  };

  var headingCopy =
    `<p class="c-modal__title">` +
    theConversationMessage.user.accountInfo.username +
    `</p>`;

  var messageCopy =
    `<p class="c-modal__copy">"` + theConversationMessage.message + `"</p>`;

  var modalHTML =
    '<div class="c-modal c-modal--visible js-js-modal"><div class="c-modal__bg js-close-modal"></div><div class="c-modal__inner">' +
    headingCopy +
    messageCopy +
    '<button class="c-btn c-btn--small c-btn--green js-accept-modal-choicea">Approve</button><button class="c-btn c-btn--small c-btn--red js-accept-modal-choiceb">Reject</button><button class="c-btn c-btn--small c-btn--grey u-margin-right-0 js-accept-modal-choicec">Edit</button></div></div>';

  $("body").append(modalHTML);
  setTimeout(function () {
    $(".js-js-modal").addClass("c-modal--visible");
  }, 0);
}

function adminEditMessage(theConversationMessage, theChoiceACallback, theMode) {
  $(".js-js-modal-loader").remove();
  $(".js-js-modal").remove();
  document.theCallback = null;
  document.choiceACallback = null;
  document.choiceBCallback = null;
  document.choiceCCallback = null;
  document.choiceDeclineCallback = null;

  document.choiceACallback = () => {
    theChoiceACallback(
      theMode,
      document.getElementById("theformbody")
        ? document.getElementById("theformbody").value
        : false,
      document.getElementById("theformcomment").value
    );
  };

  var editCopy;

  var saveButton;

  if (theMode == "edit") {
    var editCopy =
      `<p class="c-modal__title">` +
      theConversationMessage.user.accountInfo.username +
      `</p>
		<form id="editform" class="c-modal__form" target="_self">
						<textarea id="theformbody" rows="4" name="theformbody">` +
      theConversationMessage.message +
      `</textarea>
						<br/><p class="c-modal__copy">Moderator message (optional)</p>
						<textarea rows="2" id="theformcomment" name="theformcomment">` +
      (theConversationMessage.moderation.moderatorMessage || "") +
      `</textarea>
				</form>`;
  } else if (theMode == "reject") {
    var editCopy =
      `<p class="c-modal__title">Moderator message (optional)</p>
		<form id="editform" class="c-modal__form" target="_self">
		<textarea rows="2" id="theformcomment" name="theformcomment">` +
      (theConversationMessage.moderation.moderatorMessage || "") +
      `</textarea>
				</form>`;
  }

  var modalHTML =
    '<div class="c-modal c-modal--visible js-js-modal"><div class="c-modal__bg js-close-modal"></div><div class="c-modal__inner">' +
    editCopy +
    '<button class="c-btn c-btn--small c-btn--yellow c-btn--red js-accept-modal-choicea">Save</button><button class="c-btn c-btn--small c-btn--grey u-margin-right-0 js-accept-modal-choiceb">Cancel</button></div></div>';

  $("body").append(modalHTML);
  setTimeout(function () {
    $(".js-js-modal").addClass("c-modal--visible");
  }, 0);
}

function showSpinner() {
  $(".js-js-modal-loader").remove();
  $(".js-js-modal").remove();
  document.theCallback = null;
  document.choiceACallback = null;
  document.choiceBCallback = null;
  document.choiceCCallback = null;
  document.choiceDeclineCallback = null;
  if (document.closeModalExtra) {
    document.closeModalExtra(false);
  }

  var modalHTML =
    '<div class="c-modal c-modal--loader c-modal--visible js-js-modal-loader"><div class="c-modal__loader js"></div></div>';

  $("body").append(modalHTML);
  setTimeout(function () {
    $(".js-js-modal").addClass("c-modal--visible");
  }, 0);
}

function closeSpinner(immediateBoo) {
  if (immediateBoo) {
    $(".js-js-modal-loader").remove();
  } else {
    $(".js-js-modal-loader").removeClass("c-modal--visible");
    setTimeout(function () {
      $(".js-js-modal-loader").remove();
    }, 300);
  }
}

function hideNav(event) {
  $("body").addClass("l-no-bottom-nav");
}

function showNav(event) {
  $("body").removeClass("l-no-bottom-nav");
}

function alert(theMessage) {
  window.alert(theMessage);
}

export {
  hideNav,
  showNav,
  adminModerateMessage,
  adminEditMessage,
  showSpinner,
  closeSpinner,
  alert,
  closeModal,
  countMyModals,
  modal,
  updateApp,
  modalwithtip,
  modalwithnote,
  gallery,
  switchGalleryImage,
  confirm,
  declinefeedback,
  select,
  selectAlt,
};
