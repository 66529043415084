import { serverCall } from '../modules/ServerComms';

const errObjects = [
	{ errCode : "novalidjobspecitemtype", errString : "ERROR: NO VALID JOB SPEC ITEM TYPE"},
	{ errCode : "novalidjobspecitemweight", errString : "ERROR: NO VALID JOB SPEC ITEM WEIGHT"},
	{ errCode : "novalidjobspecitemweightextra", errString : "ERROR: NO VALID JOB SPEC ITEM WEIGHT EXTRA"},
	{ errCode : "novalidjobspectime", errString : "ERROR: NO VALID JOB SPEC TIME"},
	{ errCode : "novalidjobstatus", errString : "ERROR: NO VALID JOB STATUS"},
	{ errCode : "novalidname", errString : "ERROR: NO VALID NAME"},
	{ errCode : "novalidpassword", errString : "ERROR: NO VALID PASSWORD"},
	{ errCode : "novalidpublicliabilityinsurance", errString : "ERROR: NO VALID PUBLIC LIABILITY INSURANCE"},
	{ errCode : "novalidpushnotificaions", errString : "ERROR: NO VALID PUSH NOTIFICATIONS SETTING"},
	{ errCode : "novalidquoteid", errString : "ERROR: NO VALID QUOTE ID"},
	{ errCode : "novalidquotespecnumbermen", errString : "ERROR: NO VALID QUOTE SPEC NUMBER OF MEN"},
	{ errCode : "novalidquotespecnumbervans", errString : "ERROR: NO VALID QUOTE SPEC NUMBER OF VANS"},
	{ errCode : "novalidquotespecprice", errString : "ERROR: INVALID QUOTE SPEC PRICE"},
	{ errCode : "novalidquotestatus", errString : "ERROR: NO VALID QUOTE STATUS"},
	{ errCode : "novalidtelephone", errString : "ERROR: NO VALID TELEPHONE"},
	{ errCode : "novalidusername", errString : "ERROR: NO VALID USERNAME"},
	{ errCode : "novalidvatnumber", errString : "ERROR: NO VALID VAT NUMBER"},
	{ errCode : "nvaliddestinationaddress", errString : "ERROR: INVALID DESTINATION ADDRESS"},
	{ errCode : "nvalidoriginaddress", errString : "ERROR: INVALID ORIGIN ADDRESS"},
	{ errCode : "outdatedappversion", errString : "ERROR: OUTDATED VERSION"},
	{ errCode : "quotenotsaved", errString : "ERROR: QUOTE NOT SAVED"},
	{ errCode : "stripeaccountemailexists", errString : "ERROR: A STRIPE ACCOUNT EXISTS AT THIS EMAIL ADD"},
	{ errCode : "termsnotagreed", errString : "ERROR: TERMS NOT AGREED"},
	{ errCode : "unauthorised", errString : "ERROR: USER NOT AUTHORISED"},
	{ errCode : "userblocked", errString : "ERROR: USER BLOCKED"},
	{ errCode : "useremailexists", errString : "ERROR: USER EMAIL EXISTS"},
	{ errCode : "usernotactive", errString : "ERROR: USER NOT ACTIVE"},
	{ errCode : "usernotsaved", errString : "ERROR: USER NOT SAVED"},
	{ errCode : "userunderage", errString : "ERROR: USER UNDERAGE"},
	{ errCode : "userusernameexists", errString : "ERROR: USER NAME EXISTS"}
];

function getResponseString(pResStatus, pErrorString) {

	let objError = errObjects.find(errObject => errObject.errCode === pResStatus);	
	if (objError) {
		return objError.errString;
	}
	else {
		return pErrorString;
	}	
}

function getHome(theUser, theCallback, theJWTToken) {

	serverCall("POST", '/api/admin/getdashboard', theUser, function (resStatus, resJson) {

		let responseString;
		if (resStatus == "200" || resStatus == 200) {

			if (resJson.successString == "adminhomefound") {
				responseString = "SUCCESS: ADMIN HOME FOUND FOUND";
			}

			return theCallback(resStatus, responseString, resJson);
		} else {

			let responseString = getResponseString(resStatus, resJson.errorString)
			return theCallback(resStatus, responseString, false);
		}


	}, theJWTToken);

}

function getUsers(theUser, theCallback, theJWTToken) {

	serverCall("POST", '/api/getusers', theUser, function (resStatus, resJson) {

		let responseString;	
		if (resStatus == "200" || resStatus == 200) {

			if (resJson.successString == "usersfound") {
				responseString = "SUCCESS: USERS FOUND";
			}
			return theCallback(resStatus, responseString, resJson);
		} else {

			let responseString = getResponseString(resStatus, resJson.errorString)
			return theCallback(resStatus, responseString, false);
		}

	}, theJWTToken);

}

function setUser(theUser, theCallback, theJWTToken) {

	serverCall("POST", '/api/setuser', theUser, function (resStatus, resJson) {

		let responseString;
		if (resStatus == "200" || resStatus == 200) {

			if (resJson.successString == "userupdated") {
				responseString = "SUCCESS: USER UPDATED";
			}
			return theCallback(resStatus, responseString, resJson);

		} else {
			let responseString = getResponseString(resStatus, resJson.errorString)
			return theCallback(resStatus, responseString, false);
		}


	}, theJWTToken);

}

function deleteAvatars(theUser, theCallback, theJWTToken) {

	serverCall("POST", '/api/deleteavatars', theUser, function (resStatus, resJson) {

		let responseString;
		if (resStatus == "200" || resStatus == 200) {

			if (resJson.successString == "avatarsdeleted") {
				responseString = "SUCCESS: AVATARS DELETED";
			}
			return theCallback(resStatus, responseString, resJson);
		} else {

			let responseString = getResponseString(resStatus, resJson.errorString)
			return theCallback(resStatus, responseString, false);
		}

	}, theJWTToken);

}

function setJob(theJob, theCallback, theJWTToken) {


	serverCall("POST", '/api/setjob', theJob, function (resStatus, resJson) {

		let responseString;

		if (resStatus == "200" || resStatus == 200) {

			if (resJson.successString == "jobupdated") {
				responseString = "SUCCESS: JOB UPDATED";
			}
			return theCallback(resStatus, responseString, resJson);
		} else {

			let responseString = getResponseString(resStatus, resJson.errorString)
			return theCallback(resStatus, responseString, false);
		}

	}, theJWTToken);

}

function setQuote(theQuote, theCallback, theJWTToken) {


	serverCall("POST", '/api/setquote', theQuote, function (resStatus, resJson) {

		let responseString;

		if (resStatus == "200" || resStatus == 200) {

			if (resJson.successString == "quoteupdated") {
				responseString = "SUCCESS: QUOTE UPDATED";
			}

			return theCallback(resStatus, responseString, resJson);
		} else {

			let responseString = getResponseString(resStatus, resJson.errorString)
			return theCallback(resStatus, responseString, false);
		}

	}, theJWTToken);

}

function forcePaymentCompleted(theData, theCallback, theJWTToken) {

	serverCall("POST", '/api/payments/paymentcompletedforce', theData, function (resStatus, resJson) {

		let responseString;

		if (resStatus == "200" || resStatus == 200) {

			if (resJson.successString == "quoteupdated") {
				responseString = "SUCCESS: QUOTE UPDATED";
			}

			return theCallback(resStatus, responseString, resJson);
		} else {

			let responseString = getResponseString(resStatus, resJson.errorString)
			return theCallback(resStatus, responseString, false);
		}

	}, theJWTToken);

}

export {
	getHome,
	getUsers,
	setUser,
	deleteAvatars,
	setJob,
	setQuote,
	forcePaymentCompleted
}