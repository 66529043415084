import React from "react";
import _ from "lodash";
import $ from "jquery";
import { Link, NavLink } from "react-router-dom";

import TopNav from "./TopNav";
import { deleteAvatars, getUsers } from "../modules/Admin";
import {
  showSpinner,
  closeSpinner,
  modal,
  alert,
  confirm,
} from "../modules/Dialogs";
import { updateState } from "../modules/Generic";
import {
  sessionExpired,
  serverUrl,
  userImagesFolder,
  thumbSuffix,
  defaultAvatarImage,
  imageFormat,
} from "../config/GeneralConfig";
import { toScreen } from "../modules/Routing";
import PropTypes from "prop-types";
import withNavigate from "./withNavigate";

class Mavs extends React.Component {
  constructor(props) {
    super(props);
    this.updateState = updateState.bind(this);
    this.getMavs = this.getMavs.bind(this);
    this.getMavs_done = this.getMavs_done.bind(this);
    this.renderMavs = this.renderMavs.bind(this);
    this.searchMav = this.searchMav.bind(this);
    this.sortMavs = this.sortMavs.bind(this);
    this.filterMav = this.filterMav.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.nextPage = this.nextPage.bind(this);
    this.prevPage = this.prevPage.bind(this);
    this.deleteAvatarTdeleteAvatarsoggle = this.deleteAvatarToggle.bind(this);
    this.deleteAvatars = this.deleteAvatars.bind(this);

    this.state = {
      search: "",
      filter: window.history.state?.usr?.selectedFilter ?? "active",
      sort: '{"_id":1}',
      mode: "list",
      deleteAvatars: [],
      lastTheUser: {},
      mavs: {},
      totals: {
        total: 0,
        preactivation: 0,
        active: 0,
        resetting: 0,
        blocked: 0,
      },
      pagination: {
        page: window.history.state?.usr?.selectedPage ?? 1,
        total: 1,
      },
    };
  }

  nextPage(event) {
    if (event) {
      event.preventDefault();
    }

    const inputChangeCallback = () => {
      this.getMavs();
    };

    if (this.state.pagination.page < this.state.pagination.total) {
      this.setState(
        {
          ...this.state,
          pagination: {
            ...this.state.pagination,
            page: this.state.pagination.page + 1,
          },
        },
        inputChangeCallback
      );
    }
  }

  prevPage(event) {
    if (event) {
      event.preventDefault();
    }

    const inputChangeCallback = () => {
      this.getMavs();
    };

    if (this.state.pagination.page > 1) {
      this.setState(
        {
          ...this.state,
          pagination: {
            ...this.state.pagination,
            page: this.state.pagination.page - 1,
          },
        },
        inputChangeCallback
      );
    }
  }

  searchMav(event) {
    if (event) {
      event.preventDefault();
    }

    const inputChangeCallback = () => {
      this.getMavs();
    };

    this.setState(
      {
        ...this.state,
        filter: "all",
        pagination: {
          page: 1,
          total: 1,
        },
      },
      inputChangeCallback
    );
  }

  sortMavs(event, theSort) {
    if (event) {
      event.preventDefault();
    }

    var tmpSort;

    if (theSort == "name") {
      if (this.state.sort === '{"accountInfo.username":1}') {
        tmpSort = '{"accountInfo.username":-1}';
      } else {
        tmpSort = '{"accountInfo.username":1}';
      }
    } else if (theSort == "_id") {
      if (this.state.sort === '{"_id":1}') {
        tmpSort = '{"_id":-1}';
      } else {
        tmpSort = '{"_id":1}';
      }
    } else if (theSort == "tel") {
      if (this.state.sort === '{"accountInfo.telephone":1}') {
        tmpSort = '{"accountInfo.telephone":-1}';
      } else {
        tmpSort = '{"accountInfo.telephone":1}';
      }
    } else if (theSort == "location") {
      if (
        this.state.sort ===
        '{"accountInfo.baseLocation.homeaddress.formattedAddress":1}'
      ) {
        tmpSort =
          '{"accountInfo.baseLocation.homeaddress.formattedAddress":-1}';
      } else {
        tmpSort = '{"accountInfo.baseLocation.homeaddress.formattedAddress":1}';
      }
    } else if (theSort == "quotetotal") {
      if (this.state.sort === '{"quotetotal":1}') {
        tmpSort = '{"quotetotal":-1}';
      } else {
        tmpSort = '{"quotetotal":1}';
      }
    } else if (theSort == "successfulquotes") {
      if (this.state.sort === '{"successfulquotes":1}') {
        tmpSort = '{"successfulquotes":-1}';
      } else {
        tmpSort = '{"successfulquotes":1}';
      }
    } else if (theSort == "reviews") {
      if (this.state.sort === '{"totalRatings":1}') {
        tmpSort = '{"totalRatings":-1}';
      } else {
        tmpSort = '{"totalRatings":1}';
      }
    }

    const inputChangeCallback = () => {
      this.getMavs();
    };

    this.setState(
      {
        ...this.state,
        search: "",
        sort: tmpSort,
        pagination: {
          page: 1,
          total: 1,
        },
      },
      inputChangeCallback
    );
  }

  filterMav(event) {
    if (event) {
      event.preventDefault();
    }

    const inputChangeCallback = () => {
      this.getMavs();
    };

    this.setState(
      {
        ...this.state,
        search: "",
        pagination: {
          page: 1,
          total: 1,
        },
      },
      inputChangeCallback
    );
  }

  getMavs(event, lastGet) {
    showSpinner();

    if (event) {
      event.preventDefault();
    }

    let theUser;

    if (lastGet) {
      theUser = this.state.lastTheUser;
    } else {
      theUser = {
        accountInfo: {
          username: this.props.userDetails.accountInfo.username,
          email: this.props.userDetails.accountInfo.email,
        },
        userType: "mav",
      };

      if (this.state.search && this.state.search != "") {
        theUser.search = this.state.search;
      }

      if (
        this.state.filter &&
        this.state.filter != "" &&
        this.state.filter != "all"
      ) {
        theUser.filter = this.state.filter;
      }

      if (this.state.sort && this.state.sort != "") {
        theUser.sort = this.state.sort;
      }

      if (this.state.pagination.page && this.state.pagination.page != "") {
        theUser.page = this.state.pagination.page;
      }

      if (this.state.totals && this.state.totals != "") {
        theUser.totals = {};
      }
    }

    this.updateState(false, { lastTheUser: theUser }, () => {
      getUsers(theUser, this.getMavs_done, this.props.userDetails.jwttoken);
    });
  }

  getMavs_done(resStatus, responseString, resJson) {
    closeSpinner();

    if (resStatus == 401) {
      modal(sessionExpired);
      toScreen(false, this.props.navigate, "/logout");
    } else if (resStatus == "400") {
      this.setState({
        ...this.state,
        mavs: {},
        pagination: {
          page: 1,
          total: 1,
        },
      });

      alert(responseString);
    } else if (resStatus == 200) {
      var statusCount = _.groupBy(resJson.theUsers, "status");

      this.setState({
        ...this.state,
        //search:'',
        pagination: {
          ...this.state.pagination,
          total: resJson.pagination.total,
        },
        deleteAvatars: [],
        mavs: resJson.theUsers,
        totals: resJson.totals,
      });
    }
  }

  renderMavs(key) {
    const mav = this.state.mavs[key];

    let theQuoteCount = _.countBy(mav.quotes, "status");

    if (this.state.mode == "list") {
      return (
        <div className="c-list-entry" key={key}>
          <div className="c-list-entry__id">
            <b>{mav.crDate}</b>
          </div>
          <div className="c-list-entry__mavname">
            <b>{mav.accountInfo.username}</b>
          </div>
          <div className="c-list-entry__tel">{mav.accountInfo.telephone}</div>
          <div className="c-list-entry__mavlocation">
            {mav.accountInfo.baseLocation &&
            mav.accountInfo.baseLocation.homeaddress &&
            mav.accountInfo.baseLocation.homeaddress.formattedAddress
              ? mav.accountInfo.baseLocation.homeaddress.formattedAddress.split(
                  ", United Kingdom"
                )[0]
              : "(Not set)"}
          </div>
          <div className="c-list-entry__mavquotes">
            <b>{_.size(mav.quotes)}</b>
          </div>
          <div className="c-list-entry__mavjobs">
            <b>
              {theQuoteCount.complete ||
                0 + theQuoteCount.paid ||
                0 + theQuoteCount.agreed ||
                0}
            </b>
          </div>
          <div className="c-list-entry__reviews">
            <b>{mav.totalRatings}</b>
          </div>

          <button
            type="button"
            className="c-btn c-btn--teal c-btn--small"
            onClick={(e) =>
              toScreen(e, this.props.navigate, "/mav/" + mav.short_id)
            }
          >
            view
          </button>
          <button
            type="button"
            className="c-btn c-btn--green c-btn--small"
            onClick={(e) =>
              toScreen(
                e,
                this.props.navigate,
                "/message/" + mav.short_id,
                false,
                {
                  receiverId: mav?._id,
                  receiverShortId: mav?.short_id,
                  receiverType: mav?.userType,
                  userShortId1: "admin",
                  userShortId2: mav?.short_id,
                  username2: mav?.accountInfo?.username,
                  selectedPage: this.state.pagination.page,
                  selectedFilter: this.state.filter,
                  previousPath: window.location.pathname,
                }
              )
            }
          >
            Contact MAV
          </button>
        </div>
      );
    } else if (this.state.mode == "avatars") {
      return (
        <div
          className={
            _.includes(this.state.deleteAvatars, mav.short_id)
              ? "c-avatar-grid__avatar c-avatar-grid__avatar--selected"
              : "c-avatar-grid__avatar"
          }
          key={key}
          onClick={(e) => {
            this.deleteAvatarToggle(e, mav.short_id);
          }}
        >
          <img
            src={
              mav.accountInfo.avatar && mav.accountInfo.avatar != ""
                ? serverUrl +
                  userImagesFolder +
                  "/" +
                  mav.short_id +
                  "/" +
                  mav.accountInfo.avatar +
                  thumbSuffix +
                  imageFormat
                : "../img/" + defaultAvatarImage + imageFormat
            }
            alt="avatar"
            className="c-avatar__pic"
            onError={(e) => {
              e.target.src = defaultAvatarImage;
            }}
          />
          <div className="c-avatar-grid__icon u-before-icon-cancel"></div>
        </div>
      );
    }
  }

  deleteAvatarToggle(event, theShortId) {
    if (event) {
      event.preventDefault();
    }

    let updatedDeleteAvatars = [...this.state.deleteAvatars];

    if (_.includes(this.state.deleteAvatars, theShortId)) {
      updatedDeleteAvatars = _.remove(updatedDeleteAvatars, function (n) {
        return n !== theShortId;
      });
    } else {
      updatedDeleteAvatars.push(theShortId);
    }

    this.updateState(false, { deleteAvatars: updatedDeleteAvatars }, () => {});
  }

  deleteAvatars(event, theShortId) {
    if (event) {
      event.preventDefault();
    }

    confirm(
      false,
      "Delete " + _.size(this.state.deleteAvatars) + " MAV avatars?",
      "Cancel",
      "Delete",
      () => {
        showSpinner();

        const theUser = {
          accountInfo: {
            username: this.props.userDetails.accountInfo.username,
            email: this.props.userDetails.accountInfo.email,
          },
          userType: "mav",
          deleteAvatars: this.state.deleteAvatars,
        };

        deleteAvatars(
          theUser,
          (resStatus, responseString, resJson) => {
            closeSpinner();

            if (resStatus == 401) {
              modal(sessionExpired);
              toScreen(false, this.props.navigate, "/logout");
            } else if (resStatus == "400") {
              alert(responseString);
            } else if (resStatus == 200) {
              this.updateState(false, { deleteAvatars: [] }, () => {
                this.getMavs(false, true);
              });
            }
          },
          this.props.userDetails.jwttoken
        );
      }
    );
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    var oldValue = false;

    const inputChangeCallback = () => {
      if (name === "filter" && oldValue != value) {
        this.filterMav();
      }
    };

    if (name.split(".").length == 1) {
      oldValue = this.state[name];
      this.setState(
        {
          [name]: value,
        },
        inputChangeCallback
      );
    } else if (name.split(".").length == 2) {
      oldValue = this.state[name.split(".")[0]][name.split(".")[1]];
      this.setState(
        {
          [name.split(".")[0]]: {
            ...this.state[name.split(".")[0]],
            [name.split(".")[1]]: value,
          },
        },
        inputChangeCallback
      );
    } else if (name.split(".").length == 3) {
      oldValue =
        this.state[name.split(".")[0]][name.split(".")[1]][name.split(".")[2]];
      this.setState(
        {
          [name.split(".")[0]]: {
            ...this.state[name.split(".")[0]],
            [name.split(".")[1]]: {
              ...this.state[name.split(".")[0]][name.split(".")[1]],
              [name.split(".")[2]]: value,
            },
          },
        },
        inputChangeCallback
      );
    }
  }

  componentWillMount() {
    //
  }

  componentDidMount() {
    this.getMavs();
  }

  componentWillUnmount() {
    //
  }

  render() {
    return (
      <div className="l-base-layout__content">
        <h1>Admin / MAVs</h1>

        <div className="c-hr"></div>

        <div className="c-subheader-info-panel">
          <div className="c-subheader-info-panel__primary">
            Total: {this.state.totals.total}
          </div>
          <div className="c-subheader-info-panel__search">
            <form onSubmit={(e) => this.searchMav(e)}>
              <input
                type="text"
                value={this.state.search}
                onChange={this.handleInputChange}
                name="search"
                placeholder=""
                className="c-subheader-info-panel__input"
              />
              <button
                type="submit"
                className="c-btn-outline c-btn-outline--white c-btn-outline--small"
              >
                Search
              </button>
            </form>
          </div>
        </div>

        <div className="c-hr"></div>

        <div className="l-row">
          <div className="l-col-100 u-padding-top-0 u-padding-bottom-0">
            <div className="filter-bar">
              <div className="c-checkbox-radio-block">
                <input
                  type="radio"
                  id="preactivation"
                  name="filter"
                  value="preactivation"
                  onChange={this.handleInputChange}
                  checked={this.state.filter === "preactivation"}
                />
                <label htmlFor="preactivation">
                  Pre Activation ({this.state.totals.preactivation})
                </label>
              </div>
              <div className="c-checkbox-radio-block">
                <input
                  type="radio"
                  id="active"
                  name="filter"
                  value="active"
                  onChange={this.handleInputChange}
                  checked={this.state.filter === "active"}
                />
                <label htmlFor="active">
                  Active ({this.state.totals.active})
                </label>
              </div>
              <div className="c-checkbox-radio-block">
                <input
                  type="radio"
                  id="blocked"
                  name="filter"
                  value="blocked"
                  onChange={this.handleInputChange}
                  checked={this.state.filter === "blocked"}
                />
                <label htmlFor="blocked">
                  Blocked ({this.state.totals.blocked})
                </label>
              </div>

              <div className="c-checkbox-radio-block u-color-grey-mid-light-light-bg">
                <input
                  type="radio"
                  id="list"
                  name="mode"
                  value="list"
                  onChange={this.handleInputChange}
                  checked={this.state.mode === "list"}
                />
                <label htmlFor="list">List</label>
              </div>
              <div className="c-checkbox-radio-block u-color-grey-mid-light-light-bg">
                <input
                  type="radio"
                  id="avatars"
                  name="mode"
                  value="avatars"
                  onChange={this.handleInputChange}
                  checked={this.state.mode === "avatars"}
                />
                <label htmlFor="avatars">Avatars</label>
              </div>
            </div>
          </div>

          <div className="l-col-100">
            <div className="c-pagination">
              <button
                type="button"
                className="c-pagination__prev"
                onClick={(e) => this.prevPage(e)}
              >
                &lt;&lt; Prev
              </button>
              {this.state.pagination.page} / {this.state.pagination.total}
              <button
                type="button"
                className="c-pagination__next"
                onClick={(e) => this.nextPage(e)}
              >
                Next &gt;&gt;
              </button>
            </div>
            <div className="c-hr u-margin-bottom-0"></div>

            <div>
              <div className="c-list-entry c-list-entry__header">
                <div className="c-list-entry__id c-list-filter">
                  <button
                    className={
                      this.state.sort == '{"_id":-1}'
                        ? "c-list-filter__button c-list-filter__button--icon-up"
                        : this.state.sort == '{"_id":1}'
                        ? "c-list-filter__button c-list-filter__button--icon-down"
                        : "c-list-filter__button c-list-filter__button--faded"
                    }
                    onClick={(e) => this.sortMavs(e, "_id")}
                  >
                    Cr. Date
                  </button>
                </div>
                <div className="c-list-entry__mavname c-list-filter">
                  <button
                    className={
                      this.state.sort == '{"accountInfo.username":-1}'
                        ? "c-list-filter__button c-list-filter__button--icon-up"
                        : this.state.sort == '{"accountInfo.username":1}'
                        ? "c-list-filter__button c-list-filter__button--icon-down"
                        : "c-list-filter__button c-list-filter__button--faded"
                    }
                    onClick={(e) => this.sortMavs(e, "name")}
                  >
                    Name
                  </button>
                </div>
                <div className="c-list-entry__tel c-list-filter">
                  <button
                    className={
                      this.state.sort == '{"accountInfo.telephone":-1}'
                        ? "c-list-filter__button c-list-filter__button--icon-up"
                        : this.state.sort == '{"accountInfo.telephone":1}'
                        ? "c-list-filter__button c-list-filter__button--icon-down"
                        : "c-list-filter__button c-list-filter__button--faded"
                    }
                    onClick={(e) => this.sortMavs(e, "tel")}
                  >
                    Tel
                  </button>
                </div>
                <div className="c-list-entry__mavlocation c-list-filter">
                  <button
                    className={
                      this.state.sort ==
                      '{"accountInfo.baseLocation.homeaddress.formattedAddress":-1}'
                        ? "c-list-filter__button c-list-filter__button--icon-up"
                        : this.state.sort ==
                          '{"accountInfo.baseLocation.homeaddress.formattedAddress":1}'
                        ? "c-list-filter__button c-list-filter__button--icon-down"
                        : "c-list-filter__button c-list-filter__button--faded"
                    }
                    onClick={(e) => this.sortMavs(e, "location")}
                  >
                    Location
                  </button>
                </div>
                <div className="c-list-entry__mavquotes c-list-filter">
                  <button
                    className={
                      this.state.sort == '{"quotetotal":-1}'
                        ? "c-list-filter__button c-list-filter__button--icon-up"
                        : this.state.sort == '{"quotetotal":1}'
                        ? "c-list-filter__button c-list-filter__button--icon-down"
                        : "c-list-filter__button c-list-filter__button--faded"
                    }
                    onClick={(e) => this.sortMavs(e, "quotetotal")}
                  >
                    Quotes
                  </button>
                </div>
                <div className="c-list-entry__mavjobs c-list-filter">
                  <button
                    className={
                      this.state.sort == '{"successfulquotes":-1}'
                        ? "c-list-filter__button c-list-filter__button--icon-up"
                        : this.state.sort == '{"successfulquotes":1}'
                        ? "c-list-filter__button c-list-filter__button--icon-down"
                        : "c-list-filter__button c-list-filter__button--faded"
                    }
                    onClick={(e) => this.sortMavs(e, "successfulquotes")}
                  >
                    Jobs
                  </button>
                </div>
                <div className="c-list-entry__reviews c-list-filter">
                  <button
                    className={
                      this.state.sort == '{"totalRatings":-1}'
                        ? "c-list-filter__button c-list-filter__button--icon-up"
                        : this.state.sort == '{"totalRatings":1}'
                        ? "c-list-filter__button c-list-filter__button--icon-down"
                        : "c-list-filter__button c-list-filter__button--faded"
                    }
                    onClick={(e) => this.sortMavs(e, "reviews")}
                  >
                    Reviews
                  </button>
                </div>
              </div>
            </div>

            <div
              className={this.state.mode == "avatars" ? "c-avatar-grid" : null}
            >
              {Object.keys(this.state.mavs).map(this.renderMavs)}
            </div>
            {_.size(this.state.deleteAvatars) > 0 ? (
              <div className="u-text-align-center u-margin-top-bottom-m">
                <button
                  className="c-btn c-btn--small u-margin-bottom-xs  c-btn--grey"
                  onClick={(e) => {
                    e.preventDefault();
                    this.updateState(false, { deleteAvatars: [] }, () => {});
                  }}
                >
                  Deselct
                </button>
                <button
                  className="c-btn c-btn--small u-margin-bottom-xs c-btn--red"
                  onClick={(e) => {
                    this.deleteAvatars(e);
                  }}
                >
                  Delete Avatars
                </button>
              </div>
            ) : null}
            <div className="c-hr u-margin-bottom-0 u-clear-both"></div>
            <div className="c-pagination">
              <button
                type="button"
                className="c-pagination__prev"
                onClick={(e) => this.prevPage(e)}
              >
                &lt;&lt; Prev
              </button>
              {this.state.pagination.page} / {this.state.pagination.total}
              <button
                type="button"
                className="c-pagination__next"
                onClick={(e) => this.nextPage(e)}
              >
                Next &gt;&gt;
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Mavs.contextTypes = {
  router: PropTypes.object,
};

Mavs.propTypes = {
  userDetails: PropTypes.object.isRequired,
  updateState: PropTypes.func.isRequired,
};

export default withNavigate(Mavs);
