import React from 'react';
import _ from 'lodash';
import Header from './Header';
import Home from './Home';
import Clients from './Clients';
import Client from './Client';
import Mavs from './Mavs';
import Mav from './Mav';
import Reviews from './Reviews';
import Jobs from './Jobs';
import Job from './Job';
import Quotes from './Quotes';
import Quote from './Quote';
import Messages from './Messages';
import Message from './Message';
import Navigation from './Navigation';
import Logout from './Logout';
import SignIn from './SignIn';
import NotFound from './NotFound';
import { mavMode, devModeNotice_admin } from '../config/GeneralConfig';

import { modal } from '../modules/Dialogs';
import RptMonthly from './reports/RptMonthly';
import PushNotifications from './PushNotifications';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import PropTypes from 'prop-types';

class Router extends React.Component {

	constructor() {

		super();

		this.updateState = this.updateState.bind(this);
		this.logOut = this.logOut.bind(this);

		this.state = {
			userDetails: {
				jwttoken: null,
				accountInfo: {
					firstname: null,
					surname: null,
					username: null,
					email: null,
					dob: null,
					telephone: null,
					settings: {
						pushnotifications: null
					}
				},
				myJobs: {},
				myQuotes: {},
				messages: {},
				userType: null,
				short_id: null
			},
		};

	}

	updateState(stateLabel, stateObject, theCallback) {
		if (!_.isEqual(stateObject, this.state[stateLabel])) {

			this.setState({ [stateLabel]: stateObject }, theCallback);
		} else {

		}
	}

	logOut(logOutCallbackFunc) {

		const userDetails = {
			jwttoken: null,
			accountInfo: {
				firstname: null,
				surname: null,
				username: null,
				email: null,
				dob: null,
				telephone: null,
				settings: {
					pushnotifications: null
				}
			},
			myJobs: {},
			myQuotes: {},
			messages: {},
			userType: null,
			short_id:null
		};

		// const logOutCallback = () => {
		// 	if (logOutCallbackFunc) {
		// 		logOutCallbackFunc;
		// 	}
		// }

		this.setState({ userDetails });
		//modal('logged out');

	}

	componentWillMount() {

		const localStorageRef = localStorage.getItem('userDetailsAdmin');

		if (localStorageRef) {
			this.setState({
				userDetails: JSON.parse(localStorageRef)
			});
		}

	}

	componentWillUpdate(nextProps, nextState) {

		localStorage.setItem('userDetailsAdmin', JSON.stringify(nextState.userDetails));

	}


	componentDidMount() {
		;

	}

	render({ userDetails } = this.state) {
		let loggedIn = false;
		if (this.state.userDetails.jwttoken != null && this.state.userDetails.accountInfo.username != null) {
			loggedIn = true;
		}
		return (
			<BrowserRouter>
				<main className="l-base-layout">
					<Navigation
						userDetails={userDetails}
						updateState={this.updateState}
						logOut={this.logOut}
						appMode={this.props.appMode}
					/>

					{loggedIn ? (
						<Routes>
							<Route path="/" element={<Home userDetails={userDetails} updateState={this.updateState} appMode={this.props.appMode} />} />
							<Route path="/signin" element={<SignIn userDetails={userDetails} updateState={this.updateState} />} />
							<Route path="/clients" element={<Clients userDetails={userDetails} updateState={this.updateState} />} />
							<Route path="/client/:clientId" element={<Client userDetails={userDetails} updateState={this.updateState} />} />
							<Route path="/mavs" element={<Mavs userDetails={userDetails} updateState={this.updateState} />} />
							<Route path="/mav/:mavId/reviews" element={<Reviews userDetails={userDetails} updateState={this.updateState} />} />
							<Route path="/mav/:mavId" element={<Mav userDetails={userDetails} updateState={this.updateState} />} />
							<Route path="/jobs" element={<Jobs userDetails={userDetails} updateState={this.updateState} />} />
							<Route path="/job/:jobId" element={<Job userDetails={userDetails} updateState={this.updateState} />} />
							<Route path="/quotes" element={<Quotes userDetails={userDetails} updateState={this.updateState} />} />
							<Route path="/quote/:quoteId" element={<Quote userDetails={userDetails} updateState={this.updateState} />} />
							<Route path="/messages" element={<Messages userDetails={userDetails} updateState={this.updateState} />} />
							<Route path="/message/:userId/:secondUserId" element={<Message userDetails={userDetails} updateState={this.updateState} />} />
							<Route path="/message/:userId" element={<Message userDetails={userDetails} updateState={this.updateState} />} />
							<Route path="/reports/monthly" element={<RptMonthly userDetails={userDetails} updateState={this.updateState} />} />
							<Route path="/pushNotifications" element={<PushNotifications userDetails={userDetails} updateState={this.updateState} />} />
							<Route path="/logout" element={<Logout userDetails={userDetails} updateState={this.updateState} logOut={this.logOut} />} />
							<Route path="*" element={<NotFound />} />
						</Routes>
					) : (
						<Routes>
							<Route path="/signin" element={<SignIn userDetails={userDetails} updateState={this.updateState} />} />
							<Route path="*" element={<SignIn userDetails={userDetails} updateState={this.updateState} />} />
							<Route path="*" element={<NotFound />} />
						</Routes>
					)}


					{mavMode == 'dev' ? <div className="dev-mode-notice-admin" onClick={(e) => { e.preventDefault(); modal(devModeNotice_admin, 'This app is in development mode'); }}><div></div><span className="u-before-icon-exclamation">&nbsp;&nbsp;DEVELOPMENT MODE</span>&nbsp;&nbsp;(<span className="u-text-decoration-underline">INFO</span>)</div> : null}
				</main>


			</BrowserRouter>
		)
	}

}

Router.propTypes = {
	appMode: PropTypes.string.isRequired
}


export default Router;