import React from 'react';
import $ from 'jquery';
import PropTypes from 'prop-types';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import cogIcon from '../../icons/cog.svg';
import manIcon from '../../icons/man.svg';
import vanIcon from '../../icons/van.svg';
import calanderIcon from '../../icons/calendar.svg';
import listIcon from '../../icons/list.svg';
import envelopIcon from '../../icons/envelope.svg';
import smartPhoneIcon from '../../icons/smartphone.svg';
import lockIcon from '../../icons/lock.svg';

// class Navigation extends React.Component {

// 	constructor() {

// 		super();
// 		this.goBack = this.goBack.bind(this);
// 		this.logOut = this.logOut.bind(this);

// 	}

// 	goBack() {
// 		this.context.router.history.goBack();
// 	}
// 	logOut() {
// 		this.props.logOut();
// 		this.context.router.history.push('/');
// 	}

// 	render() {
// 		return (
// 			<div className="l-base-layout__menu">

// 				{this.props.userDetails.jwttoken != null && this.props.userDetails.accountInfo.username != null ?
// <div className="c-navigation">
// 	<NavLink className="c-navigation__item" activeClassName="is-active" exact to='/'><img src={cogIcon} alt="" />Home</NavLink>
// 	<NavLink className="c-navigation__item" activeClassName="is-active" to='/clients'><img src={manIcon} alt="" />Clients</NavLink>
// 	<NavLink className="c-navigation__item" activeClassName="is-active" to='/mavs'><img src={vanIcon} alt="" />MAVs</NavLink>
// 	<NavLink className="c-navigation__item" activeClassName="is-active" to='/jobs'><img src={calanderIcon} alt="" />Jobs</NavLink>
// 	<NavLink className="c-navigation__item" activeClassName="is-active" to='/quotes'><img src={listIcon} alt="" />Quotes</NavLink>
// 	<NavLink className="c-navigation__item" activeClassName="is-active" to='/messages'>
// 		<img src={envelopIcon} alt="" />
// 		Messages
// 	</NavLink>
// 	<NavLink className="c-navigation__item" activeClassName="is-active" to='/reports/monthly'>
// 		<img src={listIcon} alt="" />
// 		Monthly Report
// 	</NavLink>
// 	<NavLink className="c-navigation__item" activeClassName="is-active" to='/pushNotifications'>
// 		<img src={smartPhoneIcon} alt="" />
// 		Push
// 	</NavLink>

// 	<NavLink className="c-navigation__item" activeClassName="is-active" to='/logout'><img src={lockIcon} alt="" />Log Out</NavLink>
// </div>
// 					:
// 					<div className="c-navigation">
// 						<NavLink className="c-navigation__item" to='/signin'><img src={lockIcon} alt="" />Sign In</NavLink>
// 					</div>
// 				}

// 			</div>
// 		)
// 	}

// }

// Navigation.contextTypes = {
// 	router: PropTypes.object
// }

// Navigation.propTypes = {
// 	logOut: PropTypes.func.isRequired
// }

function Navigation({ userDetails, logOut }) {
	const navigate = useNavigate();

	const handleLogOut = () => {
		logOut();
		navigate('/');
	};

	return (
		<div className="l-base-layout__menu">
			{userDetails.jwttoken != null && userDetails.accountInfo.username != null ? (
				<div className="c-navigation">
					<NavLink className="c-navigation__item" activeClassName="is-active" exact to='/'><img src={cogIcon} alt="" />Home</NavLink>
					<NavLink className="c-navigation__item" activeClassName="is-active" to='/clients'><img src={manIcon} alt="" />Clients</NavLink>
					<NavLink className="c-navigation__item" activeClassName="is-active" to='/mavs'><img src={vanIcon} alt="" />MAVs</NavLink>
					<NavLink className="c-navigation__item" activeClassName="is-active" to='/jobs'><img src={calanderIcon} alt="" />Jobs</NavLink>
					<NavLink className="c-navigation__item" activeClassName="is-active" to='/quotes'><img src={listIcon} alt="" />Quotes</NavLink>
					<NavLink className="c-navigation__item" activeClassName="is-active" to='/messages'>
						<img src={envelopIcon} alt="" />
						Messages
					</NavLink>
					<NavLink className="c-navigation__item" activeClassName="is-active" to='/reports/monthly'>
						<img src={listIcon} alt="" />
						Monthly Report
					</NavLink>
					{/* <NavLink className="c-navigation__item" activeClassName="is-active" to='/pushNotifications'>
						<img src={smartPhoneIcon} alt="" />
						Push
					</NavLink> */}

					<NavLink className="c-navigation__item" activeClassName="is-active" to='/logout'><img src={lockIcon} alt="" />Log Out</NavLink>
				</div>
			) : (
				<div className="c-navigation">
						<NavLink className="c-navigation__item" to='/signin'>
							<img src={lockIcon} alt="" />
							Sign In
						</NavLink>
					</div>
			)}
		</div>
	);
}

export default Navigation;