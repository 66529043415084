

import { serverCall } from '../modules/ServerComms';
import Dialogs from '../modules/Dialogs';

function findAddress(theAddress, theCallback) {

	serverCall("POST", '/api/job/findaddress', theAddress, function (resStatus, resJson) {

		var responseString;


		if (resStatus == "400" || resStatus == 400) {

			if (resJson.errorString == "noaddressfound") {
				responseString = "ERROR: NO ADDRESS FOUND";
			} else {
				responseString = "ERROR: UNKNOWN";
			}

			return theCallback(resStatus, responseString, resJson);

		} else if (resStatus == "401" || resStatus == 401) {

			if (resJson.errorString == "unauthorised") {
				responseString = "ERROR: USER NOT AUTHORISED";
			} else if (resJson.errorString == "userblocked") {
				responseString = "ERROR: USER BLOCKED";
			} else if (resJson.errorString == "outdatedappversion") {
				responseString = "ERROR: OUTDATED VERSION";
			} else {
				responseString = "ERROR: UNKNOWN";
			}

			return theCallback(resStatus, responseString, resJson);

		} else if (resStatus == "504" || resStatus == 504 || resStatus == "502" || resStatus == 502) {

			responseString = "ERROR: TIMEOUT";
			return theCallback(resStatus, responseString, false);

		} else if (resStatus == "200" || resStatus == 200) {

			if (resJson.successString == "addressfound") {
				responseString = "SUCCESS: ADDRESS FOUND";
			}

			return theCallback(resStatus, responseString, resJson);
		}



	}, null);

}

function addressAutoComplete(theAddress, theCallback) {

	serverCall("POST", '/api/job/addressautocomplete', theAddress, function (resStatus, resJson) {

		var responseString;


		if (resStatus == "400" || resStatus == 400) {

			if (resJson.errorString == "noaddressfound") {
				responseString = "ERROR: NO ADDRESS FOUND";
			} else {
				responseString = "ERROR: UNKNOWN";
			}

			return theCallback(resStatus, responseString, resJson);

		} else if (resStatus == "401" || resStatus == 401) {

			if (resJson.errorString == "unauthorised") {
				responseString = "ERROR: USER NOT AUTHORISED";
			} else if (resJson.errorString == "userblocked") {
				responseString = "ERROR: USER BLOCKED";
			} else if (resJson.errorString == "outdatedappversion") {
				responseString = "ERROR: OUTDATED VERSION";
			} else {
				responseString = "ERROR: UNKNOWN";
			}

			return theCallback(resStatus, responseString, resJson);

		} else if (resStatus == "504" || resStatus == 504 || resStatus == "502" || resStatus == 502) {

			responseString = "ERROR: TIMEOUT";
			return theCallback(resStatus, responseString, false);

		} else if (resStatus == "200" || resStatus == 200) {

			if (resJson.successString == "addressfound") {
				responseString = "SUCCESS: ADDRESS FOUND";
			}

			return theCallback(resStatus, responseString, resJson);
		}



	}, null);

}

function myCoordinates(appMode, theLocation, theCallback) {

	if (appMode == "mobile") {

		navigator.geolocation.getCurrentPosition(function (position) {
			if (position && position.coords && position.coords.latitude && position.coords.longitude) {

				theLocation.theLatitude = position.coords.latitude;
				theLocation.theLongitude = position.coords.longitude;
				return theCallback(theLocation);
			} else {
				theLocation.responseString = "ERROR: COULDN'T GET GEOLOCATION";
				return theCallback(theLocation);
			}
		},
			function (error) {
				if (error.code && error.code == 1) {
					theLocation.responseString = "ERROR: PLEASE ENABLE GEOLOCATION PERMISSION";
				} else {
					theLocation.responseString = "ERROR: COULDN'T GET GEOLOCATION";
				}
				return theCallback(theLocation);
			},
			{ maximumAge: 3000, timeout: 15000, enableHighAccuracy: true }
		);

	} else if (appMode == "web") {

		navigator.geolocation.getCurrentPosition(function (position) {
			theLocation.theLatitude = position.coords.latitude;
			theLocation.theLongitude = position.coords.longitude;
			// theLocation.theLatitude = 51.5074;
			// theLocation.theLongitude = 0.1278;
			return theCallback(theLocation);
		}, function (err) {
			theLocation.responseString = "ERROR: COULDN'T GET GEOLOCATION";
			return theCallback(theLocation);
		});

	} else {

		theLocation.responseString = "ERROR: COULDN'T GET GEOLOCATION";
		return theCallback(theLocation);

	}

}

function myLocation(theLocation, theCallback) {

	serverCall("POST", '/api/job/mylocation', theLocation, function (resStatus, resJson) {

		var responseString;


		if (resStatus == "400" || resStatus == 400) {

			if (resJson.errorString == "nolocationfound") {
				responseString = "ERROR: NO LOCATION FOUND";
			} else if (resJson.errorString == "usernotinuk") {
				responseString = "ERROR: USER NOT IN UK";
			} else {
				responseString = "ERROR: UNKNOWN";
			}

			return theCallback(resStatus, responseString, resJson);

		} else if (resStatus == "401" || resStatus == 401) {

			if (resJson.errorString == "unauthorised") {
				responseString = "ERROR: USER NOT AUTHORISED";
			} else if (resJson.errorString == "userblocked") {
				responseString = "ERROR: USER BLOCKED";
			} else if (resJson.errorString == "outdatedappversion") {
				responseString = "ERROR: OUTDATED VERSION";
			} else {
				responseString = "ERROR: UNKNOWN";
			}

			return theCallback(resStatus, responseString, resJson);

		} else if (resStatus == "504" || resStatus == 504 || resStatus == "502" || resStatus == 502) {

			responseString = "ERROR: TIMEOUT";
			return theCallback(resStatus, responseString, false);

		} else if (resStatus == "200" || resStatus == 200) {

			if (resJson.successString == "locationfound") {
				responseString = "SUCCESS: LOCATION FOUND";
			}

			return theCallback(resStatus, responseString, resJson);
		}



	}, null);

}

function findDistance(findDistance, theCallback) {

	serverCall("POST", '/api/job/finddistance', findDistance, function (resStatus, resJson) {

		var responseString;


		if (resStatus == "400" || resStatus == 400) {

			if (resJson.errorString == "nodistancefound") {
				responseString = "ERROR: NO DISTANCE FOUND";
			} else {
				responseString = "ERROR: UNKNOWN";
			}

			return theCallback(resStatus, responseString, resJson);

		} else if (resStatus == "401" || resStatus == 401) {

			if (resJson.errorString == "unauthorised") {
				responseString = "ERROR: USER NOT AUTHORISED";
			} else if (resJson.errorString == "userblocked") {
				responseString = "ERROR: USER BLOCKED";
			} else if (resJson.errorString == "outdatedappversion") {
				responseString = "ERROR: OUTDATED VERSION";
			} else {
				responseString = "ERROR: UNKNOWN";
			}

			return theCallback(resStatus, responseString, resJson);

		} else if (resStatus == "504" || resStatus == 504 || resStatus == "502" || resStatus == 502) {

			responseString = "ERROR: TIMEOUT";
			return theCallback(resStatus, responseString, false);

		} else if (resStatus == "200" || resStatus == 200) {

			if (resJson.successString == "distancefound") {
				responseString = "SUCCESS: DISTANCE FOUND";
			}

			return theCallback(resStatus, responseString, resJson);
		}



	}, null);

}


export {
	findAddress,
	addressAutoComplete,
	myLocation,
	myCoordinates,
	findDistance
}