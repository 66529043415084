import { serverCall } from '../modules/ServerComms';
import Dialogs from '../modules/Dialogs';

function sendMessage(theMessage, theCallback, theJWTToken) {

	serverCall("POST", '/api/messages/sendmessage', theMessage, function (resStatus, resJson) {

		var responseString;


		if (resStatus == "400" || resStatus == 400) {

			if (resJson.errorString == "nouserdata") {
				responseString = "ERROR: NO USER DATA";
			} else if (resJson.errorString == "nojobconnection") {
				responseString = "ERROR: NO JOB CONNECTION";
			} else if (resJson.errorString == "novalidconversationid") {
				responseString = "ERROR: NO VALID CONVERSATION ID";
			} else if (resJson.errorString == "novaliddisputeid") {
				responseString = "ERROR: NO VALID DISPUTE ID";
			} else if (resJson.errorString == "nodisputedjobfound") {
				responseString = "ERROR: DISPUTE JOB NOT FOUND";
			} else {
				responseString = "ERROR: UNKNOWN";
			}

			return theCallback(resStatus, responseString, resJson);

		} else if (resStatus == "401" || resStatus == 401) {

			if (resJson.errorString == "unauthorised") {
				responseString = "ERROR: USER NOT AUTHORISED";
			} else if (resJson.errorString == "userblocked") {
				responseString = "ERROR: USER BLOCKED";
			} else if (resJson.errorString == "outdatedappversion") {
				responseString = "ERROR: OUTDATED VERSION";
			} else {
				responseString = "ERROR: UNKNOWN";
			}

			return theCallback(resStatus, responseString, resJson);

		} else if (resStatus == "504" || resStatus == 504 || resStatus == "502" || resStatus == 502) {

			responseString = "ERROR: TIMEOUT";
			return theCallback(resStatus, responseString, false);

		} else if (resStatus == "200" || resStatus == 200) {

			if (resJson.successString == "messagesent") {
				responseString = "SUCCESS: MESSAGE SENT";
			}

			return theCallback(resStatus, responseString, resJson);
		}



	}, theJWTToken);

}



function updateMessage(theMessage, theCallback, theJWTToken) {

	serverCall("POST", '/api/messages/updatemessage', theMessage, function (resStatus, resJson) {

		var responseString;


		if (resStatus == "400" || resStatus == 400) {

			if (resJson.errorString == "nomesagedata") {
				responseString = "ERROR: NO MESSAGE DATA";

			} else if (resJson.errorString == "novalidmessage") {
				responseString = "ERROR: MESSAGE NOT VALID";
			} else if (resJson.errorString == "novalidmoideratormessage") {
				responseString = "ERROR: MODERATOR MESSAGE NOT VALID";
			} else if (resJson.errorString == "novalidflags") {
				responseString = "ERROR: FLAGS NOT VALID";
			} else if (resJson.errorString == "novalidmoideratonstatus") {
				responseString = "ERROR: STATUS NOT VALID";
			} else if (resJson.errorString == "messagenotfound") {
				responseString = "ERROR: MESSAGE NOT FOUND";
			} else if (resJson.errorString == "noconversationfound") {
				responseString = "ERROR: CONVERSATION NOT FOUND";
			} else if (resJson.errorString == "conversationnotsaved") {
				responseString = "ERROR: CONVERSATION NOT SAVED";
			} else {
				responseString = "ERROR: UNKNOWN";
			}

			return theCallback(resStatus, responseString, resJson);

		} else if (resStatus == "401" || resStatus == 401) {

			if (resJson.errorString == "unauthorised") {
				responseString = "ERROR: USER NOT AUTHORISED";
			} else {
				responseString = "ERROR: UNKNOWN";
			}

			return theCallback(resStatus, responseString, resJson);

		} else if (resStatus == "504" || resStatus == 504 || resStatus == "502" || resStatus == 502) {

			responseString = "ERROR: TIMEOUT";
			return theCallback(resStatus, responseString, false);

		} else if (resStatus == "200" || resStatus == 200) {

			if (resJson.successString == "messageupdated") {
				responseString = "SUCCESS: MESSAGE UPDATED";
			}

			return theCallback(resStatus, responseString, resJson);
		}



	}, theJWTToken);

}

function getConversation(theConversation, theCallback, theJWTToken) {

	serverCall("POST", '/api/messages/getconversation', theConversation, function (resStatus, resJson) {

		var responseString;// quoteretrieved novalidquoteid noquotefound


		if (resStatus == "400" || resStatus == 400) {

			if (resJson.errorString == "nouserdata") {
				responseString = "ERROR: NO USER DATA";
			} else if (resJson.errorString == "novaliduserid") {
				responseString = "ERROR: NO VALID USER ID";
			} else if (resJson.errorString == "invaliduserid") {
				responseString = "ERROR: INVALID USER ID";
			} else if (resJson.errorString == "invalidparticipants") {
				responseString = "ERROR: INVALID PARTICIPANTS";
			} else if (resJson.errorString == "nouserfound") {
				responseString = "ERROR: NO USER FOUND";
			} else if (resJson.errorString == "usernotactive") {
				responseString = "ERROR: USER NOT ACTIVE";
			} else if (resJson.errorString == "nojobconnection") {
				responseString = "ERROR: NO JOBS WITH USER";
			} else if (resJson.errorString == "conversationnotsaved") {
				responseString = "ERROR: CONVERSATION NOT SAVED";
			} else {
				responseString = "ERROR: UNKNOWN";
			}

			return theCallback(resStatus, responseString, resJson);

		} else if (resStatus == "401" || resStatus == 401) {

			if (resJson.errorString == "unauthorised") {
				responseString = "ERROR: USER NOT AUTHORISED";
			} else if (resJson.errorString == "userblocked") {
				responseString = "ERROR: USER BLOCKED";
			} else if (resJson.errorString == "outdatedappversion") {
				responseString = "ERROR: OUTDATED VERSION";
			} else {
				responseString = "ERROR: UNKNOWN";
			}

			return theCallback(resStatus, responseString, resJson);

		} else if (resStatus == "504" || resStatus == 504 || resStatus == "502" || resStatus == 502) {

			responseString = "ERROR: TIMEOUT";
			return theCallback(resStatus, responseString, false);

		} else if (resStatus == "200" || resStatus == 200) {

			if (resJson.successString == "converstionfound") {
				responseString = "SUCCESS: CONVERSATION FOUND";
			}

			return theCallback(resStatus, responseString, resJson);
		}



	}, theJWTToken);

}

function setMessageStatus(theConversation, theCallback, theJWTToken) {

	serverCall("POST", '/api/messages/setmessagestatus', theConversation, function (resStatus, resJson) {

		var responseString;// quoteretrieved novalidquoteid noquotefound


		if (resStatus == "400" || resStatus == 400) {

			if (resJson.errorString == "nouserdata") {
				responseString = "ERROR: NO USER DATA";
			} else if (resJson.errorString == "novalidconversationid") {
				responseString = "ERROR: NO VALID CONVERSATION ID";
			} else if (resJson.errorString == "novalidmessageid") {
				responseString = "ERROR: NO VALID MESSAGE ID";
			} else if (resJson.errorString == "novalidmessagereadstatus") {
				responseString = "ERROR: NO VALID MESSAGE READ STATUS";
			} else if (resJson.errorString == "nouserfound") {
				responseString = "ERROR: USER NOT FOUND";
			} else if (resJson.errorString == "usernotactive") {
				responseString = "ERROR: USER NOT ACTIVE";
			} else if (resJson.errorString == "noconversationfound") {
				responseString = "ERROR: NO CONVERSATION FFOUND";
			} else if (resJson.errorString == "nomessagefound") {
				responseString = "ERROR: NO MESSAGE FFOUND";
			} else if (resJson.errorString == "userisauthor") {
				responseString = "ERROR: USER IS MESSAGE AUTHOR";
			} else if (resJson.errorString == "conversationnotsaved") {
				responseString = "ERROR: CONVERSATION NOT SAVED";
			} else if (resJson.errorString == "unauthorised") {
				responseString = "ERROR: USER NOT AUTHORISED";
			} else if (resJson.errorString == "userblocked") {
				responseString = "ERROR: USER BLOCKED";
			} else if (resJson.errorString == "outdatedappversion") {
				responseString = "ERROR: OUTDATED VERSION";
			} else {
				responseString = "ERROR: UNKNOWN";
			}

			return theCallback(resStatus, responseString, resJson);

		} else if (resStatus == "401" || resStatus == 401) {

			if (resJson.errorString == "unauthorised") {
				responseString = "ERROR: USER NOT AUTHORISED";
			} else if (resJson.errorString == "userblocked") {
				responseString = "ERROR: USER BLOCKED";
			} else if (resJson.errorString == "outdatedappversion") {
				responseString = "ERROR: OUTDATED VERSION";
			} else {
				responseString = "ERROR: UNKNOWN";
			}

			return theCallback(resStatus, responseString, resJson);

		} else if (resStatus == "504" || resStatus == 504 || resStatus == "502" || resStatus == 502) {

			responseString = "ERROR: TIMEOUT";
			return theCallback(resStatus, responseString, false);

		} else if (resStatus == "200" || resStatus == 200) {

			if (resJson.successString == "messagestatusset") {
				responseString = "SUCCESS: MESSAGE STATUS SET";
			}

			return theCallback(resStatus, responseString, resJson);
		}



	}, theJWTToken);

}

function getMessages(theConversation, theCallback, theJWTToken) {

	serverCall("POST", '/api/messages/getmessages', theConversation, function (resStatus, resJson) {

		var responseString;// quoteretrieved novalidquoteid noquotefound


		if (resStatus == "400" || resStatus == 400) {

			if (resJson.errorString == "nouserdata") {
				responseString = "ERROR: NO USER DATA";
			} else if (resJson.errorString == "nouserfound") {
				responseString = "ERROR: USER NOT FOUND";
			} else if (resJson.errorString == "usernotactive") {
				responseString = "ERROR: USER NOT ACTIVE";
				// }else if(resJson.errorString=="invalidmessagestatus"){
				// 	responseString="ERROR: INVALID MESSAGE STATUS";
			} else if (resJson.errorString == "invalidfilter") {
				responseString = "ERROR: INVALID FILTER";
			} else if (resJson.errorString == "invalidsearch") {
				responseString = "ERROR: INVALID SEARCH";
			} else if (resJson.errorString == "invalidsort") {
				responseString = "ERROR: INVALID SORT";
			} else if (resJson.errorString == "invalidpage") {
				responseString = "ERROR: INVALID PAGE";
			} else if (resJson.errorString == "unauthorised") {
				responseString = "ERROR: USER NOT AUTHORISED";
			} else if (resJson.errorString == "noconversationsfound") {
				responseString = "ERROR: NO CONVERSATIONS FOUND";
			} else {
				responseString = "ERROR: UNKNOWN";
			}

			return theCallback(resStatus, responseString, resJson);

		} else if (resStatus == "401" || resStatus == 401) {

			if (resJson.errorString == "unauthorised") {
				responseString = "ERROR: USER NOT AUTHORISED";
			} else if (resJson.errorString == "userblocked") {
				responseString = "ERROR: USER BLOCKED";
			} else if (resJson.errorString == "outdatedappversion") {
				responseString = "ERROR: OUTDATED VERSION";
			} else {
				responseString = "ERROR: UNKNOWN";
			}

			return theCallback(resStatus, responseString, resJson);

		} else if (resStatus == "504" || resStatus == 504 || resStatus == "502" || resStatus == 502) {

			responseString = "ERROR: TIMEOUT";
			return theCallback(resStatus, responseString, false);

		} else if (resStatus == "200" || resStatus == 200) {

			if (resJson.successString == "messagesfound") {
				responseString = "SUCCESS: MESSAGES FOUND";
			}

			return theCallback(resStatus, responseString, resJson);
		}



	}, theJWTToken);

}

function getMessagesAdmin(theConversation, theCallback, theJWTToken) {

	serverCall("POST", '/api/messages/getmessagesadmin', theConversation, function (resStatus, resJson) {

		var responseString;// quoteretrieved novalidquoteid noquotefound


		if (resStatus == "400" || resStatus == 400) {

			if (resJson.errorString == "nouserdata") {
				responseString = "ERROR: NO USER DATA";
			} else if (resJson.errorString == "nouserfound") {
				responseString = "ERROR: USER NOT FOUND";
			} else if (resJson.errorString == "usernotactive") {
				responseString = "ERROR: USER NOT ACTIVE";
				// }else if(resJson.errorString=="invalidmessagestatus"){
				// 	responseString="ERROR: INVALID MESSAGE STATUS";
			} else if (resJson.errorString == "invalidfilter") {
				responseString = "ERROR: INVALID FILTER";
			} else if (resJson.errorString == "invalidsearch") {
				responseString = "ERROR: INVALID SEARCH";
			} else if (resJson.errorString == "invalidsort") {
				responseString = "ERROR: INVALID SORT";
			} else if (resJson.errorString == "invalidpage") {
				responseString = "ERROR: INVALID PAGE";
			} else if (resJson.errorString == "unauthorised") {
				responseString = "ERROR: USER NOT AUTHORISED";
			} else if (resJson.errorString == "noconversationsfound") {
				responseString = "ERROR: NO CONVERSATIONS FOUND";
			} else {
				responseString = "ERROR: UNKNOWN";
			}

			return theCallback(resStatus, responseString, resJson);

		} else if (resStatus == "401" || resStatus == 401) {

			if (resJson.errorString == "unauthorised") {
				responseString = "ERROR: USER NOT AUTHORISED";
			} else if (resJson.errorString == "userblocked") {
				responseString = "ERROR: USER BLOCKED";
			} else if (resJson.errorString == "outdatedappversion") {
				responseString = "ERROR: OUTDATED VERSION";
			} else {
				responseString = "ERROR: UNKNOWN";
			}

			return theCallback(resStatus, responseString, resJson);

		} else if (resStatus == "504" || resStatus == 504 || resStatus == "502" || resStatus == 502) {

			responseString = "ERROR: TIMEOUT";
			return theCallback(resStatus, responseString, false);

		} else if (resStatus == "200" || resStatus == 200) {

			if (resJson.successString == "messagesfound") {
				responseString = "SUCCESS: MESSAGES FOUND";
			}

			return theCallback(resStatus, responseString, resJson);
		}



	}, theJWTToken);

}


function countMessages(theConversation, theCallback, theJWTToken) {

	serverCall("POST", '/api/messages/countmessages', theConversation, function (resStatus, resJson) {

		var responseString;// quoteretrieved novalidquoteid noquotefound


		if (resStatus == "400" || resStatus == 400) {

			if (resJson.errorString == "nouserdata") {
				responseString = "ERROR: NO USER DATA";
			} else if (resJson.errorString == "nouserfound") {
				responseString = "ERROR: USER NOT FOUND";
			} else if (resJson.errorString == "usernotactive") {
				responseString = "ERROR: USER NOT ACTIVE";
			} else if (resJson.errorString == "unauthorised") {
				responseString = "ERROR: USER NOT AUTHORISED";
			} else {
				responseString = "ERROR: UNKNOWN";
			}

			return theCallback(resStatus, responseString, resJson);

		} else if (resStatus == "401" || resStatus == 401) {

			if (resJson.errorString == "unauthorised") {
				responseString = "ERROR: USER NOT AUTHORISED";
			} else if (resJson.errorString == "userblocked") {
				responseString = "ERROR: USER BLOCKED";
			} else if (resJson.errorString == "outdatedappversion") {
				responseString = "ERROR: OUTDATED VERSION";
			} else {
				responseString = "ERROR: UNKNOWN";
			}

			return theCallback(resStatus, responseString, resJson);

		} else if (resStatus == "504" || resStatus == 504 || resStatus == "502" || resStatus == 502) {

			responseString = "ERROR: TIMEOUT";
			return theCallback(resStatus, responseString, false);

		} else if (resStatus == "200" || resStatus == 200) {

			if (resJson.successString == "messagescounted") {
				responseString = "SUCCESS: MESSAGES COUNTED";
			}

			return theCallback(resStatus, responseString, resJson);
		}



	}, theJWTToken);

}

export {
	sendMessage,
	updateMessage,
	getConversation,
	setMessageStatus,
	getMessages,
	getMessagesAdmin,
	countMessages
}