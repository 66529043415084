import React from "react";
import ReactSlider from "react-slider";
import $ from "jquery";
import _ from "lodash";
import { Link, NavLink } from "react-router-dom";

import TopNav from "./TopNav";
import { getUsers, setUser } from "../modules/Admin";
import { showSpinner, closeSpinner, modal, confirm } from "../modules/Dialogs";
import {
  mavBaseLocationNotFound,
  sessionExpired,
  adminSaveChangesConfirm,
  defaultAvatarBase64,
  stripeConnectActive,
  adminMavWillCreateStripe,
  adminMavCannotPreactivate,
  adminMavCannotReset,
  serverUrl,
  userImagesFolder,
  imageFormat,
  defaultAvatarImage,
} from "../config/GeneralConfig";
import { addressAutoComplete } from "../modules/Geolocation";
import { toScreen } from "../modules/Routing";
import AuditNotes from "./AuditNotes";

import { getCounties, getCities } from "../modules/CountiesAndCities";
import PropTypes from "prop-types";
import withNavigate from "./withNavigate";

class Mav extends React.Component {
  constructor(props) {
    super(props);

    this.updateNotes = this.updateNotes.bind(this);

    this.addressAutoComplete = this.addressAutoComplete.bind(this);
    this.addressAutoComplete_done = this.addressAutoComplete_done.bind(this);
    this.renderMyAddresses = this.renderMyAddresses.bind(this);
    this.checkAddress = this.checkAddress.bind(this);

    this.getMav = this.getMav.bind(this);
    this.getMav_done = this.getMav_done.bind(this);
    this.renderQuotes = this.renderQuotes.bind(this);
    this.saveMav = this.saveMav.bind(this);
    this.saveMav_done = this.saveMav_done.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.deleteMavAvatar = this.deleteMavAvatar.bind(this);
    this.discardChanges = this.discardChanges.bind(this);
    this.handleSliderChange = this.handleSliderChange.bind(this);

    this.getCounties = this.getCounties.bind(this);
    this.getCounties_done = this.getCounties_done.bind(this);
    this.checkCounty = this.checkCounty.bind(this);
    this.countyAutoComplete = this.countyAutoComplete.bind(this);
    this.renderCounties = this.renderCounties.bind(this);

    this.getCities = this.getCities.bind(this);
    this.getCities_done = this.getCities_done.bind(this);
    this.checkCity = this.checkCity.bind(this);
    this.cityAutoComplete = this.cityAutoComplete.bind(this);
    this.renderCities = this.renderCities.bind(this);
    this.setLoader = this.setLoader.bind(this);
    this.runMavFetchCompleted = this.runMavFetchCompleted.bind(this);

    this.state = {
      originalStatus: "",
      findAddressStatus: "",
      mav: {
        _id: "",
        short_id: "",
        notes: [],
        accountInfo: {
          username: "",
          drivingLicence: "",
          licenceCheckCode: "",
          companyName: "",
          vatNumber: "",
          wasteHandlingLicence: "",
          wasteHandlingLicenceCopy: "",
          publicLiabilityInsurance: false,
          goodsInTransitInsurance: false,
          generalVanInsurance: false,
          firstname: "",
          surname: "",
          telephone: "",
          email: "",
          avatar: "",
          terms: false,
          settings: {
            emailnotifications: false,
            holidaymode: false,
            marketingnotifications: false,
            pushnotifications: false,
          },
          baseLocation: {
            homeaddress: {
              googleValidated: "",
              placeId: "",
              formattedAddress: "",
              postCode: "",
              addressLine1: "",
              addressLine2: "",
              cityName: "",
              countyName: "",
              countryName: "",
            },
            radius: 50,
          },
        },

        earlyAdopter: "",
        hiddenFromApi: "",
        stripeAccountId: "",
        userType: "",
        status: "",
        quotes: {},

        // New Fields Added for Mav
        //================================================================
        vanMake: "",
        vanModel: "",
        vanYear: "",
        vanRegNumber: "",
        vanStickers: false,
        tshirtSize: "",

        utilitybillCopy: "",
        utilitybillCopyData: "",
        vaninsuranceCopy: "",
        vaninsuranceCopyData: "",
        wasteHandlingLicenceCopy: "",
        wasteHandlingLicenceCopyData: "",
      },
      addressSuggestions: {
        homeaddress: {},
      },
      countiesFound: [],
      citiesFound: [],
      loaders: {
        baseLocation: false,
        city: false,
        postCode: false,
      },
    };
  }
  getBase64(file, cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }

  handleFileSelect = (event) => {
    const target = event.target;
    let stateName = target.name.replace("Picker", "");
    let dataStateName = target.name.replace("Picker", "Data");
    let file = event.target.files[0];

    if (file) {
      let fileBase64 = "";
      this.getBase64(file, (result) => {
        fileBase64 = result;

        this.setState({
          ...this.state,
          mav: {
            ...this.state.mav,
            [stateName]: file.name,
            [dataStateName]: fileBase64,
          },
        });
      });
    }
  };

  setLoader(loaderName, flag) {
    this.setState({
      ...this.state,
      loaders: {
        ...this.state.loaders,
        [loaderName]: flag,
      },
    });
  }

  updateNotes(newNotes, theCallback) {
    if (!_.isEqual(newNotes, this.state.mav.notes)) {
      this.setState(
        {
          ...this.state,
          mav: {
            ...this.state.mav,
            notes: newNotes,
          },
        },
        theCallback
      );
    } else if (theCallback) {
      theCallback();
    }
  }

  handleSliderChange(value) {
    this.setState(
      {
        ...this.state,
        mav: {
          ...this.state.mav,
          accountInfo: {
            ...this.state.mav.accountInfo,
            baseLocation: {
              ...this.state.mav.accountInfo.baseLocation,
              radius: value,
            },
          },
        },
      },
      () => {}
    );
  }

  discardChanges(event) {
    if (event) {
      event.preventDefault();
    }

    //this.getMav();

    toScreen(false, this.props.navigate, "/mavs");
  }

  addressAutoComplete(event, addressTarget) {
    this.setLoader("baseLocation", true);

    try {
      if (event) {
        event.preventDefault();
      }

      if (
        this.state.findAddressStatus ==
          "" /*&& this.state.mav.accountInfo.baseLocation[addressTarget].googleValidated==''*/ &&
        this.state.mav.accountInfo.baseLocation[addressTarget]
          .formattedAddress != "" &&
        addressTarget === "homeaddress"
      ) {
        setTimeout(() => {
          var theAddress = {
            addressTarget: addressTarget,
            theAddress: {
              ...this.state.mav.accountInfo.baseLocation[addressTarget],
            },
          };

          addressAutoComplete(theAddress, this.addressAutoComplete_done);
        }, 100);
      } else {
        this.setLoader("baseLocation", false);
      }
    } catch (error) {
      this.setLoader("baseLocation", false);
    }
  }

  addressAutoComplete_done(resStatus, responseString, resJson) {
    this.setLoader("baseLocation", false);

    if (resStatus == "400" || resStatus == 400) {
      this.setState({
        ...this.state,
        addressSuggestions: {
          homeaddress: {},
        },
      });
    } else if (resStatus == 200) {
      this.setState({
        ...this.state,
        addressSuggestions: {
          ...this.state.addressSuggestions,
          [resJson.addressTarget]: resJson.predictions,
        },
      });
    }
  }

  renderMyAddresses(key, addressTarget) {
    const address = this.state.addressSuggestions[addressTarget][key];
    return <option value={address.description} key={key} />;
  }

  checkAddress(event, addressTarget) {
    event.preventDefault();

    if (
      this.state.mav.accountInfo.baseLocation.homeaddress.placeId == "" ||
      this.state.mav.accountInfo.baseLocation.homeaddress.googleValidated !=
        "passed"
    ) {
      this.setState(
        {
          ...this.state,
          mav: {
            ...this.state.mav,
            accountInfo: {
              ...this.state.mav.accountInfo,
              baseLocation: {
                ...this.state.mav.accountInfo.baseLocation,
                homeaddress: {
                  ...this.state.mav.accountInfo.baseLocation.homeaddress,
                  googleValidated: "",
                  placeId: "",
                  formattedAddress: "",
                  addressGeometry: {
                    coordinates: [0, 0],
                    type: "Point",
                  },
                },
              },
            },
          },
        },
        () => {
          modal(mavBaseLocationNotFound);
        }
      );
    }
  }

  getMav(event) {
    showSpinner();

    if (event) {
      event.preventDefault();
    }

    const theUser = {
      accountInfo: {
        username: this.props.userDetails.accountInfo.username,
        email: this.props.userDetails.accountInfo.email,
      },
      user: this.props.mavId ?? window.location.pathname?.split("/")[2],
      userType: "mav",
    };

    getUsers(theUser, this.getMav_done, this.props.userDetails.jwttoken);
  }

  getMav_done(resStatus, responseString, resJson) {
    closeSpinner();

    if (resStatus == 401) {
      modal(sessionExpired);
      toScreen(false, this.props.navigate, "/logout");
    } else if (resStatus == "400") {
      modal(responseString);

      toScreen(false, this.props.navigate, "/mavs");
    } else if (resStatus == 200) {
      if (resJson.theUsers && resJson.theUsers["0"]) {
        if (
          !resJson.theUsers["0"].accountInfo.baseLocation ||
          !resJson.theUsers["0"].accountInfo.baseLocation.homeaddress ||
          !resJson.theUsers["0"].accountInfo.baseLocation.homeaddress
            .formattedAddress
        ) {
          resJson.theUsers["0"].accountInfo.baseLocation = {
            homeaddress: {
              googleValidated: "",
              placeId: "",
              formattedAddress: "",
            },
          };
        }

        if (!resJson.theUsers["0"].accountInfo.baseLocation.radius) {
          resJson.theUsers["0"].accountInfo.baseLocation.radius = 50;
        }

        this.setState(
          {
            ...this.state,
            originalStatus: resJson.theUsers["0"].status,
            mav: resJson.theUsers["0"],
          },
          this.runMavFetchCompleted()
        );
      } else {
        modal("MAV user details not found", "Something went wrong!", () =>
          window.history.back()
        );
      }
    }
  }

  runMavFetchCompleted() {
    this.getCounties();
    this.getCities();
  }

  saveMav(event) {
    if (event) {
      event.preventDefault();
    }

    confirm(false, adminSaveChangesConfirm, "Cancel", "Save", () => {
      showSpinner();

      var theUser = {
        accountInfo: {
          username: this.props.userDetails.accountInfo.username,
          email: this.props.userDetails.accountInfo.email,
        },
        user: {
          _id: this.state.mav._id,
          short_id: this.state.mav.short_id,
          notes: this.state.mav.notes,
          accountInfo: {
            username: this.state.mav.accountInfo.username,
            drivingLicence: this.state.mav.accountInfo.drivingLicence,
            licenceCheckCode: this.state.mav.accountInfo.licenceCheckCode,
            publicLiabilityInsurance:
              this.state.mav.accountInfo.publicLiabilityInsurance,
            goodsInTransitInsurance:
              this.state.mav.accountInfo.goodsInTransitInsurance,
            generalVanInsurance: this.state.mav.accountInfo.generalVanInsurance,
            firstname: this.state.mav.accountInfo.firstname,
            surname: this.state.mav.accountInfo.surname,
            telephone: this.state.mav.accountInfo.telephone,
            email: this.state.mav.accountInfo.email,
            baseLocation: {
              homeaddress: {
                googleValidated:
                  this.state.mav.accountInfo.baseLocation.homeaddress
                    .googleValidated,
                placeId:
                  this.state.mav.accountInfo.baseLocation.homeaddress.placeId,
                formattedAddress:
                  this.state.mav.accountInfo.baseLocation.homeaddress
                    .formattedAddress,
                postCode:
                  this.state.mav.accountInfo.baseLocation.homeaddress.postCode,
                addressLine1:
                  this.state.mav.accountInfo.baseLocation.homeaddress
                    .addressLine1,
                addressLine2:
                  this.state.mav.accountInfo.baseLocation.homeaddress
                    .addressLine2,
                cityName:
                  this.state.mav.accountInfo.baseLocation.homeaddress.cityName,
                countyName:
                  this.state.mav.accountInfo.baseLocation.homeaddress
                    .countyName,
              },
              radius: this.state.mav.accountInfo.baseLocation.radius,
            },
            settings: {
              emailnotifications:
                this.state.mav.accountInfo.settings.emailnotifications,
              holidaymode: this.state.mav.accountInfo.settings.holidaymode,
              marketingnotifications:
                this.state.mav.accountInfo.settings.marketingnotifications,
              pushnotifications:
                this.state.mav.accountInfo.settings.pushnotifications,
            },
          },
          earlyAdopter: this.state.mav.earlyAdopter,
          hiddenFromApi: this.state.mav.hiddenFromApi,
          userType: this.state.mav.userType,
          status: this.state.mav.status,
          vanMake: this.state.mav.vanMake,
          vanModel: this.state.mav.vanModel,
          vanYear: this.state.mav.vanYear,
          vanRegNumber: this.state.mav.vanRegNumber,
          vanStickers: this.state.mav.vanStickers,
          tshirtSize: this.state.mav.tshirtSize,
          utilitybillCopy: this.state.mav.utilitybillCopy,
          utilitybillCopyData: this.state.mav.utilitybillCopyData,
          vaninsuranceCopy: this.state.mav.vaninsuranceCopy,
          vaninsuranceCopyData: this.state.mav.vaninsuranceCopyData,
          wasteHandlingLicenceCopy: this.state.mav.wasteHandlingLicenceCopy,
          wasteHandlingLicenceCopyData:
            this.state.mav.wasteHandlingLicenceCopyData,
        },
      };

      //if(this.state.mav.accountInfo.companyName!=''){
      theUser.user.accountInfo.companyName =
        this.state.mav.accountInfo.companyName;
      //}

      //if(this.state.mav.accountInfo.vatNumber!=''){
      theUser.user.accountInfo.vatNumber = this.state.mav.accountInfo.vatNumber;
      //}

      //if(this.state.mav.accountInfo.wasteHandlingLicence!=''){
      theUser.user.accountInfo.wasteHandlingLicence =
        this.state.mav.accountInfo.wasteHandlingLicence;
      //}

      if (this.state.mav.accountInfo.avatar == "") {
        theUser.user.accountInfo.avatar = defaultAvatarBase64;
      }

      setUser(theUser, this.saveMav_done, this.props.userDetails.jwttoken);
    });
  }

  saveMav_done(resStatus, responseString, resJson) {
    closeSpinner();

    if (resStatus == 401) {
      modal(sessionExpired);
      toScreen(false, this.props.navigate, "/logout");
    } else if (resStatus == "400") {
      modal(responseString);
    } else if (resStatus == 200) {
      modal("Changes successfully saved", false, () => {
        toScreen(false, this.props.navigate, "/mavs");
      });
    }
  }

  renderQuotes(key) {
    const quote = this.state.mav.quotes[key];

    var originAddressShort = "";
    if (!quote.job?.originAddress?.formattedAddress) {
      originAddressShort = "";
    } else if (quote.job?.originAddress?.formattedAddress?.length > 18) {
      originAddressShort =
        quote.job?.originAddress?.formattedAddress?.substring(0, 15) + "...";
    } else {
      originAddressShort = quote.job?.originAddress?.formattedAddress;
    }

    var destinationAddressShort = "";
    if (!quote.job?.destinationAddress?.formattedAddress) {
      destinationAddressShort = "";
    } else if (quote.job?.destinationAddress?.formattedAddress.length > 18) {
      destinationAddressShort =
        quote.job?.destinationAddress?.formattedAddress.substring(0, 15) +
        "...";
    } else {
      destinationAddressShort = quote.job?.destinationAddress?.formattedAddress;
    }

    return (
      <div className="c-list-entry" key={key}>
        <div className="c-list-entry__primary">
          <b>{quote.job?.jobSpec?.date || "ASAP"}</b> | &pound;
          {quote.quoteSpec?.price} | {quote.status} | From: {originAddressShort}{" "}
          to: {destinationAddressShort}
        </div>
        <button
          type="button"
          className="c-btn c-btn--teal c-btn--small"
          onClick={(e) =>
            toScreen(e, this.props.navigate, "/quote/" + quote.short_id)
          }
        >
          view
        </button>
      </div>
    );
  }

  getCounties(event) {
    if (event) {
      event.preventDefault();
    }

    getCounties(this.getCounties_done);
  }

  getCounties_done(resStatus, responseString, resJson) {
    try {
      let varCountiesFound = [];

      if (resStatus == "400" || resStatus == 400) {
        varCountiesFound = [];
      } else if (resStatus == 200) {
        varCountiesFound = resJson.counties;
      }

      this.setState({
        ...this.state,
        countiesFound: varCountiesFound,
      });
    } catch (error) {
      console.log(error);
    }
  }

  checkCounty(event, target) {
    if (event) {
      event.preventDefault();
    }

    if (
      this.state.mav.accountInfo.baseLocation &&
      this.state.mav.accountInfo.baseLocation.homeaddress &&
      this.state.mav.accountInfo.baseLocation.homeaddress.countyName &&
      this.state.mav.accountInfo.baseLocation.homeaddress.countyName != ""
    ) {
      let strCountyName =
        this.state.mav.accountInfo.baseLocation.homeaddress.countyName;

      const matchedCounties = this.state.countiesFound.filter(function (
        county
      ) {
        return county.county == strCountyName;
      });

      if (!matchedCounties || matchedCounties.length == 0) {
        this.setState(
          {
            ...this.state,
            mav: {
              ...this.state.mav,
              accountInfo: {
                ...this.state.mav.accountInfo,
                baseLocation: {
                  ...this.state.mav.accountInfo.baseLocation,
                  homeaddress: {
                    ...this.state.mav.accountInfo.baseLocation.homeaddress,
                    countyName: "",
                  },
                },
              },
            },
          },
          () => {
            modal("County not found ");
          }
        );
      } else {
        this.getCities();
      }
    }
  }

  countyAutoComplete(event, stateName) {
    if (event) {
      event.preventDefault();
    }
  }

  renderCounties(key) {
    const county = this.state.countiesFound[key];

    return <option value={county.county} key={key} />;
  }

  getCities() {
    this.setLoader("city", true);

    if (
      this.state.mav.accountInfo.baseLocation &&
      this.state.mav.accountInfo.baseLocation.homeaddress &&
      this.state.mav.accountInfo.baseLocation.homeaddress.countyName &&
      this.state.mav.accountInfo.baseLocation.homeaddress.countyName != ""
    ) {
      let strCountyName =
        this.state.mav.accountInfo.baseLocation.homeaddress.countyName;
      getCities(strCountyName, this.getCities_done);
    } else {
      this.setLoader("city", false);
    }
  }

  getCities_done(resStatus, responseString, resJson) {
    this.setLoader("city", false);
    try {
      let varCitiesFound = [];

      if (resStatus == "400" || resStatus == 400) {
        varCitiesFound = [];
      } else if (resStatus == 200) {
        varCitiesFound = resJson.cities;
      }

      this.setState({
        ...this.state,
        citiesFound: varCitiesFound,
      });
    } catch (error) {
      console.log(error);
    }
  }

  checkCity(event, target) {
    if (event) {
      event.preventDefault();
    }

    if (
      this.state.mav.accountInfo.baseLocation &&
      this.state.mav.accountInfo.baseLocation.homeaddress &&
      this.state.mav.accountInfo.baseLocation.homeaddress.cityName &&
      this.state.mav.accountInfo.baseLocation.homeaddress.cityName != ""
    ) {
      let strCityName =
        this.state.mav.accountInfo.baseLocation.homeaddress.cityName;

      const matchedCities = this.state.citiesFound.filter(function (city) {
        return city == strCityName;
      });

      if (!matchedCities || matchedCities.length == 0) {
        this.setState(
          {
            ...this.state,
            mav: {
              ...this.state.mav,
              accountInfo: {
                ...this.state.mav.accountInfo,
                baseLocation: {
                  ...this.state.mav.accountInfo.baseLocation,
                  homeaddress: {
                    ...this.state.mav.accountInfo.baseLocation.homeaddress,
                    cityName: "",
                  },
                },
              },
            },
          },
          () => {
            modal("City not found ");
          }
        );
      }
    }
  }

  cityAutoComplete(event) {
    if (event) {
      event.preventDefault();
    }

    if (this.state.citiesFound && this.state.citiesFound.length == 0) {
      this.getCities();
    }
  }

  renderCities(key) {
    const city = this.state.citiesFound[key];

    return <option value={city} key={key} />;
  }

  handleInputChange(event, theCallback) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    var oldValue;

    const inputChangeCallback = () => {
      if (
        stripeConnectActive &&
        name === "mav.status" &&
        value === "active" &&
        (!this.state.mav.stripeAccountId ||
          this.state.mav.stripeAccountId == "")
      ) {
        modal(adminMavWillCreateStripe, "Stripe Account Notice");
      } else {
        if (
          name == "mav.accountInfo.baseLocation.homeaddress.formattedAddress"
        ) {
          var newPlaceId = "";
          var newGoogleValidated = "";

          _.forEach(
            this.state.addressSuggestions.homeaddress,
            (valueA, key) => {
              if (valueA.description == value) {
                newPlaceId = valueA.place_id;
                newGoogleValidated = "passed";
              }
            }
          );

          this.setState(
            {
              ...this.state,
              mav: {
                ...this.state.mav,
                accountInfo: {
                  ...this.state.mav.accountInfo,
                  baseLocation: {
                    ...this.state.mav.accountInfo.baseLocation,
                    homeaddress: {
                      ...this.state.mav.accountInfo.baseLocation.homeaddress,
                      googleValidated: newGoogleValidated,
                      placeId: newPlaceId,
                      addressGeometry: {
                        coordinates: [0, 0],
                        type: "Point",
                      },
                    },
                  },
                },
              },
            },
            theCallback
          );
        } else if (theCallback) {
          theCallback();
        }
      }
    };

    if (
      name === "mav.accountInfo.settings.pushnotifications" ||
      name === "mav.accountInfo.settings.emailnotifications" ||
      name === "mav.accountInfo.settings.marketingnotifications" ||
      name === "mav.accountInfo.settings.holidaymode"
    ) {
      let splitString = name.split(".");

      let settingType = splitString[splitString.length - 1];

      this.setState(
        {
          ...this.state,
          mav: {
            ...this.state.mav,
            accountInfo: {
              ...this.state.mav.accountInfo,
              settings: {
                ...this.state.mav.accountInfo.settings,
                [settingType]: value,
              },
              baseLocation: {
                ...this.state.mav.accountInfo.baseLocation,
              },
            },
          },
        },
        inputChangeCallback
      );

      //Nowt
    } else if (
      name === "mav.status" &&
      value === "preactivation" &&
      this.state.originalStatus !== "preactivation"
    ) {
      modal(adminMavCannotPreactivate);
    } else if (
      name === "mav.status" &&
      value === "resetting" &&
      this.state.originalStatus !== "resetting"
    ) {
      modal(adminMavCannotReset);
    } else if (name != "mav.accountInfo.terms") {
      if (name.split(".").length == 1) {
        oldValue = this.state[name];
        this.setState(
          {
            [name]: value,
          },
          inputChangeCallback
        );
      } else if (name.split(".").length == 2) {
        oldValue = this.state[name.split(".")[0]][name.split(".")[1]];

        this.setState(
          {
            [name.split(".")[0]]: {
              ...this.state[name.split(".")[0]],
              [name.split(".")[1]]: value,
            },
          },
          inputChangeCallback
        );
      } else if (name.split(".").length == 3) {
        oldValue =
          this.state[name.split(".")[0]][name.split(".")[1]][
            name.split(".")[2]
          ];
        this.setState(
          {
            [name.split(".")[0]]: {
              ...this.state[name.split(".")[0]],
              [name.split(".")[1]]: {
                ...this.state[name.split(".")[0]][name.split(".")[1]],
                [name.split(".")[2]]: value,
              },
            },
          },
          inputChangeCallback
        );
      } else if (name.split(".").length == 4) {
        oldValue =
          this.state[name.split(".")[0]][name.split(".")[1]][
            name.split(".")[2]
          ];
        this.setState(
          {
            [name.split(".")[0]]: {
              ...this.state[name.split(".")[0]],
              [name.split(".")[1]]: {
                ...this.state[name.split(".")[0]][name.split(".")[1]],
                [name.split(".")[2]]: {
                  ...this.state[name.split(".")[0]][name.split(".")[1]][
                    name.split(".")[2]
                  ],
                  [name.split(".")[3]]: value,
                },
              },
            },
          },
          inputChangeCallback
        );
      } else if (name.split(".").length == 5) {
        oldValue =
          this.state[name.split(".")[0]][name.split(".")[1]][
            name.split(".")[2]
          ];
        this.setState(
          {
            [name.split(".")[0]]: {
              ...this.state[name.split(".")[0]],
              [name.split(".")[1]]: {
                ...this.state[name.split(".")[0]][name.split(".")[1]],
                [name.split(".")[2]]: {
                  ...this.state[name.split(".")[0]][name.split(".")[1]][
                    name.split(".")[2]
                  ],
                  [name.split(".")[3]]: {
                    ...this.state[name.split(".")[0]][name.split(".")[1]][
                      name.split(".")[2]
                    ][name.split(".")[3]],
                    [name.split(".")[4]]: value,
                  },
                },
              },
            },
          },
          inputChangeCallback
        );
      }
    }
  }

  deleteMavAvatar(event) {
    if (event) {
      event.preventDefault();
    }

    this.setState({
      mav: {
        ...this.state.mav,
        accountInfo: {
          ...this.state.mav.accountInfo,
          avatar: "",
        },
      },
    });
  }

  componentWillMount() {
    //
  }
  componentDidMount() {
    this.getMav();
  }
  componentWillUnmount() {
    //
  }

  render() {
    return (
      <div className="l-base-layout__content">
        <h1>Admin / Mav</h1>

        <div className="c-hr"></div>

        <div className="c-subheader-info-panel">
          <div className="c-subheader-info-panel__primary">
            ID: {this.state.mav.short_id}
          </div>
        </div>

        <div className="c-hr"></div>

        <div className="l-row">
          <div className="l-col-18">
            <div className="c-avatar">
              <div className="c-avatar__pic-wrap">
                <img
                  src={
                    this.state.mav.accountInfo.avatar &&
                    this.state.mav.accountInfo.avatar != ""
                      ? serverUrl +
                        userImagesFolder +
                        "/" +
                        this.state.mav.short_id +
                        "/" +
                        this.state.mav.accountInfo.avatar +
                        imageFormat
                      : defaultAvatarImage
                  }
                  alt="avatar"
                  className="c-avatar__pic"
                  onError={(e) => {
                    e.target.src = defaultAvatarImage;
                  }}
                />
              </div>
              <button onClick={(e) => this.deleteMavAvatar(e)}>
                Delete Avatar
              </button>
            </div>
          </div>
          <div className="l-col-41">
            <div className="c-checkbox-radio">
              <input
                type="radio"
                id="pending"
                name="mav.status"
                value="preactivation"
                onChange={this.handleInputChange}
                checked={this.state.mav.status === "preactivation"}
              />
              <label htmlFor="pending">Pending</label>
            </div>
            <div className="c-checkbox-radio">
              <input
                type="radio"
                id="active"
                name="mav.status"
                value="active"
                onChange={this.handleInputChange}
                checked={this.state.mav.status === "active"}
              />
              <label htmlFor="active">Active</label>
            </div>
            <div className="c-checkbox-radio c-checkbox-radio--no-right-border">
              <input
                type="radio"
                id="blocked"
                name="mav.status"
                value="blocked"
                onChange={this.handleInputChange}
                checked={this.state.mav.status === "blocked"}
              />
              <label htmlFor="blocked">Blocked</label>
            </div>

            <div className="c-rating u-margin-left-xs u-margin-bottom-0 u-margin-top-l">
              <fieldset
                className="c-rating__wrap"
                onClick={(e) => {
                  this.state.mav.totalRatings && this.state.mav.totalRatings > 0
                    ? toScreen(
                        e,
                        this.props.navigate,
                        "/mav/" + this.state.mav.short_id + "/reviews"
                      )
                    : null;
                }}
              >
                <input
                  tabIndex="-1"
                  className="c-rating__input"
                  type="radio"
                  id="star5"
                  name="stars"
                  value="5"
                  checked={this.state.mav.ratingAsMav === 5}
                  readOnly
                />
                <label className="c-rating__label-read-only" htmlFor="star5">
                  <span className="u-visually-hidden">5 stars</span>
                </label>
                <input
                  tabIndex="-1"
                  className="c-rating__input"
                  type="radio"
                  id="star4"
                  name="stars"
                  value="4"
                  checked={this.state.mav.ratingAsMav === 4}
                  readOnly
                />
                <label className="c-rating__label-read-only" htmlFor="star4">
                  <span className="u-visually-hidden">4 stars</span>
                </label>
                <input
                  tabIndex="-1"
                  className="c-rating__input"
                  type="radio"
                  id="star3"
                  name="stars"
                  value="3"
                  checked={this.state.mav.ratingAsMav === 3}
                  readOnly
                />
                <label className="c-rating__label-read-only" htmlFor="star3">
                  <span className="u-visually-hidden">3 stars</span>
                </label>
                <input
                  tabIndex="-1"
                  className="c-rating__input"
                  type="radio"
                  id="star2"
                  name="stars"
                  value="2"
                  checked={this.state.mav.ratingAsMav === 2}
                  readOnly
                />
                <label className="c-rating__label-read-only" htmlFor="star2">
                  <span className="u-visually-hidden">2 stars</span>
                </label>
                <input
                  tabIndex="-1"
                  className="c-rating__input"
                  type="radio"
                  id="star1"
                  name="stars"
                  value="1"
                  checked={this.state.mav.ratingAsMav === 1}
                  readOnly
                />
                <label className="c-rating__label-read-only" htmlFor="star1">
                  <span className="u-visually-hidden">1 star</span>
                </label>
              </fieldset>
            </div>
            <div
              className="u-margin-left-s"
            >
              {this.state.mav.totalRatings == 0 ||
              this.state.mav.totalRatings == "" ? (
                <p className="c-rating__subtext">(No reviews yet)</p>
              ) : (
                <a href={this.state.mav.totalRatings && this.state.mav.totalRatings > 0 ? `/mav/${this.state.mav.short_id}/reviews` : "#"}>
                  View {this.state.mav.totalRatings} driver reviews
                </a>
              )}
            </div>
          </div>
          <div className="l-col-41">
            <button
              className="c-btn c-btn--small c-btn--full-width u-margin-bottom-xs c-btn--red"
              onClick={(e) => this.saveMav(e)}
            >
              Save Changes
            </button>
            <button
              className="c-btn c-btn--small c-btn--full-width u-margin-bottom-xs  c-btn--grey"
              onClick={(e) => this.discardChanges(e)}
            >
              Discard Changes
            </button>
            <button
              className="c-btn c-btn--small c-btn--full-width  c-btn--green"
              onClick={(e) =>
                toScreen(
                  e,
                  this.props.navigate,
                  "/message/" + this.state.mav.short_id,
                  false,
                  {
                    receiverId: this.state.mav?._id,
                    receiverShortId: this.state.mav?.short_id,
                    receiverType: this.state.mav?.userType,
                    userShortId1: "admin",
                    userShortId2: this.state.mav?.short_id,
                    username2: this.state.mav?.accountInfo?.username,
                    selectedPage: window.history.state.usr?.selectedPage ?? 1,
                    selectedFilter:
                      window.history.state.usr?.selectedFilter ??
                      "adminuserunread",
                    previousPath:
                      window.history.state.usr?.previousPath ??
                      window.location.pathname,
                  }
                )
              }
            >
              Contact MAV
            </button>
          </div>
        </div>

        <div className="c-hr"></div>

        <div className="l-row">
          <div className="l-col-66">
            <form action="">
              <div className="c-input-wide">
                <label className="c-input-wide__label">First name:</label>
                <input
                  type="text"
                  value={this.state.mav.accountInfo.firstname}
                  onChange={this.handleInputChange}
                  name="mav.accountInfo.firstname"
                  placeholder="First name"
                  className="c-input-wide__input"
                />
              </div>
              <div className="c-input-wide">
                <label className="c-input-wide__label">Surname:</label>
                <input
                  type="text"
                  value={this.state.mav.accountInfo.surname}
                  onChange={this.handleInputChange}
                  name="mav.accountInfo.surname"
                  placeholder="Surname"
                  className="c-input-wide__input"
                />
              </div>
              <div className="c-input-wide">
                <label className="c-input-wide__label">Username:</label>
                <input
                  type="text"
                  value={this.state.mav.accountInfo.username}
                  onChange={this.handleInputChange}
                  name="mav.accountInfo.username"
                  placeholder="Username"
                  className="c-input-wide__input"
                />
              </div>
              <div className="c-input-wide">
                <label className="c-input-wide__label">Email:</label>
                <input
                  type="text"
                  value={this.state.mav.accountInfo.email}
                  onChange={this.handleInputChange}
                  name="mav.accountInfo.email"
                  placeholder="Email"
                  className="c-input-wide__input"
                />
              </div>
              <div className="c-input-wide">
                <label className="c-input-wide__label">Telephone:</label>
                <input
                  type="text"
                  value={this.state.mav.accountInfo.telephone}
                  onChange={this.handleInputChange}
                  name="mav.accountInfo.telephone"
                  placeholder="Telephone"
                  className="c-input-wide__input"
                />
              </div>
              <div className="c-input-wide">
                <label className="c-input-wide__label">
                  Driving Licence No:
                </label>
                <input
                  type="text"
                  value={this.state.mav.accountInfo.drivingLicence}
                  onChange={this.handleInputChange}
                  name="mav.accountInfo.drivingLicence"
                  placeholder="Driving Licence Number"
                  className="c-input-wide__input"
                />
              </div>
              <div className="c-input-wide">
                <label className="c-input-wide__label">
                  Licence Check Code:
                </label>
                <input
                  type="text"
                  value={this.state.mav.accountInfo.licenceCheckCode}
                  onChange={this.handleInputChange}
                  name="mav.accountInfo.licenceCheckCode"
                  placeholder="Driving Licence Check Code"
                  className="c-input-wide__input"
                />
              </div>

              <div className="c-input-wide">
                <div className="c-input-wide__label">T-Shirt Size:</div>
                <select
                  className="c-input-wide__input"
                  value={this.state.mav.tshirtSize}
                  onChange={this.handleInputChange}
                  name="mav.tshirtSize"
                >
                  <option value="">Please select</option>
                  <option value="small">Small</option>
                  <option value="medium">Medium</option>
                  <option value="Large">Large</option>
                  <option value="xlarge">X Large</option>
                  <option value="xxlarge">XX Large</option>
                </select>
              </div>

              <br />
              <h4>Address :</h4>
              <div className="c-input-wide">
                <label className="c-input-wide__label">Base location:</label>
                <input
                  list="addressSuggestion"
                  type="text"
                  value={
                    this.state.mav.accountInfo.baseLocation.homeaddress
                      .formattedAddress
                  }
                  onFocus={(e) => {
                    this.addressAutoComplete(e, "homeaddress");
                  }}
                  onBlur={(e) => {
                    this.checkAddress(e, "homeaddress");
                  }}
                  onChange={(e) => {
                    this.handleInputChange(e, () => {
                      this.addressAutoComplete(e, "homeaddress");
                    });
                  }}
                  name="mav.accountInfo.baseLocation.homeaddress.formattedAddress"
                  placeholder="Base location"
                  className="c-input-wide__input"
                  autoComplete="off"
                />
                {this.state.loaders.baseLocation &&
                this.state.loaders.baseLocation == true ? (
                  <div className="c-input-wide__loader" />
                ) : null}
                <datalist id="addressSuggestion">
                  {_.size(this.state.addressSuggestions.homeaddress) > 0
                    ? Object.keys(
                        this.state.addressSuggestions.homeaddress
                      ).map((key) => {
                        return this.renderMyAddresses(key, "homeaddress");
                      })
                    : null}
                </datalist>
              </div>

              {/* 
							================================================
							Newly added fields 20210920 By MWays 
							================================================
							*/}

              <div className="c-input-wide">
                <label className="c-input-wide__label">Address Line 1:</label>
                <input
                  type="text"
                  value={
                    this.state.mav.accountInfo.baseLocation.homeaddress
                      .addressLine1
                  }
                  onChange={this.handleInputChange}
                  name="mav.accountInfo.baseLocation.homeaddress.addressLine1"
                  placeholder="Address Line 1"
                  className="c-input-wide__input"
                />
              </div>
              <div className="c-input-wide">
                <label className="c-input-wide__label">Address Line 2:</label>
                <input
                  type="text"
                  value={
                    this.state.mav.accountInfo.baseLocation.homeaddress
                      .addressLine2
                  }
                  onChange={this.handleInputChange}
                  name="mav.accountInfo.baseLocation.homeaddress.addressLine2"
                  placeholder="Address Line 2"
                  className="c-input-wide__input"
                />
              </div>
              <div className="c-input-wide">
                <label className="c-input-wide__label">PostCode:</label>
                <input
                  type="text"
                  value={
                    this.state.mav.accountInfo.baseLocation.homeaddress.postCode
                  }
                  onChange={this.handleInputChange}
                  name="mav.accountInfo.baseLocation.homeaddress.postCode"
                  placeholder="Post Code"
                  className="c-input-wide__input"
                />
                {this.state.loaders.postCode ? (
                  <div className="c-input-wide__loader" />
                ) : null}
              </div>

              <div className="c-input-wide">
                <label className="c-input-wide__label">County:</label>
                <input
                  list="countySuggestion"
                  type="text"
                  value={
                    this.state.mav.accountInfo.baseLocation.homeaddress
                      .countyName
                  }
                  onFocus={(e) => {
                    this.countyAutoComplete(e, "countyName");
                  }}
                  onBlur={(e) => {
                    this.checkCounty(e, "countyName");
                  }}
                  onChange={(e) => {
                    this.handleInputChange(e, () => {
                      this.countyAutoComplete(e, "countyName");
                    });
                  }}
                  name="mav.accountInfo.baseLocation.homeaddress.countyName"
                  placeholder="County"
                  className="c-input-wide__input"
                  autoComplete="off"
                />
                {this.state.loaders.couty ? (
                  <div className="c-input-wide__loader" />
                ) : null}
                <datalist id="countySuggestion">
                  {_.size(this.state.countiesFound) > 0
                    ? Object.keys(this.state.countiesFound).map((key) => {
                        return this.renderCounties(key, "county");
                      })
                    : null}
                </datalist>
              </div>

              <div className="c-input-wide">
                <label className="c-input-wide__label">City:</label>
                <input
                  list="citySuggestion"
                  type="text"
                  value={
                    this.state.mav.accountInfo.baseLocation.homeaddress.cityName
                  }
                  onFocus={(e) => {
                    this.cityAutoComplete(e, "cityName");
                  }}
                  onBlur={(e) => {
                    this.checkCity(e, "cityName");
                  }}
                  onChange={(e) => {
                    this.handleInputChange(e, () => {
                      this.cityAutoComplete(e, "city");
                    });
                  }}
                  name="mav.accountInfo.baseLocation.homeaddress.cityName"
                  placeholder="City"
                  className="c-input-wide__input"
                  autoComplete="off"
                />
                {this.state.loaders.city ? (
                  <div className="c-input-wide__loader" />
                ) : null}
                <datalist id="citySuggestion">
                  {_.size(this.state.citiesFound) > 0
                    ? Object.keys(this.state.citiesFound).map((key) => {
                        return this.renderCities(key);
                      })
                    : null}
                </datalist>
              </div>

              <div className="c-input-wide">
                <label className="c-input-wide__label">Range:</label>
                <div className="c-input-wide__slider range-slider-main">
                  <ReactSlider
                    className="horizontal-slider mav-range"
                    thumbClassName="thumb"
                    trackClassName="track"
                    min={50}
                    max={200}
                    value={this.state.mav.accountInfo.baseLocation.radius}
                    onChange={(e) => this.handleSliderChange(e)}
                    renderThumb={(props, state) => (
                      <div {...props}>
                        <span>
                          {state.valueNow === 200
                            ? "National"
                            : `${state.valueNow} miles`}
                        </span>
                      </div>
                    )}
                  />
                  {/* <Slider
                    min={50}
                    max={200}
                    format={(e) => {
                      if (e == 200) {
                        return "National";
                      } else {
                        return e + " miles";
                      }
                    }}
                    value={this.state.mav.accountInfo.baseLocation.radius}
                    orientation="horizontal"
                    onChange={(e) => {
                      this.handleSliderChange(e);
                    }}
                  /> */}
                </div>
              </div>

              <div className="c-input-wide">
                <label className="c-input-wide__label">
                  Stripe account ID:
                </label>
                <span>
                  {this.state.mav.stripeAccountId &&
                  this.state.mav.stripeAccountId != "" ? (
                    <a
                      href={
                        "https://dashboard.stripe.com/connect/accounts/" +
                        this.state.mav.stripeAccountId
                      }
                      target="_blank"
                    >
                      {this.state.mav.stripeAccountId}
                    </a>
                  ) : (
                    "N/A"
                  )}
                </span>
              </div>

              <div className="c-input-wide c-input-wide--checkbox">
                <input
                  type="checkbox"
                  className="c-input-wide__checkbox-radio-input"
                  id="earlyadopter"
                  onChange={this.handleInputChange}
                  name="mav.earlyAdopter"
                  checked={this.state.mav.earlyAdopter}
                />
                <label className="c-input-wide__label" htmlFor="earlyadopter">
                  Early Adopter:
                </label>
              </div>

              <div className="c-input-wide c-input-wide--checkbox">
                <input
                  type="checkbox"
                  className="c-input-wide__checkbox-radio-input"
                  id="hiddenFromApi"
                  onChange={this.handleInputChange}
                  name="mav.hiddenFromApi"
                  checked={this.state.mav.hiddenFromApi}
                />
                <label className="c-input-wide__label" htmlFor="hiddenFromApi">
                  Hidden from API:
                </label>
              </div>

              <div className="c-input-wide c-input-wide--checkbox">
                <input
                  type="checkbox"
                  className="c-input-wide__checkbox-radio-input"
                  id="mav.accountInfo.terms"
                  onChange={this.handleInputChange}
                  name="mav.accountInfo.terms"
                  checked={this.state.mav.accountInfo.terms}
                />
                <label
                  className="c-input-wide__label"
                  htmlFor="mav.accountInfo.terms"
                >
                  Terms agreed:
                </label>
              </div>

              {/* 
							================================================
							Newly added fields 20210920 By MWays  22222
							================================================
							*/}

              <br />
              <h4>Documents Attached:</h4>
              <div className="c-input-wide">
                <label className="c-input-wide__label">
                  Utility Bill Copy:
                </label>
                <span>
                  <b>
                    {this.state.mav.utilitybillCopy &&
                    this.state.mav.utilitybillCopy != "" ? (
                      <a href={this.state.mav.utilitybillCopy} target="_blank">
                        Utility Bill Copy{" "}
                      </a>
                    ) : (
                      "N/A"
                    )}
                  </b>
                </span>
                <input
                  id="utilitybillCopyPicker"
                  type="file"
                  accept="image/*, application/pdf"
                  className="u-margin-left-s"
                  onChange={this.handleFileSelect}
                  name="utilitybillCopyPicker"
                />
              </div>

              <div className="c-input-wide">
                <label className="c-input-wide__label">
                  Van Insurance Copy:
                </label>
                <span>
                  <b>
                    {this.state.mav.vaninsuranceCopy &&
                    this.state.mav.vaninsuranceCopy != "" ? (
                      <a href={this.state.mav.vaninsuranceCopy} target="_blank">
                        Van Insurance Copy{" "}
                      </a>
                    ) : (
                      "N/A"
                    )}
                  </b>
                </span>
                <input
                  id="utilitybillCopyPicker"
                  type="file"
                  accept="image/*, application/pdf"
                  className="u-margin-left-s"
                  onChange={this.handleFileSelect}
                  name="vaninsuranceCopyPicker"
                />
              </div>

              <div className="c-input-wide">
                <label className="c-input-wide__label">
                  WasteH Licence Copy:
                </label>
                <span>
                  <b>
                    {this.state.mav.accountInfo.wasteHandlingLicenceCopy &&
                    this.state.mav.accountInfo.wasteHandlingLicenceCopy !=
                      "" ? (
                      <a
                        href={
                          this.state.mav.accountInfo.wasteHandlingLicenceCopy
                        }
                        target="_blank"
                      >
                        Waste Handling Licence Copy{" "}
                      </a>
                    ) : (
                      "N/A"
                    )}
                  </b>
                </span>
                <input
                  id="utilitybillCopyPicker"
                  type="file"
                  accept="image/*, application/pdf"
                  className="u-margin-left-s"
                  onChange={this.handleFileSelect}
                  name="wasteHandlingLicenceCopyPicker"
                />
              </div>

              <br />
              <h4>Van Details:</h4>

              <div className="c-input-wide">
                <label className="c-input-wide__label">Van Make:</label>
                <input
                  type="text"
                  value={this.state.mav.vanMake}
                  onChange={this.handleInputChange}
                  name="mav.vanMake"
                  placeholder="Van Make"
                  className="c-input-wide__input"
                />
              </div>

              <div className="c-input-wide">
                <label className="c-input-wide__label">Van Model:</label>
                <input
                  type="text"
                  value={this.state.mav.vanModel}
                  onChange={this.handleInputChange}
                  name="mav.vanModel"
                  placeholder="Van Model"
                  className="c-input-wide__input"
                />
              </div>

              <div className="c-input-wide">
                <label className="c-input-wide__label">Van Year:</label>
                <input
                  type="text"
                  value={this.state.mav.vanYear}
                  onChange={this.handleInputChange}
                  name="mav.vanYear"
                  placeholder="Van Year"
                  className="c-input-wide__input"
                />
              </div>

              <div className="c-input-wide">
                <label className="c-input-wide__label">
                  Vehicle Reg. Number:
                </label>
                <input
                  type="text"
                  value={this.state.mav.vanRegNumber}
                  onChange={this.handleInputChange}
                  name="mav.vanRegNumber"
                  placeholder="Vehicle Reg. Number"
                  className="c-input-wide__input"
                />
              </div>

              <div className="c-input-wide c-input-wide--checkbox">
                <input
                  type="checkbox"
                  className="c-input-wide__checkbox-radio-input"
                  id="mav.vanStickers"
                  onChange={this.handleInputChange}
                  name="mav.vanStickers"
                  checked={this.state.mav.vanStickers}
                />
                <label
                  className="c-input-wide__label"
                  htmlFor="mav.vanStickers"
                >
                  Need Van Stickers ?
                </label>
              </div>

              <br />
              <h4>Company Details:</h4>

              <div className="c-input-wide">
                <label className="c-input-wide__label">Company Name:</label>
                <input
                  type="text"
                  value={this.state.mav.accountInfo.companyName}
                  onChange={this.handleInputChange}
                  name="mav.accountInfo.companyName"
                  placeholder="Company Name"
                  className="c-input-wide__input"
                />
              </div>
              <div className="c-input-wide">
                <label className="c-input-wide__label">VAT Number:</label>
                <input
                  type="text"
                  value={this.state.mav.accountInfo.vatNumber}
                  onChange={this.handleInputChange}
                  name="mav.accountInfo.vatNumber"
                  placeholder="VAT Number"
                  className="c-input-wide__input"
                />
              </div>
              <div className="c-input-wide">
                <label className="c-input-wide__label">Waste/H Licence:</label>
                <input
                  type="text"
                  value={this.state.mav.accountInfo.wasteHandlingLicence}
                  onChange={this.handleInputChange}
                  name="mav.accountInfo.wasteHandlingLicence"
                  placeholder="Waste Handling Licence"
                  className="c-input-wide__input"
                />
              </div>

              <br />
              <h4>Insurance Status:</h4>

              <div className="c-input-wide c-input-wide--checkbox">
                <input
                  type="checkbox"
                  className="c-input-wide__checkbox-radio-input"
                  id="mav.accountInfo.publicLiabilityInsurance"
                  onChange={this.handleInputChange}
                  name="mav.accountInfo.publicLiabilityInsurance"
                  checked={this.state.mav.accountInfo.publicLiabilityInsurance}
                />
                <label
                  className="c-input-wide__label"
                  htmlFor="mav.accountInfo.publicLiabilityInsurance"
                >
                  Public Liability:
                </label>
              </div>

              <div className="c-input-wide c-input-wide--checkbox">
                <input
                  type="checkbox"
                  className="c-input-wide__checkbox-radio-input"
                  id="mav.accountInfo.goodsInTransitInsurance"
                  onChange={this.handleInputChange}
                  name="mav.accountInfo.goodsInTransitInsurance"
                  checked={this.state.mav.accountInfo.goodsInTransitInsurance}
                />
                <label
                  className="c-input-wide__label"
                  htmlFor="mav.accountInfo.goodsInTransitInsurance"
                >
                  Goods In Transit:
                </label>
              </div>
              <div className="c-input-wide c-input-wide--checkbox">
                <input
                  type="checkbox"
                  className="c-input-wide__checkbox-radio-input"
                  id="mav.accountInfo.generalVanInsurance"
                  onChange={this.handleInputChange}
                  name="mav.accountInfo.generalVanInsurance"
                  checked={this.state.mav.accountInfo.generalVanInsurance}
                />
                <label
                  className="c-input-wide__label"
                  htmlFor="mav.accountInfo.generalVanInsurance"
                >
                  General Van:
                </label>
              </div>

              <br />
              <h4>Notification Settings:</h4>

              <div className="c-input-wide">
                <label className="c-input-wide__label-alt">
                  Push notifications :
                </label>
                <span className="c-input-wide__copy-alt">
                  <input
                    type="checkbox"
                    className="c-toggle-switch-small__input"
                    name="mav.accountInfo.settings.pushnotifications"
                    id="mav.accountInfo.settings.pushnotifications"
                    onChange={this.handleInputChange}
                    checked={
                      this.state.mav.accountInfo.settings.pushnotifications ===
                      true
                    }
                  />
                  <label
                    htmlFor="mav.accountInfo.settings.pushnotifications"
                    className="c-toggle-switch-small__label"
                  >
                    <span className="u-visually-hidden">
                      Push notifications
                    </span>
                  </label>
                </span>
              </div>

              <div className="c-input-wide">
                <label className="c-input-wide__label-alt">
                  Email notifications :
                </label>
                <span className="c-input-wide__copy-alt">
                  <input
                    type="checkbox"
                    className="c-toggle-switch-small__input"
                    name="mav.accountInfo.settings.emailnotifications"
                    id="mav.accountInfo.settings.emailnotifications"
                    onChange={this.handleInputChange}
                    checked={
                      this.state.mav.accountInfo.settings.emailnotifications ===
                      true
                    }
                  />
                  <label
                    htmlFor="mav.accountInfo.settings.emailnotifications"
                    className="c-toggle-switch-small__label"
                  >
                    <span className="u-visually-hidden">
                      Email notifications
                    </span>
                  </label>
                </span>
              </div>

              <div className="c-input-wide">
                <label className="c-input-wide__label-alt">
                  Marketing notifications :
                </label>
                <span className="c-input-wide__copy-alt">
                  <input
                    type="checkbox"
                    className="c-toggle-switch-small__input"
                    name="mav.accountInfo.settings.marketingnotifications"
                    id="mav.accountInfo.settings.marketingnotifications"
                    onChange={this.handleInputChange}
                    checked={
                      this.state.mav.accountInfo.settings
                        .marketingnotifications === true
                    }
                  />
                  <label
                    htmlFor="mav.accountInfo.settings.marketingnotifications"
                    className="c-toggle-switch-small__label"
                  >
                    <span className="u-visually-hidden">
                      Marketing notifications
                    </span>
                  </label>
                </span>
              </div>

              <div className="c-input-wide">
                <label className="c-input-wide__label-alt">
                  Holiday mode :
                </label>
                <span className="c-input-wide__copy-alt">
                  <input
                    type="checkbox"
                    className="c-toggle-switch-small__input"
                    name="mav.accountInfo.settings.holidaymode"
                    id="mav.accountInfo.settings.holidaymode"
                    onChange={this.handleInputChange}
                    checked={
                      this.state.mav.accountInfo.settings.holidaymode === true
                    }
                  />
                  <label
                    htmlFor="mav.accountInfo.settings.holidaymode"
                    className="c-toggle-switch-small__label"
                  >
                    <span className="u-visually-hidden">Holiday mode</span>
                  </label>
                </span>
              </div>
            </form>
          </div>

          <div className="l-col-33">
            <AuditNotes
              ref="auditNotes"
              notes={this.state.mav.notes}
              updateNotes={this.updateNotes}
            />
          </div>
        </div>

        <div className="l-row l-row">
          <div className="l-col-100">
            <h2 className="u-margin-bottom-0">Quotes</h2>
          </div>
        </div>

        <div className="c-hr"></div>

        <div className="l-row">
          <div className="l-col-33"></div>
          <div className="l-col-66">
            {_.size(this.state.mav.quotes) == 0
              ? "No quotes for this MAV."
              : Object.keys(this.state.mav.quotes).map(this.renderQuotes)}
          </div>
        </div>
      </div>
    );
  }
}

Mav.contextTypes = {
  router: PropTypes.object,
};

Mav.propTypes = {
  userDetails: PropTypes.object.isRequired,
  mavId: PropTypes.string.isRequired,
  updateState: PropTypes.func.isRequired,
};

export default withNavigate(Mav);
