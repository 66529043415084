import React from "react";
import $ from "jquery";
import _ from "lodash";
import { Link, NavLink } from "react-router-dom";

import TopNav from "./TopNav";
import { setQuote } from "../modules/Admin";
import {
  showSpinner,
  closeSpinner,
  modal,
  confirm,
  updateApp,
  alert,
} from "../modules/Dialogs";
import {
  appVersionOutdatedExpired,
  userIsBlocked,
  sessionExpired,
  serverTimeOutMsg,
  serverUrl,
  userImagesFolder,
  imageFormat,
  defaultAvatarImage,
} from "../config/GeneralConfig";
import { spoofedISODateProcess } from "../modules/Generic";
import { toScreen } from "../modules/Routing";
import { retrieveRatingsMav } from "../modules/Ratings";
import EditRating from "./EditRating";
import PropTypes from "prop-types";
import withNavigate from "./withNavigate";
class Reviews extends React.Component {
  constructor(props) {
    super(props);

    this.editRating = this.editRating.bind(this);
    this.saveRating = this.saveRating.bind(this);
    this.deleteRating = this.deleteRating.bind(this);
    this.getMavRatings = this.getMavRatings.bind(this);
    this.getMavRatings_done = this.getMavRatings_done.bind(this);
    this.sortRatings = this.sortRatings.bind(this);

    this.renderReviews = this.renderReviews.bind(this);

    this.nextPage = this.nextPage.bind(this);
    this.prevPage = this.prevPage.bind(this);

    this.state = {
      sort: '{"job.creationDate":1}',
      mav: {
        _id: "",
        short_id: "",
        notes: [],
        accountInfo: {
          username: "",
          drivingLicence: "",
          companyName: "",
          vatNumber: "",
          wasteHandlingLicence: "",
          publicLiabilityInsurance: false,
          goodsInTransitInsurance: false,
          generalVanInsurance: false,
          firstname: "",
          surname: "",
          telephone: "",
          email: "",
          avatar: "",
          terms: false,
          settings: {
            emailnotifications: false,
            holidaymode: false,
            marketingnotifications: false,
            pushnotifications: false,
          },
          baseLocation: {
            homeaddress: {
              googleValidated: "",
              placeId: "",
              formattedAddress: "",
            },
            radius: 50,
          },
        },

        earlyAdopter: "",
        stripeAccountId: "",
        userType: "",
        status: "",
        quotes: {},
      },
      ratings: {},
      pagination: {
        page: 1,
        total: 1,
      },
    };
  }

  editRating(event, theKey) {
    if (event) {
      event.preventDefault();
    }

    this.refs.editRatingInput.goEditRating(
      event,
      theKey,
      this.state.ratings[theKey].comment,
      this.state.ratings[theKey].stars,
      this.state.ratings[theKey].job._creator.accountInfo.firstname
    );
  }

  saveRating(event, theKey, theComment, theStars) {
    if (event) {
      event.preventDefault();
    }

    showSpinner();

    const theQuote = {
      short_id: this.state.ratings[theKey].job.quote.short_id,
      ratings: [
        {
          stars: parseInt(theStars),
          comment: theComment,
        },
      ],
    };

    setQuote(
      theQuote,
      (resStatus, responseString, resJson) => {
        closeSpinner();

        if (resStatus == 401) {
          modal(sessionExpired);
          toScreen(false, this.props.navigate, "/logout");
        } else if (resStatus == "400") {
          modal(responseString);
        } else if (resStatus == 200) {
          this.getMavRatings();
        }
      },
      this.props.userDetails.jwttoken
    );
  }

  deleteRating(event, theKey) {
    if (event) {
      event.preventDefault();
    }

    confirm(
      false,
      "Are you sure you want to delete this review?",
      "Cancel",
      "Delete",
      () => {
        this.saveRating(event, theKey, false, false);
      }
    );
  }

  getMavRatings() {
    if (
      this.props.userDetails.jwttoken != null &&
      this.props.userDetails.accountInfo.username != null
    ) {
      const theMav = {
        accountInfo: {
          username: this.props.userDetails.accountInfo.username,
          email: this.props.userDetails.accountInfo.email,
        },
        short_id: this.props.mavId ?? window.location.pathname?.split("/")[2],
        contentmode: "full",
        profilemode: "full",
        sort: this.state.sort,
      };

      if (this.state.pagination.page && this.state.pagination.page != "") {
        theMav.page = this.state.pagination.page;
      }

      return retrieveRatingsMav(
        theMav,
        this.getMavRatings_done,
        this.props.userDetails.jwttoken
      );
    }
  }

  getMavRatings_done(resStatus, responseString, resJson) {
    if (resStatus == 401) {
      if (responseString == "ERROR: OUTDATED VERSION") {
        var updateUrl;

        if (
          this.props.appMode == "mobile" &&
          device &&
          (device.platform == "iOS" || device.version == "iPhone")
        ) {
          updateUrl =
            "https://itunes.apple.com/us/app/man-van/id1455174908?ls=1&mt=8";
        } else if (
          this.props.appMode == "mobile" &&
          device &&
          (device.platform == "Android" || device.version == "Android")
        ) {
          updateUrl =
            "https://play.google.com/store/apps/details?id=uk.co.makeonline.manandvan";
        } else {
          updateUrl = "https://www.manandvan.app/";
        }

        updateApp(appVersionOutdatedExpired, false, () => {
          window.open(updateUrl, "_system");
        });
      } else if (responseString == "ERROR: USER BLOCKED") {
        modal(userIsBlocked);
        toScreen(false, this.props.navigate, "/logout");
      } else {
        modal(sessionExpired);
        toScreen(false, this.props.navigate, "/logout");
      }
    } else if (
      resStatus == "504" ||
      resStatus == 504 ||
      resStatus == "502" ||
      resStatus == 502
    ) {
      modal(serverTimeOutMsg);
      toScreen(false, this.props.navigate, "/welcome");
    } else if (resStatus == "400" || resStatus == 400) {
      alert(responseString);
    } else if (resStatus == 200) {
      this.setState({
        ...this.state,
        mav: resJson._creator,
        ratings: resJson._creator.ratings,
        pagination: {
          ...this.state.pagination,
          total: resJson.pagination.total,
        },
      });
    }
  }

  sortRatings(event, theSort) {
    if (event) {
      event.preventDefault();
    }

    var tmpSort;

    if (theSort == "date") {
      if (this.state.sort === '{"job.creationDate":1}') {
        tmpSort = '{"job.creationDate":-1}';
      } else {
        tmpSort = '{"job.creationDate":1}';
      }
    } else if (theSort == "stars") {
      if (this.state.sort === '{"ratings[0].stars":1}') {
        tmpSort = '{"ratings[0].stars":-1}';
      } else {
        tmpSort = '{"ratings[0].stars":1}';
      }
    } else if (theSort == "client") {
      if (this.state.sort === '{"job._creator.accountInfo.firstname":1}') {
        tmpSort = '{"job._creator.accountInfo.firstname":-1}';
      } else {
        tmpSort = '{"job._creator.accountInfo.firstname":1}';
      }
    }

    const inputChangeCallback = () => {
      this.getMavRatings();
    };

    this.setState(
      {
        ...this.state,
        sort: tmpSort,
      },
      inputChangeCallback
    );
  }

  renderReviews(key) {
    const review = this.state.ratings[key];

    // let theQuoteCount = _.countBy(mav.quotes, 'status');

    return (
      <div className="c-list-entry" key={key}>
        <div className="c-list-entry__comment">
          {review.comment ? (
            <b>{'"' + review.comment + '"'}</b>
          ) : (
            "(no comment)"
          )}
        </div>
        <div className="c-list-entry__rating">
          <div className="c-rating c-rating--list">
            <fieldset className="c-rating__wrap">
              <input
                tabIndex="-1"
                className="c-rating__input"
                type="radio"
                name={"stars" + key}
                value="5"
                checked={review.stars === 5}
                readOnly
              />
              <label className="c-rating__label-read-only-small">
                <span className="u-visually-hidden">5 stars</span>
              </label>
              <input
                tabIndex="-1"
                className="c-rating__input"
                type="radio"
                name={"stars" + key}
                value="4"
                checked={review.stars === 4}
                readOnly
              />
              <label className="c-rating__label-read-only-small">
                <span className="u-visually-hidden">4 stars</span>
              </label>
              <input
                tabIndex="-1"
                className="c-rating__input"
                type="radio"
                name={"stars" + key}
                value="3"
                checked={review.stars === 3}
                readOnly
              />
              <label className="c-rating__label-read-only-small">
                <span className="u-visually-hidden">3 stars</span>
              </label>
              <input
                tabIndex="-1"
                className="c-rating__input"
                type="radio"
                name={"stars" + key}
                value="2"
                checked={review.stars === 2}
                readOnly
              />
              <label className="c-rating__label-read-only-small">
                <span className="u-visually-hidden">2 stars</span>
              </label>
              <input
                tabIndex="-1"
                className="c-rating__input"
                type="radio"
                name={"stars" + key}
                value="1"
                checked={review.stars === 1}
                readOnly
              />
              <label className="c-rating__label-read-only-small">
                <span className="u-visually-hidden">1 star</span>
              </label>
            </fieldset>
          </div>
        </div>
        <div className="c-list-entry__clientname ">
          {review.job._creator.accountInfo.firstname +
            " " +
            review.job._creator.accountInfo.surname}
        </div>
        <div className="c-list-entry__reviews">
          {review.job.creationDate
            ? spoofedISODateProcess(review.job.creationDate, "printDateNice")
            : "(in progress)"}
        </div>
        <button
          type="button"
          className="c-btn c-btn--teal c-btn--small"
          onClick={(e) =>
            toScreen(e, this.props.navigate, "/job/" + review.job.short_id)
          }
        >
          View job
        </button>
        <button
          type="button"
          className="c-btn c-btn--red c-btn--small"
          onClick={(e) => this.deleteRating(e, key)}
        >
          Delete
        </button>
        <button
          type="button"
          className="c-btn c-btn--green c-btn--small"
          onClick={(e) => this.editRating(e, key)}
        >
          Edit
        </button>
      </div>
    );
  }

  nextPage(event) {
    if (event) {
      event.preventDefault();
    }

    const inputChangeCallback = () => {
      this.getMavRatings();
    };

    if (this.state.pagination.page < this.state.pagination.total) {
      this.setState(
        {
          ...this.state,
          pagination: {
            ...this.state.pagination,
            page: this.state.pagination.page + 1,
          },
        },
        inputChangeCallback
      );
    }
  }

  prevPage(event) {
    if (event) {
      event.preventDefault();
    }

    const inputChangeCallback = () => {
      this.getMavRatings();
    };

    if (this.state.pagination.page > 1) {
      this.setState(
        {
          ...this.state,
          pagination: {
            ...this.state.pagination,
            page: this.state.pagination.page - 1,
          },
        },
        inputChangeCallback
      );
    }
  }

  componentWillMount() {
    //
  }
  componentDidMount() {
    this.getMavRatings();
  }
  componentWillUnmount() {
    //
  }

  render() {
    return (
      <div className="l-base-layout__content">
        <h1>Admin / Mav / Reviews</h1>

        <div className="c-hr"></div>

        <div className="c-subheader-info-panel">
          <div className="c-subheader-info-panel__primary">
            ID: {this.state.mav.short_id}
          </div>
        </div>

        <div className="c-hr"></div>

        <div className="l-row">
          <div className="l-col-18">
            <div className="c-avatar u-margin-bottom-0">
              <div
                className="c-avatar__pic-wrap"
                onClick={(e) => {
                  this.state.mav.totalRatings && this.state.mav.totalRatings > 0
                    ? toScreen(
                        e,
                        this.props.navigate,
                        "/mav/" + this.state.mav.short_id
                      )
                    : null;
                }}
              >
                <img
                  src={
                    this.state.mav.accountInfo.avatar &&
                    this.state.mav.accountInfo.avatar != ""
                      ? serverUrl +
                        userImagesFolder +
                        "/" +
                        this.state.mav.short_id +
                        "/" +
                        this.state.mav.accountInfo.avatar +
                        imageFormat
                      : defaultAvatarImage
                  }
                  alt="avatar"
                  className="c-avatar__pic"
                  onError={(e) => {
                    e.target.src = defaultAvatarImage;
                  }}
                />
              </div>
            </div>
          </div>
          <div className="l-col-41">
            <div className="c-rating u-margin-left-xs u-margin-bottom-0 u-margin-top-0">
              <fieldset
                className="c-rating__wrap"
                onClick={(e) => {
                  toScreen(
                    e,
                    this.props.navigate,
                    "/mav/" + this.state.mav.short_id
                  );
                }}
              >
                <input
                  tabIndex="-1"
                  className="c-rating__input"
                  type="radio"
                  id="star5"
                  name="stars"
                  value="5"
                  checked={this.state.mav.ratingAsMav === 5}
                  readOnly
                />
                <label className="c-rating__label-read-only" htmlFor="star5">
                  <span className="u-visually-hidden">5 stars</span>
                </label>
                <input
                  tabIndex="-1"
                  className="c-rating__input"
                  type="radio"
                  id="star4"
                  name="stars"
                  value="4"
                  checked={this.state.mav.ratingAsMav === 4}
                  readOnly
                />
                <label className="c-rating__label-read-only" htmlFor="star4">
                  <span className="u-visually-hidden">4 stars</span>
                </label>
                <input
                  tabIndex="-1"
                  className="c-rating__input"
                  type="radio"
                  id="star3"
                  name="stars"
                  value="3"
                  checked={this.state.mav.ratingAsMav === 3}
                  readOnly
                />
                <label className="c-rating__label-read-only" htmlFor="star3">
                  <span className="u-visually-hidden">3 stars</span>
                </label>
                <input
                  tabIndex="-1"
                  className="c-rating__input"
                  type="radio"
                  id="star2"
                  name="stars"
                  value="2"
                  checked={this.state.mav.ratingAsMav === 2}
                  readOnly
                />
                <label className="c-rating__label-read-only" htmlFor="star2">
                  <span className="u-visually-hidden">2 stars</span>
                </label>
                <input
                  tabIndex="-1"
                  className="c-rating__input"
                  type="radio"
                  id="star1"
                  name="stars"
                  value="1"
                  checked={this.state.mav.ratingAsMav === 1}
                  readOnly
                />
                <label className="c-rating__label-read-only" htmlFor="star1">
                  <span className="u-visually-hidden">1 star</span>
                </label>
              </fieldset>
            </div>
            <div className="u-margin-left-s">
              {this.state.mav.totalRatings == 0 ||
              this.state.mav.totalRatings == "" ? (
                <p className="c-rating__subtext">(No reviews yet)</p>
              ) : (
                <a
                  href={
                    this.state.mav.totalRatings &&
                    this.state.mav.totalRatings > 0
                      ? `/mav/${this.state.mav.short_id}`
                      : "#"
                  }
                >
                  {this.state.mav.totalRatings} driver reviews
                </a>
              )}
            </div>
          </div>
          <div className="l-col-41">
            <button
              className="c-btn c-btn--small c-btn--full-width u-margin-bottom-xs  c-btn--grey"
              onClick={(e) => {
                toScreen(
                  e,
                  this.props.navigate,
                  "/mav/" + this.state.mav.short_id
                );
              }}
            >
              View MAV
            </button>
            <button
              className="c-btn c-btn--small c-btn--full-width  c-btn--green"
              onClick={(e) =>{console.log({mavlist:this.state.mav})
                toScreen(
                  e,
                  this.props.navigate,
                  "/message/" + this.state.mav.short_id,
                  false,
                  {
                    receiverId: this.state.mav?.accountInfo?._id,
                    receiverShortId: this.state.mav?.short_id,
                    receiverType: this.state.mav?.userType ?? "mav",
                    userShortId1: "admin",
                    userShortId2: this.state.mav?.short_id,
                    username2: this.state.mav?.accountInfo?.username,
                    selectedPage: window.history.state.usr?.selectedPage ?? 1,
                    selectedFilter:
                      window.history.state.usr?.selectedFilter ??
                      "adminuserunread",
                    previousPath:
                      window.history.state.usr?.previousPath ??
                      window.location.pathname,
                  }
                )}
              }
            >
              Contact MAV
            </button>
          </div>
        </div>

        <div className="l-col-100">
          <div className="c-pagination">
            <button
              type="button"
              className="c-pagination__prev"
              onClick={(e) => this.prevPage(e)}
            >
              &lt;&lt; Prev
            </button>
            {this.state.pagination.page} / {this.state.pagination.total}
            <button
              type="button"
              className="c-pagination__next"
              onClick={(e) => this.nextPage(e)}
            >
              Next &gt;&gt;
            </button>
          </div>
          <div className="c-hr u-margin-bottom-0"></div>

          <div>
            <div className="c-list-entry c-list-entry__header">
              <div className="c-list-entry__comment c-list-filter"></div>
              <div className="c-list-entry__rating c-list-filter">
                <button
                  className={
                    this.state.sort == '{"ratings[0].stars":-1}'
                      ? "c-list-filter__button c-list-filter__button--icon-up"
                      : this.state.sort == '{"ratings[0].stars":1}'
                      ? "c-list-filter__button c-list-filter__button--icon-down"
                      : "c-list-filter__button c-list-filter__button--faded"
                  }
                  onClick={(e) => this.sortRatings(e, "stars")}
                >
                  Stars
                </button>
              </div>
              <div className="c-list-entry__clientname c-list-filter">
                <button
                  className={
                    this.state.sort ==
                    '{"job._creator.accountInfo.firstname":-1}'
                      ? "c-list-filter__button c-list-filter__button--icon-up"
                      : this.state.sort ==
                        '{"job._creator.accountInfo.firstname":1}'
                      ? "c-list-filter__button c-list-filter__button--icon-down"
                      : "c-list-filter__button c-list-filter__button--faded"
                  }
                  onClick={(e) => this.sortRatings(e, "client")}
                >
                  Client
                </button>
              </div>
              <div className="c-list-entry__reviews c-list-filter">
                <button
                  className={
                    this.state.sort == '{"job.creationDate":-1}'
                      ? "c-list-filter__button c-list-filter__button--icon-up"
                      : this.state.sort == '{"job.creationDate":1}'
                      ? "c-list-filter__button c-list-filter__button--icon-down"
                      : "c-list-filter__button c-list-filter__button--faded"
                  }
                  onClick={(e) => this.sortRatings(e, "date")}
                >
                  Job date
                </button>
              </div>
            </div>
          </div>

          <div>{Object.keys(this.state.ratings).map(this.renderReviews)}</div>
          <div className="c-hr u-margin-bottom-0 u-clear-both"></div>
          <div className="c-pagination">
            <button
              type="button"
              className="c-pagination__prev"
              onClick={(e) => this.prevPage(e)}
            >
              &lt;&lt; Prev
            </button>
            {this.state.pagination.page} / {this.state.pagination.total}
            <button
              type="button"
              className="c-pagination__next"
              onClick={(e) => this.nextPage(e)}
            >
              Next &gt;&gt;
            </button>
          </div>
        </div>

        <EditRating ref="editRatingInput" saveRating={this.saveRating} />
      </div>
    );
  }
}

Reviews.contextTypes = {
  router: PropTypes.object,
};

Reviews.propTypes = {
  userDetails: PropTypes.object.isRequired,
  mavId: PropTypes.string.isRequired,
  updateState: PropTypes.func.isRequired,
};

export default withNavigate(Reviews);
