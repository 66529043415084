import React from 'react';
import $ from 'jquery';
import _ from 'lodash';
import { Link, NavLink } from 'react-router-dom';
import moment from 'moment-timezone';
import TopNav from './TopNav';
import { confirm } from '../modules/Dialogs';
import { longPressTime } from '../config/GeneralConfig';
import parse from 'html-react-parser';
import RichTextInput from './RichTextInput';
import { dateProcess, timeStamp } from '../modules/Generic';
import PropTypes from 'prop-types';

class AuditNotes extends React.Component {

    constructor(props) {

        super(props);

        this.saveText = this.saveText.bind(this);
        this.renderNotes = this.renderNotes.bind(this);
        this.editNote = this.editNote.bind(this);
        this.deleteNote = this.deleteNote.bind(this);

        this.state = {
        }

    }

    saveText(event, theText, theIdentifier, theCallback) {

        if (event) {
            event.preventDefault();
        }

        let tmpNotes = [...this.props.notes];
        let newNote;
        let tmpEdits

        if (theIdentifier) {

            tmpEdits = [...this.props.notes[theIdentifier].edits];
            tmpEdits.unshift({ standardDate: timeStamp() });

            newNote = {
                content: theText,
                edits: tmpEdits
            }

            tmpNotes[theIdentifier] = newNote;

        } else {

            newNote = {
                content: theText,
                edits: [{
                    standardDate: timeStamp()
                }]
            }

            tmpNotes.push(newNote);

        }

        tmpNotes = _.orderBy(tmpNotes, 'edits[0].standardDate', 'desc')
        this.props.updateNotes(tmpNotes, theCallback);

    }


    editNote(event, key) {

        if (event) {
            event.preventDefault();
        }

        this.refs.richTextInput.goRichText(event, key, this.props.notes[key].content)

    }


    deleteNote(event, key) {

        if (event) {
            event.preventDefault();
        }

        confirm('Delete note?', false, 'Cancel', 'Delete', () => {

            let tmpNotes = [...this.props.notes];
            tmpNotes.splice(key, 1);

            this.props.updateNotes(tmpNotes);

        });

    }

    renderNotes(key) {

        const deleteNoteStart = (event) => {
            if (event) {
                event.preventDefault();
            }
            window.deleteNoteTimer = setTimeout(() => this.deleteNote(false, key), longPressTime);
        }

        const deleteNoteCancel = (event) => {
            if (event) {
                event.preventDefault();
            }
            clearTimeout(window.deleteNoteTimer);
        }

        const note = this.props.notes[key];


        return (
            <div className="c-audit-note"
                onTouchStart={(e) => deleteNoteStart(e)}
                onTouchEnd={(e) => deleteNoteCancel(e)}
                onMouseDown={(e) => deleteNoteStart(e)}
                onMouseUp={(e) => deleteNoteCancel(e)}
                onMouseLeave={(e) => deleteNoteCancel(e)}
                onClick={(e) => this.editNote(e, key)}
                key={key}>
                <div className="c-audit-note__date"><span className="u-before-icon-calendar">&nbsp;&nbsp;{dateProcess(note.edits[0].standardDate, "printForAuditNote")}</span></div>
                {parse(note?.content)}
            </div>
        )


    }


    render() {
        return (<div>
            <div>
                <button className="c-btn c-btn--small c-btn--full-width  c-btn--yellow c-btn--black-text u-margin-bottom-0" onClick={(e) => this.refs.richTextInput.goRichText(e)}>Add Note</button>
                <div className="c-hr u-margin-bottom-s u-margin-top-sm"></div>
                <div className="c-audit-note-container">

                    {this.props.notes && _.size(this.props.notes) > 0 ? Object.keys(this.props.notes).map(this.renderNotes) : null}

                </div>
            </div>

            <RichTextInput ref="richTextInput" saveText={this.saveText} />

        </div>

        )
    }



}

AuditNotes.contextTypes = {
    router: PropTypes.object
}

AuditNotes.propTypes = {
    updateNotes: PropTypes.func.isRequired,
    notes: PropTypes.array.isRequired
}


export default AuditNotes;