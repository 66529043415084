/*------------------------------------*\ 
    Dev helper buttons
\*------------------------------------*/
// $(document).ready(function() {
//   if (window.location.href.indexOf('localhost') > -1) {

//       $(".dev-helpers button").on("click", function() {
//           var newClass = $(this).attr('data-class');
//           $('body').toggleClass(newClass);
//       });

//   }
// });
import React from 'react'
import Router from './js/components/Router';

document.addEventListener("DOMContentLoaded", function () {
  if (window.location.href.indexOf('localhost') > -1) {
    const buttons = document.querySelectorAll(".dev-helpers button");

    for (const button of buttons) {
      button.addEventListener("click", function () {
        const newClass = this.dataset.class;
        document.body.classList.toggle(newClass);
      });
    }
  }
});

const initApp = ({ appMode }) => {
  return (
    <Router appMode={appMode} />
  )
}

export default initApp;
// module.exports = {
// initApp:initApp
// }