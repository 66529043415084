import React from "react";
import _ from "lodash";
import $ from "jquery";
import { Link, NavLink } from "react-router-dom";

import TopNav from "./TopNav";
import { getUsers } from "../modules/Admin";
import { showSpinner, closeSpinner, modal, alert } from "../modules/Dialogs";
import { sessionExpired } from "../config/GeneralConfig";
import { toScreen } from "../modules/Routing";
import PropTypes from "prop-types";
import withNavigate from "./withNavigate";

class Clients extends React.Component {
  constructor(props) {
    super(props);

    this.getClients = this.getClients.bind(this);
    this.getClients_done = this.getClients_done.bind(this);
    this.renderClients = this.renderClients.bind(this);
    this.searchClient = this.searchClient.bind(this);
    this.sortClients = this.sortClients.bind(this);
    this.filterClient = this.filterClient.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.nextPage = this.nextPage.bind(this);
    this.prevPage = this.prevPage.bind(this);

    this.state = {
      search: "",
      filter: window.history.state?.usr?.selectedFilter ?? "active",
      sort: '{"accountInfo.username":1}',
      clients: {},
      totals: {
        total: 0,
        preactivation: 0,
        active: 0,
        resetting: 0,
        blocked: 0,
      },
      pagination: {
        page: window.history.state?.usr?.selectedPage ?? 1,
        total: 1,
      },
    };
  }

  nextPage(event) {
    if (event) {
      event.preventDefault();
    }

    const inputChangeCallback = () => {
      this.getClients();
    };

    if (this.state.pagination.page < this.state.pagination.total) {
      this.setState(
        {
          ...this.state,
          pagination: {
            ...this.state.pagination,
            page: this.state.pagination.page + 1,
          },
        },
        inputChangeCallback
      );
    }
  }

  prevPage(event) {
    if (event) {
      event.preventDefault();
    }

    const inputChangeCallback = () => {
      this.getClients();
    };

    if (this.state.pagination.page > 1) {
      this.setState(
        {
          ...this.state,
          pagination: {
            ...this.state.pagination,
            page: this.state.pagination.page - 1,
          },
        },
        inputChangeCallback
      );
    }
  }

  searchClient(event) {
    if (event) {
      event.preventDefault();
    }

    const inputChangeCallback = () => {
      this.getClients();
    };

    this.setState(
      {
        ...this.state,
        filter: "all",
        pagination: {
          page: 1,
          total: 1,
        },
      },
      inputChangeCallback
    );
  }

  sortClients(event, theSort) {
    if (event) {
      event.preventDefault();
    }

    var tmpSort;

    if (theSort == "name") {
      if (this.state.sort === '{"accountInfo.username":1}') {
        tmpSort = '{"accountInfo.username":-1}';
      } else {
        tmpSort = '{"accountInfo.username":1}';
      }
    } else if (theSort == "tel") {
      if (this.state.sort === '{"accountInfo.telephone":1}') {
        tmpSort = '{"accountInfo.telephone":-1}';
      } else {
        tmpSort = '{"accountInfo.telephone":1}';
      }
    } else if (theSort == "email") {
      if (this.state.sort === '{"accountInfo.email":1}') {
        tmpSort = '{"accountInfo.email":-1}';
      } else {
        tmpSort = '{"accountInfo.email":1}';
      }
    } else if (theSort == "jobtotal") {
      if (this.state.sort === '{"jobtotal":1}') {
        tmpSort = '{"jobtotal":-1}';
      } else {
        tmpSort = '{"jobtotal":1}';
      }
    } else if (theSort == "completedjobs") {
      if (this.state.sort === '{"completedjobs":1}') {
        tmpSort = '{"completedjobs":-1}';
      } else {
        tmpSort = '{"completedjobs":1}';
      }
    }

    const inputChangeCallback = () => {
      this.getClients();
    };

    this.setState(
      {
        ...this.state,
        search: "",
        sort: tmpSort,
        pagination: {
          page: 1,
          total: 1,
        },
      },
      inputChangeCallback
    );
  }

  filterClient(event) {
    if (event) {
      event.preventDefault();
    }

    const inputChangeCallback = () => {
      this.getClients();
    };

    this.setState(
      {
        ...this.state,
        search: "",
        pagination: {
          page: 1,
          total: 1,
        },
      },
      inputChangeCallback
    );
  }

  getClients() {
    showSpinner();

    const theUser = {
      accountInfo: {
        username: this.props.userDetails.accountInfo.username,
        email: this.props.userDetails.accountInfo.email,
      },
      userType: "user",
    };

    if (this.state.search && this.state.search != "") {
      theUser.search = this.state.search;
    }

    if (
      this.state.filter &&
      this.state.filter != "" &&
      this.state.filter != "all"
    ) {
      theUser.filter = this.state.filter;
    }

    if (this.state.sort && this.state.sort != "") {
      theUser.sort = this.state.sort;
    }

    if (this.state.pagination.page && this.state.pagination.page != "") {
      theUser.page = this.state.pagination.page;
    }

    if (this.state.totals && this.state.totals != "") {
      theUser.totals = {};
    }

    getUsers(theUser, this.getClients_done, this.props.userDetails.jwttoken);
  }

  getClients_done(resStatus, responseString, resJson) {
    closeSpinner();

    if (resStatus == 401) {
      modal(sessionExpired);
      toScreen(false, this.props.navigate, "/logout");
    } else if (resStatus == "400") {
      this.setState({
        ...this.state,
        clients: {},
        pagination: {
          page: 1,
          total: 1,
        },
      });

      alert(responseString);
    } else if (resStatus == 200) {
      this.setState({
        ...this.state,
        //search:'',
        pagination: {
          ...this.state.pagination,
          total: resJson.pagination.total,
        },
        clients: resJson.theUsers,
        totals: resJson.totals,
      });
    }
  }

  renderClients(key) {
    const client = this.state.clients[key];

    let theJobCount = _.countBy(client.jobs, "status");

    return (
      <div className="c-list-entry" key={key}>
        {/*<div className="c-list-entry__primary">
					<b>{client.accountInfo.firstname} {client.accountInfo.surname}</b> | <a href={"mailto:" + client.accountInfo.email}>{client.accountInfo.email}</a> | <b>{client.short_id}</b> | {client.status}
		</div>*/}

        <div className="c-list-entry__clientname">
          <b>{client.accountInfo.username}</b>
        </div>
        <div className="c-list-entry__tel">{client.accountInfo.telephone}</div>
        <div className="c-list-entry__email">
          <a href={"mailto:" + client.accountInfo.email}>
            {client.accountInfo.email}
          </a>
        </div>
        <div className="c-list-entry__clientjobs">
          <b>{_.size(client.jobs)}</b>
        </div>
        <div className="c-list-entry__clientcompleted">
          <b>{theJobCount.complete || "0"}</b>
        </div>

        <button
          type="button"
          className="c-btn  c-btn--teal c-btn--small"
          onClick={(e) =>
            toScreen(e, this.props.navigate, "/client/" + client.short_id)
          }
        >
          view
        </button>
        <button
          type="button"
          className="c-btn c-btn--green c-btn--small"
          onClick={(e) => {
            toScreen(
              e,
              this.props.navigate,
              "/message/" + client.short_id,
              false,
              {
                receiverId: client?._id,
                receiverShortId: client?.short_id,
                receiverType: client?.userType,
                userShortId1: "admin",
                userShortId2: client?.short_id,
                username2: client?.accountInfo?.username,
                selectedPage: this.state.pagination.page,
                selectedFilter: this.state.filter,
                previousPath: window.location.pathname,
              }
            );
          }}
        >
          Contact Client
        </button>
      </div>
    );
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    var oldValue = false;

    const inputChangeCallback = () => {
      if (name === "filter" && oldValue != value) {
        this.filterClient();
      }
    };

    if (name.split(".").length == 1) {
      oldValue = this.state[name];
      this.setState(
        {
          [name]: value,
        },
        inputChangeCallback
      );
    } else if (name.split(".").length == 2) {
      oldValue = this.state[name.split(".")[0]][name.split(".")[1]];
      this.setState(
        {
          [name.split(".")[0]]: {
            ...this.state[name.split(".")[0]],
            [name.split(".")[1]]: value,
          },
        },
        inputChangeCallback
      );
    } else if (name.split(".").length == 3) {
      oldValue =
        this.state[name.split(".")[0]][name.split(".")[1]][name.split(".")[2]];
      this.setState(
        {
          [name.split(".")[0]]: {
            ...this.state[name.split(".")[0]],
            [name.split(".")[1]]: {
              ...this.state[name.split(".")[0]][name.split(".")[1]],
              [name.split(".")[2]]: value,
            },
          },
        },
        inputChangeCallback
      );
    }
  }

  componentWillMount() {
    //
  }

  componentDidMount() {
    this.getClients();
  }

  componentWillUnmount() {
    //
  }

  render() {
    return (
      <div className="l-base-layout__content">
        <h1>Admin / Clients</h1>

        <div className="c-hr"></div>

        <div className="c-subheader-info-panel">
          <div className="c-subheader-info-panel__primary">
            Total: {this.state.totals.total}
          </div>
          <div className="c-subheader-info-panel__search">
            <form onSubmit={(e) => this.searchClient(e)}>
              <input
                type="text"
                value={this.state.search}
                onChange={this.handleInputChange}
                name="search"
                placeholder=""
                className="c-subheader-info-panel__input"
              />
              <button
                type="submit"
                className="c-btn-outline c-btn-outline--white c-btn-outline--small"
              >
                Search
              </button>
            </form>
          </div>
        </div>

        <div className="c-hr"></div>

        <div className="l-row">
          <div className="l-col-100 u-padding-top-0 u-padding-bottom-0">
            <div className="filter-bar">
              <div className="c-checkbox-radio-block">
                <input
                  type="radio"
                  id="preactivation"
                  name="filter"
                  value="preactivation"
                  onChange={this.handleInputChange}
                  checked={this.state.filter === "preactivation"}
                />
                <label htmlFor="preactivation">
                  Pre Activation ({this.state.totals.preactivation})
                </label>
              </div>
              {/*<div className="c-checkbox-radio-block">
								<input type="radio" id="all" name="filter" value="all" onChange={this.handleInputChange} checked={this.state.filter === 'all'} />
								<label htmlFor="all">All</label>
		</div>*/}
              <div className="c-checkbox-radio-block">
                <input
                  type="radio"
                  id="active"
                  name="filter"
                  value="active"
                  onChange={this.handleInputChange}
                  checked={this.state.filter === "active"}
                />
                <label htmlFor="active">
                  Active ({this.state.totals.active})
                </label>
              </div>
              {/*<div className="c-checkbox-radio-block">
								<input type="radio" id="resetting" name="filter" value="resetting" onChange={this.handleInputChange} checked={this.state.filter === 'resetting'} />
								<label htmlFor="resetting">Resetting ({this.state.totals.resetting})</label>
							</div>*/}
              <div className="c-checkbox-radio-block">
                <input
                  type="radio"
                  id="blocked"
                  name="filter"
                  value="blocked"
                  onChange={this.handleInputChange}
                  checked={this.state.filter === "blocked"}
                />
                <label htmlFor="blocked">
                  Blocked ({this.state.totals.blocked})
                </label>
              </div>
            </div>
          </div>

          <div className="l-col-100">
            <div className="c-pagination">
              <button
                type="button"
                className="c-pagination__prev"
                onClick={(e) => this.prevPage(e)}
              >
                &lt;&lt; Prev
              </button>
              {this.state.pagination.page} / {this.state.pagination.total}
              <button
                type="button"
                className="c-pagination__next"
                onClick={(e) => this.nextPage(e)}
              >
                Next &gt;&gt;
              </button>
            </div>
            <div className="c-hr u-margin-bottom-0"></div>

            <div>
              <div className="c-list-entry c-list-entry__header">
                <div className="c-list-entry__clientname c-list-filter">
                  <button
                    className={
                      this.state.sort == '{"accountInfo.username":-1}'
                        ? "c-list-filter__button c-list-filter__button--icon-up"
                        : this.state.sort == '{"accountInfo.username":1}'
                        ? "c-list-filter__button c-list-filter__button--icon-down"
                        : "c-list-filter__button c-list-filter__button--faded"
                    }
                    onClick={(e) => this.sortClients(e, "name")}
                  >
                    Name
                  </button>
                </div>
                <div className="c-list-entry__tel c-list-filter">
                  <button
                    className={
                      this.state.sort == '{"accountInfo.telephone":-1}'
                        ? "c-list-filter__button c-list-filter__button--icon-up"
                        : this.state.sort == '{"accountInfo.telephone":1}'
                        ? "c-list-filter__button c-list-filter__button--icon-down"
                        : "c-list-filter__button c-list-filter__button--faded"
                    }
                    onClick={(e) => this.sortClients(e, "tel")}
                  >
                    Tel
                  </button>
                </div>
                <div className="c-list-entry__email c-list-filter">
                  <button
                    className={
                      this.state.sort == '{"accountInfo.email":-1}'
                        ? "c-list-filter__button c-list-filter__button--icon-up"
                        : this.state.sort == '{"accountInfo.email":1}'
                        ? "c-list-filter__button c-list-filter__button--icon-down"
                        : "c-list-filter__button c-list-filter__button--faded"
                    }
                    onClick={(e) => this.sortClients(e, "email")}
                  >
                    Email
                  </button>
                </div>
                <div className="c-list-entry__clientjobs c-list-filter">
                  <button
                    className={
                      this.state.sort == '{"jobtotal":-1}'
                        ? "c-list-filter__button c-list-filter__button--icon-up"
                        : this.state.sort == '{"jobtotal":1}'
                        ? "c-list-filter__button c-list-filter__button--icon-down"
                        : "c-list-filter__button c-list-filter__button--faded"
                    }
                    onClick={(e) => this.sortClients(e, "jobtotal")}
                  >
                    Jobs
                  </button>
                </div>
                <div className="c-list-entry__clientcompleted c-list-filter">
                  <button
                    className={
                      this.state.sort == '{"completedjobs":-1}'
                        ? "c-list-filter__button c-list-filter__button--icon-up"
                        : this.state.sort == '{"completedjobs":1}'
                        ? "c-list-filter__button c-list-filter__button--icon-down"
                        : "c-list-filter__button c-list-filter__button--faded"
                    }
                    onClick={(e) => this.sortClients(e, "completedjobs")}
                  >
                    Completed
                  </button>
                </div>
              </div>
            </div>
            <div>{Object.keys(this.state.clients).map(this.renderClients)}</div>
            <div className="c-hr u-margin-bottom-0"></div>
            <div className="c-pagination">
              <button
                type="button"
                className="c-pagination__prev"
                onClick={(e) => this.prevPage(e)}
              >
                &lt;&lt; Prev
              </button>
              {this.state.pagination.page} / {this.state.pagination.total}
              <button
                type="button"
                className="c-pagination__next"
                onClick={(e) => this.nextPage(e)}
              >
                Next &gt;&gt;
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Clients.contextTypes = {
  router: PropTypes.object,
};

Clients.propTypes = {
  userDetails: PropTypes.object.isRequired,
  updateState: PropTypes.func.isRequired,
};

export default withNavigate(Clients);
