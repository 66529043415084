import React from "react";
import $ from "jquery";
import TopNav from "./TopNav";
import { showSpinner, modal, closeSpinner } from "../modules/Dialogs";
import { signInFailTooManyAttempts } from "../config/GeneralConfig";
import { signInUser } from "../modules/Authentication";
import PropTypes from 'prop-types';
import { toScreen } from "../modules/Routing";
import withNavigate from "./withNavigate";
class SignIn extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      otp: "",
      otpSent: false, // Track whether OTP has been sent successfully
    };
    // Bind methods
    this.signInUser_done = this.signInUser_done.bind(this);
    this.signInUser = this.signInUser.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  signInUser(event) {
    event.preventDefault();

    const theUser = {
      email: this.state.email,
      password: this.state.password,
    };
    const theUserWith2FA = {
      email: theUser.email,
      password: theUser.password,
      otp: this.state.otp,
    };

    this.setState({
      email: theUser.email,
      password: theUser.password,
      otp: this.state.otp,
    });

    if (
      (!this.state.otpSent && this.state.email && this.state.password) ||
      (this.state.otpSent && this.state.otp)
    ) {
      showSpinner();
      signInUser(
        !this.state.otpSent ? theUser : theUserWith2FA,
        this.signInUser_done
      );
    } else {
      if (
        !this.otpSent &&
        (this.state.email === "" || this.state.password === "")
      ) {
        modal("Enter email and password");
      } else if (this.state.otpSent && this.state.otp === "") {
        modal("Enter valid OTP");
      } else {
        modal("Something went wrong, Try again!");
      }
    }
  }

  signInUser_done(resStatus, resString, resJson) {
    closeSpinner();

    if (resStatus == "400") {
      modal(resString);
    } else if (resStatus == "429" || resStatus == 429) {
      modal(signInFailTooManyAttempts);
    } else if (resStatus == "200") {
      modal(resString);

      if (this.state.otpSent) {
        const userDetails = { ...this.props.userDetails };
        userDetails.jwttoken = resJson.jwttoken;
        userDetails.accountInfo = { ...resJson.accountInfo };
        userDetails.myJobs = { ...resJson.myJobs };
        userDetails.myQuotes = { ...resJson.myQuotes };
        userDetails.messages = resJson.messages;
        userDetails.userType = resJson.userType;
        userDetails.short_id = resJson.short_id;

        this.props.updateState("userDetails", userDetails);
        toScreen(false, this.props.navigate, "/");
      }
      this.setState({ otpSent: true });
    }
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  render() {
    if (
      this.props.userDetails.jwttoken != null &&
      this.props.userDetails.accountInfo.username != null
    ) {
      return (
        <div className="l-base-layout__content">
          <h1>Admin / Sign In</h1>
          <div className="c-hr"></div>
          <div className="l-row">
            <div className="l-col-66">
              <p>
                You are already signed in as:{" "}
                {this.props.userDetails.accountInfo.username}
              </p>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="l-base-layout__content">
          <h1>Admin / Sign In</h1>
          <div className="c-hr"></div>
          <div className="l-row">
            <div className="l-col-66">
              <form onSubmit={(e) => this.signInUser(e)}>
                {!this.state.otpSent && (
                  <div>
                    <div className="c-input-wide">
                      <label className="c-input-wide__label">Email:</label>
                      <input
                        type="text"
                        value={this.state.email}
                        onChange={this.handleInputChange}
                        name="email"
                        placeholder="Email"
                        className="c-input-wide__input"
                      />
                    </div>

                    <div className="c-input-wide">
                      <label className="c-input-wide__label">Password:</label>
                      <input
                        type="password"
                        value={this.state.password}
                        onChange={this.handleInputChange}
                        name="password"
                        placeholder="Password"
                        className="c-input-wide__input"
                      />
                    </div>
                  </div>
                )}
                {this.state.otpSent && (
                  <div className="c-input-wide">
                    <label className="c-input-wide__label">
                      2FA authentication:
                    </label>
                    <input
                      type="password"
                      value={this.state.otp}
                      onChange={this.handleInputChange}
                      name="otp"
                      placeholder="Auth code"
                      className="c-input-wide__input"
                    />
                  </div>
                )}
              </form>
            </div>
            <div className="l-col-33">
              <button
                className="c-btn c-btn--small c-btn--full-width u-margin-bottom-xs"
                onClick={(e) => this.signInUser(e)}
              >
                SignIn
              </button>
            </div>
          </div>
        </div>
      );
    }
  }
}

SignIn.contextTypes = {
  router: PropTypes.object,
};

SignIn.propTypes = {
  userDetails: PropTypes.object.isRequired,
  updateState: PropTypes.func.isRequired,
};

export default withNavigate(SignIn);
