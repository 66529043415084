// import _ from 'lodash';
import { serverCall } from '../modules/ServerComms';

function getCounties(theCallback) {

	serverCall("POST", '/api/masters/getcounties?apikey=1GPMTGT9ZCNAZPA91BH', {}, function (resStatus, resJson) {

		var responseString;

		if (resStatus == "400" || resStatus == 400) {

			if (resJson.errorString == "nocountiesfound") {
				responseString = "ERROR: NO COUNTIES FOUND";
			} else {
				responseString = "ERROR: UNKNOWN";
			}

			return theCallback(resStatus, responseString, resJson);

		} else if (resStatus == "401" || resStatus == 401) {

			if (resJson.errorString == "unauthorised") {
				responseString = "ERROR: USER NOT AUTHORISED";
			} else {
				responseString = "ERROR: UNKNOWN";
			}

			return theCallback(resStatus, responseString, resJson);

		} else if (resStatus == "200" || resStatus == 200) {

			if (resJson.successString == "countiesfound") {
				responseString = "SUCCESS: COUNTIES FOUND";
			}

			return theCallback(resStatus, responseString, resJson);
		}

	}, null);

}

function getCities(theCounty, theCallback) {

	serverCall("POST", '/api/masters/getcities?apikey=1GPMTGT9ZCNAZPA91BH', { "county": theCounty }, function (resStatus, resJson) {

		var responseString;

		if (resStatus == "400" || resStatus == 400) {

			if (resJson.errorString == "nocitiesfound") {
				responseString = "ERROR: NO CITIES FOUND";
			} else {
				responseString = "ERROR: UNKNOWN";
			}

			return theCallback(resStatus, responseString, resJson);

		} else if (resStatus == "401" || resStatus == 401) {

			if (resJson.errorString == "unauthorised") {
				responseString = "ERROR: USER NOT AUTHORISED";
			} else {
				responseString = "ERROR: UNKNOWN";
			}

			return theCallback(resStatus, responseString, resJson);

		} else if (resStatus == "200" || resStatus == 200) {

			if (resJson.successString == "citiesfound") {
				responseString = "SUCCESS: CITIES FOUND";
			}

			return theCallback(resStatus, responseString, resJson);
		}

	}, null);

}




export {
	getCounties,
	getCities,
}