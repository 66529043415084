

import { serverCall } from '../modules/ServerComms';
import Dialogs from '../modules/Dialogs';

function registerUser(theUser, theCallback) {

	serverCall("POST", '/api/register', theUser, function (resStatus, resJson) {

		var responseString;


		if (resStatus == "400" || resStatus == 400) {

			if (resJson.errorString == "nouserdata") {
				responseString = "ERROR: NO USER DATA";
			} else if (resJson.errorString == "novalidusertype") {
				responseString = "ERROR: NO VALID USER TYPE";
			} else if (resJson.errorString == "novalidfirstname") {
				responseString = "ERROR: NO VALID FIRST NAME";
			} else if (resJson.errorString == "novalidsurname") {
				responseString = "ERROR: NO VALID SURNAME";
			} else if (resJson.errorString == "novaliddob") {
				responseString = "ERROR: NO VALID DOB";
			} else if (resJson.errorString == "userunderage") {
				responseString = "ERROR: USER UNDERAGE";
			} else if (resJson.errorString == "novalidtelephone") {
				responseString = "ERROR: NO VALID TELEPHONE";
			} else if (resJson.errorString == "novalidusername") {
				responseString = "ERROR: NO VALID USERNAME";
			} else if (resJson.errorString == "novaliddrivinglicence") {
				responseString = "ERROR: NO VALID DRIVING LICENCE";
			} else if (resJson.errorString == "novalidcompanyname") {
				responseString = "ERROR: NO VALID COMPANY NAME";
			} else if (resJson.errorString == "novalidvatnumber") {
				responseString = "ERROR: NO VALID VAT NUMBER";
			} else if (resJson.errorString == "novalidwastehandlinglicence") {
				responseString = "ERROR: NO VALID WASTE HANDLING LICENCE";
			} else if (resJson.errorString == "nopublicliabilityinsurance") {
				responseString = "ERROR: NO PUBLIC LIABILITY INSURANCE";
			} else if (resJson.errorString == "nogoodsintransitinsurance") {
				responseString = "ERROR: NO GOODS IN TRANSIT INSURANCE";
			} else if (resJson.errorString == "nogeneralvaninsurance") {
				responseString = "ERROR: NO GENERAL VAN INSURANCE";
			} else if (resJson.errorString == "novalidemail") {
				responseString = "ERROR: NO VALID EMAIL";
			} else if (resJson.errorString == "emailsdontmatch") {
				responseString = "ERROR: EMAILS DONT MATCH";
			} else if (resJson.errorString == "novalidpassword") {
				responseString = "ERROR: NO VALID PASSWORD";
			} else if (resJson.errorString == "termsnotagreed") {
				responseString = "ERROR: TERMS NOT AGREED";
			} else if (resJson.errorString == "userusernameexists") {
				responseString = "ERROR: USER NAME EXISTS";
			} else if (resJson.errorString == "useremailexists") {
				responseString = "ERROR: USER EMAIL EXISTS";
			} else if (resJson.errorString == "usertelexists") {
				responseString = "ERROR: USER TEL EXISTS";
			} else if (resJson.errorString == "usernotsaved") {
				responseString = "ERROR: USER NOT SAVED";
			} else if (resJson.errorString == "preusernotsaved") {
				responseString = "ERROR: PRE USER NOT SAVED";
			} else if (resJson.errorString == "smsnotsent") {
				responseString = "ERROR: SMS NOT SENT";
			} else if (resJson.errorString == "novalidotp") {
				responseString = "ERROR: INVALID CODE";
			} else if (resJson.errorString == "otpfail") {
				responseString = "ERROR: OTP FAIL";
			} else {
				responseString = "ERROR: UNKNOWN";
			}

			return theCallback(resStatus, responseString, resJson);

		} else if (resStatus == "401" || resStatus == 401 || resStatus == "429" || resStatus == 429) {

			if (resJson.errorString == "unauthorised") {
				responseString = "ERROR: USER NOT AUTHORISED";
			} else if (resJson.errorString == "userblocked") {
				responseString = "ERROR: USER BLOCKED";
			} else if (resJson.errorString == "outdatedappversion") {
				responseString = "ERROR: OUTDATED VERSION";
			} else {
				responseString = "ERROR: UNKNOWN";
			}

			return theCallback(resStatus, responseString, resJson);

		} else if (resStatus == "504" || resStatus == 504 || resStatus == "502" || resStatus == 502) {

			responseString = "ERROR: TIMEOUT";
			return theCallback(resStatus, responseString, false);

		} else if (resStatus == "200" || resStatus == 200) {

			if (resJson.successString == "checkemail") {
				responseString = "SUCCESS: CHECK EMAIL";
			} else if (resJson.successString == "regsuccess") {
				responseString = "SUCCESS: SUCCESSFULLY REGISTERED";
			} else if (resJson.successString == "verifytel") {
				responseString = "SUCCESS: VERIFY TEL";
			}

			return theCallback(resStatus, responseString, resJson);
		}



	}, null);

}

function activateUser(theUser, theCallback) {

	serverCall("POST", '/api/activate', theUser, function (resStatus, resJson) {

		var responseString;

		if (resStatus == "400" || resStatus == 400) {

			if (resJson.errorString == "invalidcode") {
				responseString = "ERROR: INVALID CODE";
			} else if (resJson.errorString == "alreadyactive") {
				responseString = "ERROR: ALREADY ACTIVE";
			} else {
				responseString = "ERROR: UNKNOWN";
			}

			return theCallback(resStatus, responseString, resJson);

		} else if (resStatus == "401" || resStatus == 401) {

			if (resJson.errorString == "unauthorised") {
				responseString = "ERROR: USER NOT AUTHORISED";
			} else if (resJson.errorString == "userblocked") {
				responseString = "ERROR: USER BLOCKED";
			} else if (resJson.errorString == "outdatedappversion") {
				responseString = "ERROR: OUTDATED VERSION";
			} else {
				responseString = "ERROR: UNKNOWN";
			}

			return theCallback(resStatus, responseString, resJson);

		} else if (resStatus == "504" || resStatus == 504 || resStatus == "502" || resStatus == 502) {

			responseString = "ERROR: TIMEOUT";
			return theCallback(resStatus, responseString, false);

		} else if (resStatus == "200" || resStatus == 200) {

			if (resJson.successString == "signinuser") {
				responseString = "SUCCESS: SIGN IN";
			}

			return theCallback(resStatus, responseString, resJson);

		}


	}, null);

}

function resendUserActivation(theUser, theCallback, theJWTToken) {

	serverCall("POST", '/api/resend', theUser, function (resStatus, resJson) {

		var responseString;

		if (resStatus == "400" || resStatus == 400) {

			if (resJson.errorString == "invaliduserid") {
				responseString = "ERROR: INVALID USER ID";
			} else if (resJson.errorString == "alreadyactive") {
				responseString = "ERROR: ALREADY ACTIVE";
			} else {
				responseString = "ERROR: UNKNOWN";
			}

			return theCallback(resStatus, responseString, resJson);

		} else if (resStatus == "401" || resStatus == 401) {

			if (resJson.errorString == "unauthorised") {
				responseString = "ERROR: USER NOT AUTHORISED";
			} else if (resJson.errorString == "userblocked") {
				responseString = "ERROR: USER BLOCKED";
			} else if (resJson.errorString == "outdatedappversion") {
				responseString = "ERROR: OUTDATED VERSION";
			} else {
				responseString = "ERROR: UNKNOWN";
			}

			return theCallback(resStatus, responseString, resJson);

		} else if (resStatus == "504" || resStatus == 504 || resStatus == "502" || resStatus == 502) {

			responseString = "ERROR: TIMEOUT";
			return theCallback(resStatus, responseString, false);

		} else if (resStatus == "200" || resStatus == 200) {

			if (resJson.successString == "activationsent") {
				responseString = "SUCCESS: ACTIVATION SENT";
			}

			return theCallback(resStatus, responseString, resJson);

		}


	}, theJWTToken);

}

function signInUser(theUser, theCallback) {

	serverCall("POST", '/api/signin', theUser, function (resStatus, resJson) {

		var responseString;

		if (resStatus == "400" || resStatus == 400) {

			if (resJson.errorString == "nouserdata") {
				responseString = "ERROR: NO USER DATA";
			} else if (resJson.errorString == "novalidusername") {
				responseString = "ERROR: NO VALID USERNAME";
			} else if (resJson.errorString == "novalidemail") {
				responseString = "ERROR: NO VALID EMAIL";
			} else if (resJson.errorString == "novalidtelemail") {
				responseString = "ERROR: NO VALID EMAIL";
			} else if (resJson.errorString == "novalidpassword") {
				responseString = "ERROR: NO VALID PASSWORD";
			} else if (resJson.errorString == "novalidtwofa") {
				responseString = "ERROR: NO VALID 2FA";
			} else if (resJson.errorString == "authfailed") {
				responseString = "ERROR: AUTHORISATION FAILED";
			} else if (resJson.errorString == "usernotsaved") {
				responseString = "ERROR: USER NOT SAVED";
			} else if (resJson.errorString == "usernotactive") {
				responseString = "ERROR: USER NOT ACTIVATED";
			} else if (resJson.errorString == "userblocked") {
				responseString = "ERROR: USER BLOCKED";
			} else if (resJson.errorString == "nojobsfound") {
				responseString = "ERROR: USER JOBS NOT FOUND";
			} else if (resJson.errorString == "usernotadmin") {
				responseString = "ERROR: USER NOT AUTHORISED ADMIN";
			} else if (resJson.errorString == "smsnotsent") {
				responseString = "ERROR: SMS NOT SENT";
			} else if (resJson.errorString == "novalidotp") {
				responseString = "ERROR: INVALID 2FA CODE";
			} else if (resJson.errorString == "otpfail") {
				responseString = "ERROR: OTP FAIL";
			} else {
				responseString = "ERROR: UNKNOWN";
			}

			return theCallback(resStatus, responseString, resJson);

		} else if (resStatus == "401" || resStatus == 401 || resStatus == "429" || resStatus == 429) {

			if (resJson.errorString == "unauthorised") {
				responseString = "ERROR: USER NOT AUTHORISED";
			} else if (resJson.errorString == "userblocked") {
				responseString = "ERROR: USER BLOCKED";
			} else if (resJson.errorString == "outdatedappversion") {
				responseString = "ERROR: OUTDATED VERSION";
			} else {
				responseString = "ERROR: UNKNOWN";
			}

			return theCallback(resStatus, responseString, resJson);

		} else if (resStatus == "504" || resStatus == 504 || resStatus == "502" || resStatus == 502) {

			responseString = "ERROR: TIMEOUT";
			return theCallback(resStatus, responseString, false);

		} else if (resStatus == "200" || resStatus == 200) {

			if (resJson.successString == "signedin") {
        responseString = "SUCCESS: SIGNED IN";
      } else if (resJson.successString == "verifytel") {
        responseString = "SUCCESS: VERIFY TEL";
      } else {
        responseString = resJson.successString || resJson.errorString;
      }

			return theCallback(resStatus, responseString, resJson);
		}


	}, null);

}

function resumeUser(theUser, theCallback, theJWTToken) {

	serverCall("POST", '/api/resume', theUser, function (resStatus, resJson) {

		var responseString;

		if (resStatus == "400" || resStatus == 400) {

			if (resJson.errorString == "invaliduserid") {
				responseString = "ERROR: INVALID USER ID";
			} else {
				responseString = "ERROR: UNKNOWN";
			}

			return theCallback(resStatus, responseString, resJson);

		} else if (resStatus == "401" || resStatus == 401) {

			if (resJson.errorString == "unauthorised") {
				responseString = "ERROR: USER NOT AUTHORISED";
			} else if (resJson.errorString == "userblocked") {
				responseString = "ERROR: USER BLOCKED";
			} else if (resJson.errorString == "outdatedappversion") {
				responseString = "ERROR: OUTDATED VERSION";
			} else {
				responseString = "ERROR: UNKNOWN";
			}

			return theCallback(resStatus, responseString, resJson);

		} else if (resStatus == "504" || resStatus == 504 || resStatus == "502" || resStatus == 502) {

			responseString = "ERROR: TIMEOUT";
			return theCallback(resStatus, responseString, false);

		} else if (resStatus == "200" || resStatus == 200) {

			if (resJson.successString == "userresumed") {
				responseString = "SUCCESS: USERRESUMED";
			}

			return theCallback(resStatus, responseString, resJson);

		}


	}, theJWTToken);

}

function logOutUser(theUser, theCallback, theJWTToken) {

	serverCall("POST", '/api/logout', theUser, function (resStatus, resJson) {

		var responseString;

		if (resStatus == "400" || resStatus == 400) {

			if (resJson.errorString == "nouserdata") {
				responseString = "ERROR: NO USER DATA";
			} else if (resJson.errorString == "unauthorised") {
				responseString = "ERROR: USER NOT AUTHORISED";
			} else {
				responseString = "ERROR: UNKNOWN";
			}

			return theCallback(resStatus, responseString, resJson);

		} else if (resStatus == "401" || resStatus == 401) {

			if (resJson.errorString == "unauthorised") {
				responseString = "ERROR: USER NOT AUTHORISED";
			} else if (resJson.errorString == "userblocked") {
				responseString = "ERROR: USER BLOCKED";
			} else if (resJson.errorString == "outdatedappversion") {
				responseString = "ERROR: OUTDATED VERSION";
			} else {
				responseString = "ERROR: UNKNOWN";
			}

			return theCallback(resStatus, responseString, resJson);

		} else if (resStatus == "504" || resStatus == 504 || resStatus == "502" || resStatus == 502) {

			responseString = "ERROR: TIMEOUT";
			return theCallback(resStatus, responseString, false);

		} else if (resStatus == "200" || resStatus == 200) {

			if (resJson.successString == "loggedout") {
				responseString = "SUCCESS: LOGGED OUT";
			}

			return theCallback(resStatus, responseString, resJson);
		}


	}, theJWTToken, 0.25);

}

function updateUser(theUser, theCallback, theJWTToken) {

	serverCall("POST", '/api/update', theUser, function (resStatus, resJson) {

		var responseString;

		if (resStatus == "400" || resStatus == 400) {

			if (resJson.errorString == "unauthorised") {
				responseString = "ERROR: UNAUTHORISED";
			} else if (resJson.errorString == "nouserdata") {
				responseString = "ERROR: NO USER DATA";
			} else if (resJson.errorString == "nousershortid") {
				responseString = "ERROR: NO USER ID";
			} else if (resJson.errorString == "novalidfirstname") {
				responseString = "ERROR: NO VALID FIRST NAME";
			} else if (resJson.errorString == "novalidsurname") {
				responseString = "ERROR: NO VALID SURNAME";
			} else if (resJson.errorString == "novaliddob") {
				responseString = "ERROR: NO VALID DOB";
			} else if (resJson.errorString == "novalidtelephone") {
				responseString = "ERROR: NO VALID TELEPHONE";
			} else if (resJson.errorString == "novalidpushnotificaions") {
				responseString = "ERROR: NO VALID PUSH NOTIFICATIONS SETTING";
			} else if (resJson.errorString == "novalidemailnotifications") {
				responseString = "ERROR: NO VALID EMAIL NOTIFICATIONS SETTING";
			} else if (resJson.errorString == "novalidmarketingnotifications") {
				responseString = "ERROR: NO VALID EMAIL NOTIFICATIONS SETTING";
			} else if (resJson.errorString == "novalidholidaymode") {
				responseString = "ERROR: NO VALID HOLIDAY MODE SETTING";
			} else if (resJson.errorString == "novalidbaselocation") {
				responseString = "ERROR: NO VALID BASE LOCATION";
			} else if (resJson.errorString == "userunderage") {
				responseString = "ERROR: USER UNDERAGE";
			} else if (resJson.errorString == "novalidusername") {
				responseString = "ERROR: NO VALID USERNAME";
			} else if (resJson.errorString == "novaliddrivinglicence") {
				responseString = "ERROR: NO VALID DRIVING LICENCE";
			} else if (resJson.errorString == "novalidemail") {
				responseString = "ERROR: NO VALID EMAIL";
			} else if (resJson.errorString == "novalidpassword") {
				responseString = "ERROR: NO VALID PASSWORD";
			} else if (resJson.errorString == "termsnotagreed") {
				responseString = "ERROR: TERMS NOT AGREED";
			} else if (resJson.errorString == "userusernameexists") {
				responseString = "ERROR: USER NAME EXISTS";
			} else if (resJson.errorString == "useremailexists") {
				responseString = "ERROR: USER EMAIL EXISTS";
			} else if (resJson.errorString == "nouserfound") {
				responseString = "ERROR: USER NOT FOUND";
			} else if (resJson.errorString == "userinactive") {
				responseString = "ERROR: USER INACTIVE";
			} else if (resJson.errorString == "usernotsaved") {
				responseString = "ERROR: USER NOT SAVED";
			} else if (resJson.errorString == "smsnotsent") {
				responseString = "ERROR: SMS NOT SENT";
			} else if (resJson.errorString == "usertelexists") {
				responseString = "ERROR: USER TEL EXISTS";
			} else if (resJson.errorString == "otpfail") {
				responseString = "ERROR: OTP FAIL";
			} else {
				responseString = "ERROR: UNKNOWN";
			}

			return theCallback(resStatus, responseString, resJson);

		} else if (resStatus == "401" || resStatus == 401) {

			if (resJson.errorString == "unauthorised") {
				responseString = "ERROR: USER NOT AUTHORISED";
			} else if (resJson.errorString == "userblocked") {
				responseString = "ERROR: USER BLOCKED";
			} else if (resJson.errorString == "outdatedappversion") {
				responseString = "ERROR: OUTDATED VERSION";
			} else {
				responseString = "ERROR: UNKNOWN";
			}

			return theCallback(resStatus, responseString, resJson);

		} else if (resStatus == "504" || resStatus == 504 || resStatus == "502" || resStatus == 502) {

			responseString = "ERROR: TIMEOUT";
			return theCallback(resStatus, responseString, false);

		} else if (resStatus == "200" || resStatus == 200) {

			if (resJson.successString == "userupdated") {
				responseString = "SUCCESS: USER UPDATED";
			} else if (resJson.successString == "verifytel") {
				responseString = "SUCCESS: VERIFY TEL";
			}

			return theCallback(resStatus, responseString, resJson);
		}



	}, theJWTToken);

}


function forgotUser(theUser, theCallback) {

	serverCall("POST", '/api/forgot', theUser, function (resStatus, resJson) {

		var responseString;

		if (resStatus == "400" || resStatus == 400) {

			if (resJson.errorString == "nouserdata") {
				responseString = "ERROR: NO USER DATA";
			} else if (resJson.errorString == "novalidtelemail") {
				responseString = "ERROR: NO VALID TELEMAIL";
			} else if (resJson.errorString == "novalidotp") {
				responseString = "ERROR: INVALID CODE";
			} else if (resJson.errorString == "incorrectotp") {
				responseString = "ERROR: INCORRECT CODE";
			} else if (resJson.errorString == "expiredotp") {
				responseString = "ERROR: EXPIRED CODE";
			} else if (resJson.errorString == "nouserwiththatemail") {
				responseString = "ERROR: NO USER WITH THAT EMAIL";
			} else if (resJson.errorString == "nouserwiththattel") {
				responseString = "ERROR: NO USER WITH THAT TEL";
			} else if (resJson.errorString == "resetcodeexists") {
				responseString = "ERROR: RESET CODE EXISTS(IMPOSSIBLE!)";
			} else if (resJson.errorString == "smsnotsent") {
				responseString = "ERROR: SMS NOT SENT";
			} else if (resJson.errorString == "otpfail") {
				responseString = "ERROR: OTP FAIL";
			} else {
				responseString = "ERROR: UNKNOWN";
			}

			return theCallback(resStatus, responseString, resJson);

		} else if (resStatus == "401" || resStatus == 401 || resStatus == "429" || resStatus == 429) {

			if (resJson.errorString == "unauthorised") {
				responseString = "ERROR: USER NOT AUTHORISED";
			} else if (resJson.errorString == "userblocked") {
				responseString = "ERROR: USER BLOCKED";
			} else if (resJson.errorString == "outdatedappversion") {
				responseString = "ERROR: OUTDATED VERSION";
			} else {
				responseString = "ERROR: UNKNOWN";
			}

			return theCallback(resStatus, responseString, resJson);

		} else if (resStatus == "504" || resStatus == 504 || resStatus == "502" || resStatus == 502) {

			responseString = "ERROR: TIMEOUT";
			return theCallback(resStatus, responseString, false);

		} else if (resStatus == "200" || resStatus == 200) {

			if (resJson.successString == "emailsent") {
				responseString = "SUCCESS: CHECK YOUR EMAIL";
			} else if (resJson.successString == "otpgenerated") {
				responseString = "SUCCESS: INPUT OTP";
			} else if (resJson.successString == "resetpassword") {
				responseString = "SUCCESS: RESET PW";
			}

			return theCallback(resStatus, responseString, resJson);
		}


	}, null);

}

function resetUser(theUser, theCallback) {

	serverCall("POST", '/api/reset', theUser, function (resStatus, resJson) {

		var responseString;

		if (resStatus == "400" || resStatus == 400) {

			if (resJson.errorString == "nouserdata") {
				responseString = "ERROR: NO USER DATA";
			} else if (resJson.errorString == "novalidpassword") {
				responseString = "ERROR: NO VALID PASSWORD";
			} else if (resJson.errorString == "invalidcode") {
				responseString = "ERROR: INVALID CODE";
			} else {
				responseString = "ERROR: UNKNOWN";
			}

			return theCallback(resStatus, responseString, resJson);

		} else if (resStatus == "401" || resStatus == 401) {

			if (resJson.errorString == "unauthorised") {
				responseString = "ERROR: USER NOT AUTHORISED";
			} else if (resJson.errorString == "userblocked") {
				responseString = "ERROR: USER BLOCKED";
			} else if (resJson.errorString == "outdatedappversion") {
				responseString = "ERROR: OUTDATED VERSION";
			} else {
				responseString = "ERROR: UNKNOWN";
			}

			return theCallback(resStatus, responseString, resJson);

		} else if (resStatus == "504" || resStatus == 504 || resStatus == "502" || resStatus == 502) {

			responseString = "ERROR: TIMEOUT";
			return theCallback(resStatus, responseString, false);

		} else if (resStatus == "200" || resStatus == 200) {

			if (resJson.successString == "passwordchanged") {
				responseString = "SUCCESS: SIGN IN";
			}

			return theCallback(resStatus, responseString, resJson);
		}


	}, null);

}

function userApplyForMav(theUser, theCallback, theJWTToken) {

	serverCall("POST", '/api/applyformav', theUser, function (resStatus, resJson) {

		var responseString;

		if (resStatus == "400" || resStatus == 400) {

			if (resJson.errorString == "nouserdata") {
				responseString = "ERROR: NO USER DATA";
			} else if (resJson.errorString == "nouserfound") {
				responseString = "ERROR: NO USER FOUND";
			} else if (resJson.errorString == "userblocked") {
				responseString = "ERROR: USER BLOCKED";
			} else if (resJson.errorString == "usernotactive") {
				responseString = "ERROR: USER NOT ACTIVE";
			} else if (resJson.errorString == "useralreadymav") {
				responseString = "ERROR: USER ALREADY MAV";
			} else if (resJson.errorString == "userisadmin") {
				responseString = "ERROR: USER IS ADMIN";
			} else if (resJson.errorString == "useralreadyapplied") {
				responseString = "ERROR: USER ALREADY APPLIED";
			} else if (resJson.errorString == "unauthorised") {
				responseString = "ERROR: USER NOT AUTHORISED";
			} else if (resJson.errorString == "usernotsaved") {
				responseString = "ERROR: USER NOT SAVED";
			} else {
				responseString = "ERROR: UNKNOWN";
			}

			return theCallback(resStatus, responseString, resJson);

		} else if (resStatus == "401" || resStatus == 401) {

			if (resJson.errorString == "unauthorised") {
				responseString = "ERROR: USER NOT AUTHORISED";
			} else if (resJson.errorString == "userblocked") {
				responseString = "ERROR: USER BLOCKED";
			} else if (resJson.errorString == "outdatedappversion") {
				responseString = "ERROR: OUTDATED VERSION";
			} else {
				responseString = "ERROR: UNKNOWN";
			}

			return theCallback(resStatus, responseString, resJson);

		} else if (resStatus == "504" || resStatus == 504 || resStatus == "502" || resStatus == 502) {

			responseString = "ERROR: TIMEOUT";
			return theCallback(resStatus, responseString, false);

		} else if (resStatus == "200" || resStatus == 200) {

			if (resJson.successString == "appliedsuccessfully") {
				responseString = "SUCCESS: USER APPLIED TO BE MAV";
			}

			return theCallback(resStatus, responseString, resJson);
		}


	}, theJWTToken);

}

export {
	registerUser,
	updateUser,
	activateUser,
	resendUserActivation,
	signInUser,
	resumeUser,
	logOutUser,
	forgotUser,
	resetUser,
	userApplyForMav
}