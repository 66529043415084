import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

class TopNav extends React.Component{
	constructor(){
		super();
	}

	render(){
		return (
				<div className="c-top-nav">
					<div className="l-content-width">
						<button className="c-top-nav__back-btn c-btn-outline c-btn-outline--white c-btn-outline--v-small" onClick={() => this.context.router.history.goBack()}> 
							&lt; Back
						</button>
						<h1>{this.props.title}</h1>

						{this.props.forwardRoute ? (
							<Link className="c-top-nav__forward-btn c-btn c-btn--white c-btn-outline--v-small" to={this.props.forwardRoute}> 
								Next &gt;
							</Link>
						): null }
					</div>

				</div>
			);
	}
}

TopNav.contextTypes = {
	router: PropTypes.object
}


export default TopNav;