import React from 'react';
import $ from 'jquery';
import _ from 'lodash';
import { Link, NavLink } from 'react-router-dom';
import moment from 'moment-timezone';
import TopNav from './TopNav';
import { getUsers } from '../modules/Admin';
import { modal, alert } from '../modules/Dialogs';
import { sessionExpired, adminUrl } from '../config/GeneralConfig';
import { toScreen } from '../modules/Routing';
import PropTypes from 'prop-types';
import withNavigate from './withNavigate';
class MavSelectComponent extends React.Component {

    constructor(props) {

        super(props);

        this.goMavSelect = this.goMavSelect.bind(this);
        this.mavSelect_close = this.mavSelect_close.bind(this);

        this.getMavs = this.getMavs.bind(this);
        this.getMavs_done = this.getMavs_done.bind(this);
        this.renderMavs = this.renderMavs.bind(this);
        this.searchMav = this.searchMav.bind(this);
        this.sortMavs = this.sortMavs.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.nextPage = this.nextPage.bind(this);
        this.prevPage = this.prevPage.bind(this);

        this.state = {
            isVisible: false,
            search: '',
            filter: 'active',
            sort: '{"accountInfo.username":1}',
            mavs: {},
            totals: {
                total: 0,
                preactivation: 0,
                active: 0,
                resetting: 0,
                blocked: 0
            },
            pagination: {
                page: 1,
                total: 1
            }
        }

    }

    goMavSelect(event) {

        if (event) {
            event.preventDefault();
        }

        document.closeModalExtra = (e) => {
            this.mavSelect_close(false);
        };

        this.setState({
            ...this.state,
            isVisible: true
        }
            , () => {
                this.getMavs();
            });

    }

    mavSelect_close(event) {

        if (event) {
            event.preventDefault();
        }

        this.setState({
            ...this.state,
            isVisible: false
        }
        );

    }

    nextPage(event) {

        if (event) {
            event.preventDefault();
        }

        const inputChangeCallback = () => {
            this.getMavs();
        }

        if (this.state.pagination.page < this.state.pagination.total) {
            this.setState({
                ...this.state,
                pagination: {
                    ...this.state.pagination,
                    page: this.state.pagination.page + 1
                }
            }, inputChangeCallback);
        }

    }

    prevPage(event) {

        if (event) {
            event.preventDefault();
        }

        const inputChangeCallback = () => {
            this.getMavs();
        }

        if (this.state.pagination.page > 1) {
            this.setState({
                ...this.state,
                pagination: {
                    ...this.state.pagination,
                    page: this.state.pagination.page - 1
                }
            }, inputChangeCallback);
        }

    }

    searchMav(event) {

        if (event) {
            event.preventDefault();
        }

        const inputChangeCallback = () => {
            this.getMavs();
        }

        this.setState({
            ...this.state,
            filter: 'active',
            pagination: {
                page: 1,
                total: 1
            }
        }, inputChangeCallback);

    }

    sortMavs(event, theSort) {

        if (event) {
            event.preventDefault();
        }

        var tmpSort;

        if (theSort == 'name') {
            if (this.state.sort === '{"accountInfo.username":1}') {
                tmpSort = '{"accountInfo.username":-1}';
            } else {
                tmpSort = '{"accountInfo.username":1}';
            }
        } else if (theSort == 'tel') {
            if (this.state.sort === '{"accountInfo.telephone":1}') {
                tmpSort = '{"accountInfo.telephone":-1}';
            } else {
                tmpSort = '{"accountInfo.telephone":1}';
            }
        } else if (theSort == 'location') {
            if (this.state.sort === '{"accountInfo.baseLocation.homeaddress.formattedAddress":1}') {
                tmpSort = '{"accountInfo.baseLocation.homeaddress.formattedAddress":-1}';
            } else {
                tmpSort = '{"accountInfo.baseLocation.homeaddress.formattedAddress":1}';
            }
        } else if (theSort == 'quotetotal') {
            if (this.state.sort === '{"quotetotal":1}') {
                tmpSort = '{"quotetotal":-1}';
            } else {
                tmpSort = '{"quotetotal":1}';
            }
        } else if (theSort == 'successfulquotes') {
            if (this.state.sort === '{"successfulquotes":1}') {
                tmpSort = '{"successfulquotes":-1}';
            } else {
                tmpSort = '{"successfulquotes":1}';
            }
        } else if (theSort == 'reviews') {
            if (this.state.sort === '{"totalRatings":1}') {
                tmpSort = '{"totalRatings":-1}';
            } else {
                tmpSort = '{"totalRatings":1}';
            }
        }

        const inputChangeCallback = () => {
            this.getMavs();
        }

        this.setState({
            ...this.state,
            search: '',
            sort: tmpSort,
            pagination: {
                page: 1,
                total: 1
            }
        }, inputChangeCallback);


    }

    getMavs(event) {
        
        if (event) {
            event.preventDefault();
        }

        const theUser = {
            accountInfo: {
                username: this.props.userDetails.accountInfo.username,
                email: this.props.userDetails.accountInfo.email
            },
            userType: "mav"
        }

        if (this.state.search && this.state.search != '') {
            theUser.search = this.state.search;
        }

        if (this.state.filter && this.state.filter != '' && this.state.filter != 'all') {
            theUser.filter = this.state.filter;
        }

        if (this.state.sort && this.state.sort != '') {
            theUser.sort = this.state.sort;
        }

        if (this.state.pagination.page && this.state.pagination.page != '') {
            theUser.page = this.state.pagination.page;
        }

        if (this.state.totals && this.state.totals != '') {
            theUser.totals = {};
        }

        getUsers(theUser, this.getMavs_done, this.props.userDetails.jwttoken);

    }

    getMavs_done(resStatus, responseString, resJson) {

        if (resStatus == 401) {

            modal(sessionExpired);
            toScreen(false, this.props.navigate, '/logout');

        } else if (resStatus == "400") {

            this.setState({
                ...this.state,
                mavs: {},
                pagination: {
                    page: 1,
                    total: 1
                }
            });

            alert(responseString);

        } else if (resStatus == 200) {

            var statusCount = _.groupBy(resJson.theUsers, "status");

            this.setState({
                ...this.state,
                //search:'',
                pagination: {
                    ...this.state.pagination,
                    total: resJson.pagination.total
                },
                mavs: resJson.theUsers,
                totals: resJson.totals
            });

        }

    }

    renderMavs(key) {

        const mav = this.state.mavs[key];

        let theQuoteCount = _.countBy(mav.quotes, 'status');

        return (
            <div className={this.props.currentMav != mav.short_id ? "c-list-entry" : "c-list-entry u-color-grey-dark"} key={key}>
                {/*<div className="c-list-entry__primary">
					<b>{mav.accountInfo.username}</b> | <a href={"mailto:" + mav.accountInfo.email}>{mav.accountInfo.email}</a> | <b>{mav.short_id}</b> | {mav.status}
		</div>*/}

                <div className="c-list-entry__mavname"><b>{mav.accountInfo.username}</b></div>
                <div className="c-list-entry__tel">{mav.accountInfo.telephone}</div>
                <div className="c-list-entry__mavlocation">{mav.accountInfo.baseLocation && mav.accountInfo.baseLocation.homeaddress && mav.accountInfo.baseLocation.homeaddress.formattedAddress ? mav.accountInfo.baseLocation.homeaddress.formattedAddress.split(', United Kingdom')[0] : '(Not set)'}</div>
                <div className="c-list-entry__mavquotes"><b>{_.size(mav.quotes)}</b></div>
                <div className="c-list-entry__mavjobs"><b>{theQuoteCount.complete || 0 + theQuoteCount.paid || 0 + theQuoteCount.agreed || 0}</b></div>
                <div className="c-list-entry__reviews"><b>{mav.totalRatings}</b></div>
                <a href={adminUrl + '/mav/' + mav.short_id} target="_blank"><button type="button" className={this.props.currentMav != mav.short_id ? "c-btn c-btn--teal c-btn--small" : "c-btn c-btn--grey-dark c-btn--small"} >View</button></a>
                <button type="button" className={this.props.currentMav != mav.short_id ? "c-btn c-btn--green c-btn--small" : "c-btn c-btn--grey c-btn--small"} onClick={this.props.currentMav != mav.short_id ? (e) => this.props.selectMav(e, mav.short_id, mav.accountInfo.username) : null}>Select MAV</button>
            </div>
        )
    }

    handleInputChange(event) {

        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        var oldValue = false;

        const inputChangeCallback = () => {
        }

        if (name.split(".").length == 1) {
            oldValue = this.state[name];
            this.setState({
                [name]: value
            }, inputChangeCallback);

        } else if (name.split(".").length == 2) {
            oldValue = this.state[name.split(".")[0]][name.split(".")[1]];
            this.setState({
                [name.split(".")[0]]: {
                    ...this.state[name.split(".")[0]],
                    [name.split(".")[1]]: value
                },
            }, inputChangeCallback);

        } else if (name.split(".").length == 3) {
            oldValue = this.state[name.split(".")[0]][name.split(".")[1]][name.split(".")[2]];
            this.setState({
                [name.split(".")[0]]: {
                    ...this.state[name.split(".")[0]],
                    [name.split(".")[1]]: {
                        ...this.state[name.split(".")[0]][name.split(".")[1]],
                        [name.split(".")[2]]: value
                    }
                },
            }, inputChangeCallback);

        }

    }

    render() {
        return (
            <div>

                <div className={this.state.isVisible ? "c-modal c-modal--visible" : "c-modal"}>
                    <div className="c-modal__bg js-close-modal"></div>
                    <div className="c-modal__inner c-modal__inner-mav-select">

                        <div className="c-subheader-info-panel">
                            <div className="c-subheader-info-panel__primary">
                                Total active: {this.state.totals.active}
                            </div>
                            <div className="c-subheader-info-panel__search">
                                <form onSubmit={(e) => this.searchMav(e)}>
                                    <input type="text" value={this.state.search} onChange={this.handleInputChange} name="search" placeholder="" className="c-subheader-info-panel__input" />
                                    <button type="submit" className="c-btn-outline c-btn-outline--white c-btn-outline--small">Search</button>
                                </form>
                            </div>
                        </div>

                        <div className="l-row">

                            <div className="l-col-100">
                                <div className="c-pagination">
                                    <button type="button" className="c-pagination__prev" onClick={(e) => this.prevPage(e)}>
                                        &lt;&lt; Prev
        </button>
                                    {this.state.pagination.page} / {this.state.pagination.total}
                                    <button type="button" className="c-pagination__next" onClick={(e) => this.nextPage(e)}>
                                        Next &gt;&gt;
        </button>
                                </div>
                                <div className="c-hr u-margin-bottom-0"></div>

                                <div>
                                    <div className="c-list-entry c-list-entry__header">
                                        <div className="c-list-entry__mavname c-list-filter"><button className={this.state.sort == '{"accountInfo.username":-1}' ? "c-list-filter__button c-list-filter__button--icon-up" : this.state.sort == '{"accountInfo.username":1}' ? "c-list-filter__button c-list-filter__button--icon-down" : "c-list-filter__button c-list-filter__button--faded"} onClick={(e) => this.sortMavs(e, 'name')}>Name</button></div>
                                        <div className="c-list-entry__tel c-list-filter"><button className={this.state.sort == '{"accountInfo.telephone":-1}' ? "c-list-filter__button c-list-filter__button--icon-up" : this.state.sort == '{"accountInfo.telephone":1}' ? "c-list-filter__button c-list-filter__button--icon-down" : "c-list-filter__button c-list-filter__button--faded"} onClick={(e) => this.sortMavs(e, 'tel')}>Tel</button></div>
                                        <div className="c-list-entry__mavlocation c-list-filter"><button className={this.state.sort == '{"accountInfo.baseLocation.homeaddress.formattedAddress":-1}' ? "c-list-filter__button c-list-filter__button--icon-up" : this.state.sort == '{"accountInfo.baseLocation.homeaddress.formattedAddress":1}' ? "c-list-filter__button c-list-filter__button--icon-down" : "c-list-filter__button c-list-filter__button--faded"} onClick={(e) => this.sortMavs(e, 'location')}>Location</button></div>
                                        <div className="c-list-entry__mavquotes c-list-filter"><button className={this.state.sort == '{"quotetotal":-1}' ? "c-list-filter__button c-list-filter__button--icon-up" : this.state.sort == '{"quotetotal":1}' ? "c-list-filter__button c-list-filter__button--icon-down" : "c-list-filter__button c-list-filter__button--faded"} onClick={(e) => this.sortMavs(e, 'quotetotal')}>Quotes</button></div>
                                        <div className="c-list-entry__mavjobs c-list-filter"><button className={this.state.sort == '{"successfulquotes":-1}' ? "c-list-filter__button c-list-filter__button--icon-up" : this.state.sort == '{"successfulquotes":1}' ? "c-list-filter__button c-list-filter__button--icon-down" : "c-list-filter__button c-list-filter__button--faded"} onClick={(e) => this.sortMavs(e, 'successfulquotes')}>Jobs</button></div>
                                        <div className="c-list-entry__reviews c-list-filter"><button className={this.state.sort == '{"totalRatings":-1}' ? "c-list-filter__button c-list-filter__button--icon-up" : this.state.sort == '{"totalRatings":1}' ? "c-list-filter__button c-list-filter__button--icon-down" : "c-list-filter__button c-list-filter__button--faded"} onClick={(e) => this.sortMavs(e, 'reviews')}>Reviews</button></div>
                                    </div>
                                </div>

                                <div>
                                    {Object.keys(this.state.mavs).map(this.renderMavs)}
                                </div>
                                <div className="c-hr u-margin-bottom-0"></div>
                                <div className="c-pagination">
                                    <button type="button" className="c-pagination__prev" onClick={(e) => this.prevPage(e)}>
                                        &lt;&lt; Prev
        </button>
                                    {this.state.pagination.page} / {this.state.pagination.total}
                                    <button type="button" className="c-pagination__next" onClick={(e) => this.nextPage(e)}>
                                        Next &gt;&gt;
        </button>
                                </div>
                            </div>



                        </div>





                    </div>
                </div>

            </div>

        )
    }



}

MavSelectComponent.contextTypes = {
    router: PropTypes.object
}

MavSelectComponent.propTypes = {
    userDetails: PropTypes.object.isRequired,
    currentMav: PropTypes.string.isRequired,
    selectMav: PropTypes.func.isRequired
}


export default withNavigate(MavSelectComponent);