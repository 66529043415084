import React from 'react';
import _ from 'lodash';

import { Editor, EditorState, RichUtils, ContentState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { stateFromHTML } from 'draft-js-import-html';
import PropTypes from 'prop-types';
class RichTextInput extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            identifier: false,
            isVisible: false,
            editorState: EditorState.createEmpty()
        };
        this.onChange = editorState => this.setState({ editorState });
        this.onFocus = () => { this.refs.editor.focus(); }

        this.goRichText = this.goRichText.bind(this);
        this.richText_close = this.richText_close.bind(this);
        this.richText_save = this.richText_save.bind(this);
        this.richText_discard = this.richText_discard.bind(this);

    }

    goRichText(event, theIdentifier, theHTML) {

        if (event) {
            event.preventDefault();
        }

        const goRichTextNow = () => {

            this.setState({
                ...this.state,
                identifier: theIdentifier,
                isVisible: true
            }
                , () => {
                    this.refs.editor.focus();
                });

        }

        document.closeModalExtra = (e) => {
            this.richText_close(false);
        };

        if (theHTML) {

            let contentState = stateFromHTML(theHTML);

            const editorState = EditorState.createWithContent(contentState);
            this.setState({
                ...this.state,
                isVisible: false,
                editorState: editorState
            }, goRichTextNow);

        } else {
            const editorState = EditorState.push(this.state.editorState, ContentState.createFromText(''));
            this.setState({
                ...this.state,
                identifier: false,
                isVisible: false,
                editorState: editorState
            }, goRichTextNow);
        }


    }

    richText_close(event) {

        if (event) {
            event.preventDefault();
        }

        this.setState({
            ...this.state,
            identifier: false,
            isVisible: false
        }
        );

    }

    richText_discard(event) {

        if (event) {
            event.preventDefault();
        }

        const editorState = EditorState.push(this.state.editorState, ContentState.createFromText(''));
        this.setState({
            ...this.state,
            identifier: false,
            isVisible: false,
            editorState: editorState
        });

    }

    richText_save(event) {

        if (event) {
            event.preventDefault();
        }

        let html = stateToHTML(this.state.editorState.getCurrentContent());

        if (html == '<p><br></p>') {
            this.richText_discard(false)
        } else {
            this.props.saveText(false, html, this.state.identifier, () => { this.richText_discard(false) });
        }

    }

    richTextButton = (event, theStyle) => {

        if (event) {
            event.preventDefault();
        }
        this.onChange(RichUtils.toggleInlineStyle(this.state.editorState, theStyle));
    }



    render() {


        let currentStyle = this.state.editorState.getCurrentInlineStyle();

        const styleMap = {
            'CODE': {
                backgroundColor: '#ffcc6d'
            }
        };

        return (
            <div className={this.state.isVisible ? "c-modal c-modal--visible" : "c-modal"}>
                <div className="c-modal__bg js-close-modal"></div>
                <div className="c-modal__inner ">
                    <div className="u-text-align-left">
                        <button className={currentStyle && currentStyle.has('BOLD') ? "c-btn c-btn--white c-btn--v-small-alt" : "c-btn c-btn--grey-mdark c-btn--v-small-alt"} onMouseDown={(e) => { this.richTextButton(e, 'BOLD') }} onClick={(e) => { e.preventDefault() }}>Bold</button>
                        <button className={currentStyle && currentStyle.has('ITALIC') ? "c-btn c-btn--white c-btn--v-small-alt" : "c-btn c-btn--grey-mdark c-btn--v-small-alt"} onMouseDown={(e) => { this.richTextButton(e, 'ITALIC') }} onClick={(e) => { e.preventDefault() }}><i>Italic</i></button>
                        <button className={currentStyle && currentStyle.has('STRIKETHROUGH') ? "c-btn c-btn--white c-btn--v-small-alt u-text-strikethrough" : "c-btn c-btn--grey-mdark c-btn--v-small-alt u-text-strikethrough"} onMouseDown={(e) => { this.richTextButton(e, 'STRIKETHROUGH') }} onClick={(e) => { e.preventDefault() }}>Strikethrough</button>
                        <button className={currentStyle && currentStyle.has('UNDERLINE') ? "c-btn c-btn--white c-btn--v-small-alt u-text-underline" : "c-btn c-btn--grey-mdark c-btn--v-small-alt u-text-underline"} onMouseDown={(e) => { this.richTextButton(e, 'UNDERLINE') }} onClick={(e) => { e.preventDefault() }}>Underline</button>
                        <button className={currentStyle && currentStyle.has('CODE') ? "c-btn c-btn--white c-btn--v-small-alt" : "c-btn c-btn--grey-mdark c-btn--v-small-alt"} onMouseDown={(e) => { this.richTextButton(e, 'CODE') }} onClick={(e) => { e.preventDefault() }}><span className={currentStyle.has('CODE') ? "u-color-grey-xlight-bg" : "u-color-grey-xdark-bg"}>Highlight</span></button>
                    </div>
                    <div className="c-rich-text-input" onClick={this.onFocus}>
                        <Editor editorState={this.state.editorState} onChange={this.onChange} customStyleMap={styleMap} ref="editor" />
                    </div>

                    <button className="c-btn c-btn--small c-btn--grey u-margin-bottom-0 u-margin-top-m" onClick={this.richText_discard}>Discard</button><button className="c-btn c-btn--small c-btn--green u-margin-right-0 u-margin-bottom-0 u-margin-top-m" onClick={this.richText_save}>Save</button>

                </div>
            </div >


        )
    }



}

RichTextInput.contextTypes = {
    router: PropTypes.object
}

RichTextInput.propTypes = {
    saveText: PropTypes.func.isRequired
}


export default RichTextInput;