
import React from 'react';

class Dropdown extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            selectedOption: ''
        };
        this.toggleOpen = this.toggleOpen.bind(this);
        this.selectOption = this.selectOption.bind(this);
    }

    toggleOpen() {
        this.setState(prevState => ({ isOpen: !prevState.isOpen }));
    }

    selectOption(option) {
        this.setState({
            selectedOption: option,
            isOpen: false // Close dropdown after selection
        });
    }

    renderOptionList(options) {
        const { selectedOption } = this.state;

        return (

            <div className='sub-title-dropdown'>
                <ul>
                    {options.map(option => {
                        // Check if it's a group of options
                        if (option.options) {
                            return (
                                <li className="sub-menu" key={option.label}>
                                    <span className='label'>{option.label}</span>
                                    {this.renderOptionList(option.options)} {/* Render sub-options */}
                                </li>
                            );
                        }
                        return (
                            <li className={`${selectedOption === option.label && "seleted"}`} key={option.value} onClick={() => this.selectOption(option.label)}>
                                {option.label}
                            </li>
                        );
                    })}
                </ul>
            </div>
        );
    }

    render() {
        const { isOpen, selectedOption } = this.state;

        const dropdownOptions = [
            { label: 'Homescreen', value: 'homescreen' },
            {
                label: 'Jobs',
                options: [
                    { label: 'New Jobs', value: 'newJobs' },
                    { label: 'Quoted', value: 'quoted' },
                    { label: 'Dismissed', value: 'dismissed' }
                ]
            },
            {
                label: 'Quotes',
                options: [
                    { label: 'Pending', value: 'pending' },
                    { label: 'Accepted', value: 'accepted' },
                    { label: 'Completed', value: 'quotesCompleted' },
                    { label: 'Archived', value: 'archived' }
                ]
            },
            {
                label: 'Bookings',
                options: [
                    { label: 'Upcoming', value: 'upcomingBookings' },
                    { label: 'Completed', value: 'bookingsCompleted' }
                ]
            },
            {
                label: 'Messages',
                options: [
                    { label: 'Messages', value: 'messages' },
                ]
            },
            {
                label: 'Account',
                options: [
                    { label: 'Edit Profile', value: 'editProfile' },
                    { label: 'My reviews', value: 'myReviews' },
                    { label: 'Invoices', value: 'invoices' },
                    { label: 'Settings', value: 'settings' }
                ]
            },
            {
                label: 'Contact Support',
                options: [
                    { label: 'Contact Support', value: 'contactSupport' },
                ]
            },
        ];

        return (
            <div className='custom-select-dropdown'>
                <div className='dropdown-button'>
                    <button onClick={this.toggleOpen}>
                        {selectedOption || 'Select an option'}
                    </button>
                </div>
                <div className='custom-option'>
                    {isOpen && this.renderOptionList(dropdownOptions)}
                </div>

            </div>
        );
    }
}

export default Dropdown;