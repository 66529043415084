

import React from 'react';
import _ from 'lodash';
import $ from 'jquery';
import { Link, NavLink } from 'react-router-dom';

import TopNav from './TopNav';
import { dateProcess } from '../modules/Generic';
import { retrieveJobs } from '../modules/JobAndQuoteManager';
import {
	showSpinner,
	closeSpinner,
	modal,
	alert
} from '../modules/Dialogs';
import { sessionExpired } from '../config/GeneralConfig';
import { toScreen } from '../modules/Routing';
import PropTypes from 'prop-types';
import withNavigate from './withNavigate';

class Jobs extends React.Component {

	constructor(props) {

		super(props);

		this.getJobs = this.getJobs.bind(this);
		this.getJobs_done = this.getJobs_done.bind(this);
		this.renderJobs = this.renderJobs.bind(this);
		this.searchJob = this.searchJob.bind(this);
		this.sortJobs = this.sortJobs.bind(this);
		this.filterJob = this.filterJob.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
		this.nextPage = this.nextPage.bind(this);
		this.prevPage = this.prevPage.bind(this);

		this.state = {
			search: '',
			filter: 'open',
			sort: '{"creationDate":1}',
			jobs: {},
			totals: {
				total: 0,
				open: 0,
				closed: 0,
				agreed: 0,
				paid: 0,
				complete: 0,
				refund: 0,
				transferneeded: 0
			},
			pagination: {
				page: 1,
				total: 1
			}
		}

	}

	nextPage(event) {

		if (event) {
			event.preventDefault();
		}

		const inputChangeCallback = () => {
			this.getJobs();
		}

		if (this.state.pagination.page < this.state.pagination.total) {
			this.setState({
				...this.state,
				pagination: {
					...this.state.pagination,
					page: this.state.pagination.page + 1
				}
			}, inputChangeCallback);
		}

	}

	prevPage(event) {

		if (event) {
			event.preventDefault();
		}

		const inputChangeCallback = () => {
			this.getJobs();
		}

		if (this.state.pagination.page > 1) {
			this.setState({
				...this.state,
				pagination: {
					...this.state.pagination,
					page: this.state.pagination.page - 1
				}
			}, inputChangeCallback);
		}

	}

	searchJob(event) {

		if (event) {
			event.preventDefault();
		}

		const inputChangeCallback = () => {
			this.getJobs();
		}

		this.setState({
			...this.state,
			filter: 'all',
			pagination: {
				page: 1,
				total: 1
			}
		}, inputChangeCallback);

	}

	sortJobs(event, theSort) {

		if (event) {
			event.preventDefault();
		}

		var tmpSort;

		if (theSort == 'name') {
			if (this.state.sort === '{"jobSpec.itemName":1}') {
				tmpSort = '{"jobSpec.itemName":-1}';
			} else {
				tmpSort = '{"jobSpec.itemName":1}';
			}
		} else if (theSort == 'jobdate') {
			if (this.state.sort === '{"jobSpec.dateAndTime.standardDate":1,"jobSpec.standardDate":1}') {
				tmpSort = '{"jobSpec.dateAndTime.standardDate":-1,"jobSpec.standardDate":-1}';
			} else {
				tmpSort = '{"jobSpec.dateAndTime.standardDate":1,"jobSpec.standardDate":1}';
			}
		} else if (theSort == 'location') {
			if (this.state.sort === '{"originAddress.formattedAddress":1}') {
				tmpSort = '{"originAddress.formattedAddress":-1}';
			} else {
				tmpSort = '{"originAddress.formattedAddress":1}';
			}
		} else if (theSort == 'created') {
			if (this.state.sort === '{"creationDate":1}') {
				tmpSort = '{"creationDate":-1}';
			} else {
				tmpSort = '{"creationDate":1}';
			}
		} else if (theSort == 'quotes') {
			if (this.state.sort === '{"quotes":1}') {
				tmpSort = '{"quotes":-1}';
			} else {
				tmpSort = '{"quotes":1}';
			}
		}

		const inputChangeCallback = () => {
			this.getJobs();
		}

		this.setState({
			...this.state,
			search: '',
			sort: tmpSort,
			pagination: {
				page: 1,
				total: 1
			}
		}, inputChangeCallback);


	}

	filterJob(event) {

		if (event) {
			event.preventDefault();
		}

		const inputChangeCallback = () => {
			this.getJobs();
		}

		this.setState({
			...this.state,
			search: '',
			pagination: {
				page: 1,
				total: 1
			}
		}, inputChangeCallback);


	}

	getJobs(event) {

		showSpinner();

		if (event) {
			event.preventDefault();
		}

		const theUser = {
			accountInfo: {
				username: this.props.userDetails.accountInfo.username,
				email: this.props.userDetails.accountInfo.email
			}
		}

		if (this.state.search && this.state.search != '') {
			theUser.search = this.state.search;
		}

		if (this.state.filter && this.state.filter != '' && this.state.filter != 'all') {
			theUser.filter = this.state.filter;
		}

		if (this.state.sort && this.state.sort != '') {
			theUser.sort = this.state.sort;
		}

		if (this.state.pagination.page && this.state.pagination.page != '') {
			theUser.page = this.state.pagination.page;
		}

		if (this.state.totals && this.state.totals != '') {
			theUser.totals = {};
		}

		retrieveJobs(theUser, this.getJobs_done, this.props.userDetails.jwttoken);

	}

	getJobs_done(resStatus, responseString, resJson) {

		closeSpinner();

		if (resStatus == 401) {

			modal(sessionExpired);
			toScreen(false, this.props.navigate, '/logout');

		} else if (resStatus == "400") {

			this.setState({
				...this.state,
				jobs: {},
				pagination: {
					page: 1,
					total: 1
				}
			});

			alert(responseString);

		} else if (resStatus == 200) {

			this.setState({
				...this.state,
				//search:'',
				pagination: {
					...this.state.pagination,
					total: resJson.pagination.total
				},
				jobs: resJson.theJobs,
				totals: resJson.totals
			});

		}

	}

	renderJobs(key) {
		const job = this.state.jobs[key];


		var originAddressShort = '';
		if (!job?.originAddress?.formattedAddress) {
			originAddressShort = '';
		} else if (job?.originAddress?.formattedAddress.length > 12) {
			originAddressShort = job?.originAddress?.formattedAddress.substring(0, 9) + '...';
		} else {
			originAddressShort = job?.originAddress?.formattedAddress;
		}

		var destinationAddressShort = '';
		if (!job?.destinationAddress?.formattedAddress) {
			destinationAddressShort = '';
		} else if (job?.destinationAddress?.formattedAddress.length > 12) {
			destinationAddressShort = job?.destinationAddress?.formattedAddress.substring(0, 9) + '...';
		} else {
			destinationAddressShort = job?.destinationAddress?.formattedAddress;
		}

		let userName = job._creator && job._creator.accountInfo && job._creator.accountInfo.username ? job._creator.accountInfo.username : 'N/A';

		return (
			<div className={job.isPaused ? "c-list-entry u-color-grey-dark" : "c-list-entry"} key={key}>

				<div className="c-list-entry__name">{(job.refundRequested && (!job.refundAuthorisation || job.refundAuthorisation == 'pending')) || (job.status == 'complete' && job.paymentType == "fullpayment" && (!job.mavPayment || job.mavPayment == '')) ? <b className="u-before-icon-exclamation u-color-red">&nbsp;&nbsp;{userName}</b> : job.isPaused ? <b className="u-before-icon-pause">&nbsp;&nbsp;{userName}</b> : <b>{userName}</b>}</div>
				<div className="c-list-entry__jobdate">{job?.jobSpec?.dateAndTime && job?.jobSpec?.dateAndTime?.standardDate ? dateProcess(job?.jobSpec?.dateAndTime?.standardDate, 'printDateNice') : job?.jobSpec?.dateAndTime && job?.jobSpec?.dateAndTime?.type == 'notsure' ? '(Not sure)' : job?.jobSpec?.date ? job?.jobSpec?.date : 'ASAP'} {job?.jobSpec?.dateAndTime && job?.jobSpec?.dateAndTime?.type == 'thisweek' ? '(W/C)' : null}</div>
				<div className="c-list-entry__location">{job?.originAddress?.townCityName || job?.originAddress?.regionName || job?.originAddress?.formattedAddress}{job?.jobSpec?.serviceType != 'dispose' ? ' to ' : null}{job?.destinationAddress?.townCityName || job?.destinationAddress?.regionName || job?.destinationAddress?.formattedAddress || ''}</div>
				<div className="c-list-entry__created">{job.creationDate} at {job.creationTime}</div>

				<div className="c-list-entry__quotes">
					<b>{_.size(job.quotes)}</b>
				</div>
				<button type="button" className="c-btn c-btn--teal c-btn--small" onClick={(e) => toScreen(e, this.props.navigate, "/job/" + job.short_id)}>view</button>

			</div>
		)
	}

	handleInputChange(event) {

		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;

		var oldValue = false;

		const inputChangeCallback = () => {
			if (name === 'filter' && oldValue != value) {
				this.filterJob();
			}
		}

		if (name.split(".").length == 1) {
			oldValue = this.state[name];
			this.setState({
				[name]: value
			}, inputChangeCallback);

		} else if (name.split(".").length == 2) {
			oldValue = this.state[name.split(".")[0]][name.split(".")[1]];
			this.setState({
				[name.split(".")[0]]: {
					...this.state[name.split(".")[0]],
					[name.split(".")[1]]: value
				},
			}, inputChangeCallback);

		} else if (name.split(".").length == 3) {
			oldValue = this.state[name.split(".")[0]][name.split(".")[1]][name.split(".")[2]];
			this.setState({
				[name.split(".")[0]]: {
					...this.state[name.split(".")[0]],
					[name.split(".")[1]]: {
						...this.state[name.split(".")[0]][name.split(".")[1]],
						[name.split(".")[2]]: value
					}
				},
			}, inputChangeCallback);

		}

	}

	componentWillMount() {
		//
	}

	componentDidMount() {
		this.getJobs();
	}

	componentWillUnmount() {
		//
	}

	render() {
		return (
			<div className="l-base-layout__content">

				<h1>Admin / Jobs</h1>

				<div className="c-hr"></div>

				<div className="c-subheader-info-panel">
					<div className="c-subheader-info-panel__primary">
						Total: {this.state.totals.total}
					</div>
					<div className="c-subheader-info-panel__search">
						<form onSubmit={(e) => this.searchJob(e)}>
							<input type="text" value={this.state.search} onChange={this.handleInputChange} name="search" placeholder="" className="c-subheader-info-panel__input" />
							<button type="submit" className="c-btn-outline c-btn-outline--white c-btn-outline--small">Search</button>
						</form>
					</div>
				</div>

				<div className="c-hr"></div>

				<div className="l-row">

					<div className="l-col-100 u-padding-top-0 u-padding-bottom-0">
						<div className="filter-bar">
							{/*
							<div className="c-checkbox-radio-block">
								<input type="radio" id="all" name="filter" value="all" onChange={this.handleInputChange} checked={this.state.filter === 'all'} />
								<label htmlFor="all">All non-closed</label>
							</div>
							*/}
							<div className="c-checkbox-radio-block">
								<input type="radio" id="open" name="filter" value="open" onChange={this.handleInputChange} checked={this.state.filter === 'open'} />
								<label htmlFor="open">Open ({this.state.totals.open})</label>
							</div>
							{/*
							<div className="c-checkbox-radio-block">
								<input type="radio" id="agreed" name="filter" value="agreed" onChange={this.handleInputChange} checked={this.state.filter === 'agreed'} />
								<label htmlFor="agreed">Agreed ({this.state.totals.agreed})</label>
							</div>
							*/}
							<div className="c-checkbox-radio-block">
								<input type="radio" id="paid" name="filter" value="paid" onChange={this.handleInputChange} checked={this.state.filter === 'paid'} />
								<label htmlFor="paid">Paid ({this.state.totals.paid})</label>
							</div>
							<div className="c-checkbox-radio-block">
								<input type="radio" id="complete" name="filter" value="complete" onChange={this.handleInputChange} checked={this.state.filter === 'complete'} />
								<label htmlFor="complete">Complete ({this.state.totals.complete})</label>
							</div>
							<div className="c-checkbox-radio-block">
								<input type="radio" id="closed" name="filter" value="closed" onChange={this.handleInputChange} checked={this.state.filter === 'closed'} />
								<label htmlFor="closed">Closed ({this.state.totals.closed})</label>
							</div>
							<div className="c-checkbox-radio-block">
								<input type="radio" id="refund" name="filter" value="refund" onChange={this.handleInputChange} checked={this.state.filter === 'refund'} />
								<label htmlFor="refund" className={this.state.totals.refund && this.state.totals.refund > 0 ? "u-color-red" : null}>Refund Requested ({this.state.totals.refund})</label>
							</div>

							<div className="c-checkbox-radio-block">
								<input type="radio" id="transferneeded" name="filter" value="transferneeded" onChange={this.handleInputChange} checked={this.state.filter === 'transferneeded'} />
								<label htmlFor="transferneeded" className={this.state.totals.transferneeded && this.state.totals.transferneeded > 0 ? "u-color-red" : null}>Transfer Needed ({this.state.totals.transferneeded})</label>
							</div>

						</div>

					</div>


					<div className="l-col-100">
						<div className="c-pagination">
							<button type="button" className="c-pagination__prev" onClick={(e) => this.prevPage(e)}>
								&lt;&lt; Prev
                        </button>

							{/*<div className="c-pagination__quotes"></div>*/}
							<div className="c-pagination__page-count">{this.state.pagination.page} / {this.state.pagination.total}</div>

							{/*<div className="c-pagination__quotes">Quotes</div>*/}

							<button type="button" className="c-pagination__next" onClick={(e) => this.nextPage(e)}>
								Next &gt;&gt;
                        </button>
						</div>
						<div className="c-hr u-margin-bottom-0"></div>

						<div>
							<div className="c-list-entry c-list-entry__header">
								<div className="c-list-entry__name c-list-filter"><button className={this.state.sort == '{"jobSpec.itemName":-1}' ? "c-list-filter__button c-list-filter__button--icon-up" : this.state.sort == '{"jobSpec.itemName":1}' ? "c-list-filter__button c-list-filter__button--icon-down" : "c-list-filter__button c-list-filter__button--faded"} onClick={(e) => this.sortJobs(e, 'name')}>Name</button></div>
								<div className="c-list-entry__jobdate c-list-filter"><button className={this.state.sort == '{"jobSpec.dateAndTime.standardDate":1,"jobSpec.standardDate":1}' ? "c-list-filter__button c-list-filter__button--icon-up" : this.state.sort == '{"jobSpec.dateAndTime.standardDate":-1,"jobSpec.standardDate":-1}' ? "c-list-filter__button c-list-filter__button--icon-down" : "c-list-filter__button c-list-filter__button--faded"} onClick={(e) => this.sortJobs(e, 'jobdate')}>Job date</button></div>
								<div className="c-list-entry__location c-list-filter"><button className={this.state.sort == '{"originAddress.formattedAddress":-1}' ? "c-list-filter__button c-list-filter__button--icon-up" : this.state.sort == '{"originAddress.formattedAddress":1}' ? "c-list-filter__button c-list-filter__button--icon-down" : "c-list-filter__button c-list-filter__button--faded"} onClick={(e) => this.sortJobs(e, 'location')}>Location</button></div>
								<div className="c-list-entry__created c-list-filter"><button className={this.state.sort == '{"creationDate":-1}' ? "c-list-filter__button c-list-filter__button--icon-up" : this.state.sort == '{"creationDate":1}' ? "c-list-filter__button c-list-filter__button--icon-down" : "c-list-filter__button c-list-filter__button--faded"} onClick={(e) => this.sortJobs(e, 'created')}>Created</button></div>
								<div className="c-list-entry__quotes c-list-filter"><button className={this.state.sort == '{"quotes":-1}' ? "c-list-filter__button c-list-filter__button--icon-up" : this.state.sort == '{"quotes":1}' ? "c-list-filter__button c-list-filter__button--icon-down" : "c-list-filter__button c-list-filter__button--faded"} onClick={(e) => this.sortJobs(e, 'quotes')}>Quotes</button></div>
							</div>
						</div>

						<div>
							{Object.keys(this.state.jobs).map(this.renderJobs)}
						</div>
						<div className="c-hr u-margin-bottom-0"></div>
						<div className="c-pagination">
							<button type="button" className="c-pagination__prev" onClick={(e) => this.prevPage(e)}>
								&lt;&lt; Prev
                        </button>
							{this.state.pagination.page} / {this.state.pagination.total}
							<button type="button" className="c-pagination__next" onClick={(e) => this.nextPage(e)}>
								Next &gt;&gt;
                        </button>
						</div>
					</div>



				</div>


			</div >
		);
	}


}

Jobs.contextTypes = {
	router: PropTypes.object
}

Jobs.propTypes = {
	userDetails: PropTypes.object.isRequired,
	updateState: PropTypes.func.isRequired
}


export default withNavigate(Jobs);