import React from "react";
import _ from "lodash";
import $ from "jquery";
import { getMonthlyRpt } from "../../modules/ReportsManager";
import { showSpinner, closeSpinner, modal, alert } from "../../modules/Dialogs";
import { sessionExpired } from "../../config/GeneralConfig";
import { toScreen } from "../../modules/Routing";
import PropTypes from 'prop-types';
import withNavigate from "../withNavigate";
class RptMonthly extends React.Component {
  constructor(props) {
    super(props);

    this.getReport = this.getReport.bind(this);
    this.getReport_done = this.getReport_done.bind(this);
    this.renderReport = this.renderReport.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);

    this.state = {
      crYear: new Date().getFullYear(),
      data: [],
    };
  }

  getReport(event) {
    showSpinner();

    if (event) {
      event.preventDefault();
    }

    getMonthlyRpt(
      this.state.crYear,
      this.getReport_done,
      this.props.userDetails.jwttoken
    );
  }

  getReport_done(resStatus, responseString, resJson) {
    closeSpinner();

    if (resStatus == 401) {
      modal(sessionExpired);
      toScreen(false, this.props.navigate, "/logout");
    } else if (resStatus == "400") {
      this.setState({
        ...this.state,
        data: {},
      });

      alert(responseString);
    } else if (resStatus == 200) {
      this.setState({
        ...this.state,
        data: resJson,
      });
    }
  }

  renderReport(key) {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const dataRow = this.state.data[key];

    return (
      <div className="c-list-entry" key={key}>
        <div className="c-list-entry__name">
          <b>{monthNames[dataRow.crMonth - 1]}</b>
        </div>
        <div className="c-list-entry__name">{dataRow.mavs}</div>
        <div className="c-list-entry__name">{dataRow.users}</div>
        <div className="c-list-entry__name">{dataRow.jobs}</div>
        <div className="c-list-entry__name">{dataRow.quotes}</div>
        <div className="c-list-entry__name">{dataRow.notQuoted}</div>
        <div className="c-list-entry__name">{dataRow.booked}</div>
      </div>
    );
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    var oldValue = false;

    const inputChangeCallback = () => {
      if (name === "filter" && oldValue != value) {
        this.filterQuote();
      }
    };

    if (name.split(".").length == 1) {
      oldValue = this.state[name];
      this.setState(
        {
          [name]: value,
        },
        inputChangeCallback
      );
    } else if (name.split(".").length == 2) {
      oldValue = this.state[name.split(".")[0]][name.split(".")[1]];
      this.setState(
        {
          [name.split(".")[0]]: {
            ...this.state[name.split(".")[0]],
            [name.split(".")[1]]: value,
          },
        },
        inputChangeCallback
      );
    } else if (name.split(".").length == 3) {
      oldValue =
        this.state[name.split(".")[0]][name.split(".")[1]][name.split(".")[2]];
      this.setState(
        {
          [name.split(".")[0]]: {
            ...this.state[name.split(".")[0]],
            [name.split(".")[1]]: {
              ...this.state[name.split(".")[0]][name.split(".")[1]],
              [name.split(".")[2]]: value,
            },
          },
        },
        inputChangeCallback
      );
    }
  }

  componentWillMount() {
    //
  }

  componentDidMount() {
    this.getReport();
  }

  componentWillUnmount() {
    //
  }

  render() {
    return (
      <div className="l-base-layout__content">
        <h1>Monthly Report</h1>

        <div className="c-hr"></div>

        <div className="l-row">
          <div className="l-col-100 u-padding-top-0 u-padding-bottom-0">
            <div className="c-filter-bar-2">
              <form onSubmit={(e) => this.getReport(e)}>
                <div className="item-container">
                  <label className="item-label">Year:</label>
                  <input
                    className="item-input"
                    type="text"
                    value={this.state.crYear}
                    onChange={this.handleInputChange}
                    name="crYear"
                    placeholder="Year"
                  />

                  <button type="submit" className="search-buttton">
                    Refresh
                  </button>
                </div>
              </form>
            </div>
          </div>

          <div className="l-col-75">
            <div className="c-hr u-margin-bottom-0"></div>

            <div>
              <div className="c-list-entry c-list-entry__header">
                <div className="c-list-entry__name">
                  <b>Month</b>
                </div>
                <div className="c-list-entry__name">
                  <b>Drivers</b>
                </div>
                <div className="c-list-entry__name">
                  <b>Users</b>
                </div>
                <div className="c-list-entry__name">
                  <b>Jobs</b>
                </div>
                <div className="c-list-entry__name">
                  <b>Quotes</b>
                </div>
                <div className="c-list-entry__name">
                  <b>NotQuoted</b>
                </div>
                <div className="c-list-entry__name">
                  <b>Boooked</b>
                </div>
              </div>
            </div>

            <div>{Object.keys(this.state.data).map(this.renderReport)}</div>
            <div className="c-hr u-margin-bottom-0"></div>
          </div>
        </div>
      </div>
    );
  }
}

RptMonthly.contextTypes = {
  router: PropTypes.object,
};

RptMonthly.propTypes = {
  userDetails: PropTypes.object.isRequired,
};

export default withNavigate(RptMonthly);
