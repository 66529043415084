import React from "react";
import _ from "lodash";
import $ from "jquery";
import { Link, NavLink } from "react-router-dom";
import TopNav from "./TopNav";
import { retrieveQuotes } from "../modules/JobAndQuoteManager";
import { showSpinner, closeSpinner, modal, alert } from "../modules/Dialogs";
import { sessionExpired } from "../config/GeneralConfig";
import { toScreen } from "../modules/Routing";
import PropTypes from 'prop-types';
import withNavigate from "./withNavigate";
class Quotes extends React.Component {
  constructor(props) {
    super(props);

    this.getQuotes = this.getQuotes.bind(this);
    this.getQuotes_done = this.getQuotes_done.bind(this);
    this.renderQuotes = this.renderQuotes.bind(this);
    this.searchQuote = this.searchQuote.bind(this);
    this.sortQuotes = this.sortQuotes.bind(this);
    this.filterQuote = this.filterQuote.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.nextPage = this.nextPage.bind(this);
    this.prevPage = this.prevPage.bind(this);

    this.state = {
      search: "",
      filter: "open",
      sort: '{"creationDate":1}',
      quotes: {},
      totals: {
        total: 0,
        open: 0,
        closed: 0,
        agreed: 0,
        paid: 0,
        complete: 0,
      },
      pagination: {
        page: 1,
        total: 1,
      },
    };
  }

  nextPage(event) {
    if (event) {
      event.preventDefault();
    }

    const inputChangeCallback = () => {
      this.getQuotes();
    };

    if (this.state.pagination.page < this.state.pagination.total) {
      this.setState(
        {
          ...this.state,
          pagination: {
            ...this.state.pagination,
            page: this.state.pagination.page + 1,
          },
        },
        inputChangeCallback
      );
    }
  }

  prevPage(event) {
    if (event) {
      event.preventDefault();
    }

    const inputChangeCallback = () => {
      this.getQuotes();
    };

    if (this.state.pagination.page > 1) {
      this.setState(
        {
          ...this.state,
          pagination: {
            ...this.state.pagination,
            page: this.state.pagination.page - 1,
          },
        },
        inputChangeCallback
      );
    }
  }

  searchQuote(event) {
    if (event) {
      event.preventDefault();
    }

    const inputChangeCallback = () => {
      this.getQuotes();
    };

    this.setState(
      {
        ...this.state,
        filter: "all",
        pagination: {
          page: 1,
          total: 1,
        },
      },
      inputChangeCallback
    );
  }

  sortQuotes(event, theSort) {
    if (event) {
      event.preventDefault();
    }

    var tmpSort;

    if (theSort == "name") {
      if (this.state.sort === '{"job.jobSpec.itemName":1}') {
        tmpSort = '{"job.jobSpec.itemName":-1}';
      } else {
        tmpSort = '{"job.jobSpec.itemName":1}';
      }
    } else if (theSort == "jobdate") {
      if (this.state.sort === '{"job.jobSpec.standardDate":1}') {
        tmpSort = '{"job.jobSpec.standardDate":-1}';
      } else {
        tmpSort = '{"job.jobSpec.standardDate":1}';
      }
    } else if (theSort == "location") {
      if (this.state.sort === '{"job.originAddress.formattedAddress":1}') {
        tmpSort = '{"job.originAddress.formattedAddress":-1}';
      } else {
        tmpSort = '{"job.originAddress.formattedAddress":1}';
      }
    } else if (theSort == "created") {
      if (this.state.sort === '{"creationDate":1}') {
        tmpSort = '{"creationDate":-1}';
      } else {
        tmpSort = '{"creationDate":1}';
      }
    }

    const inputChangeCallback = () => {
      this.getQuotes();
    };

    this.setState(
      {
        ...this.state,
        search: "",
        sort: tmpSort,
        pagination: {
          page: 1,
          total: 1,
        },
      },
      inputChangeCallback
    );
  }

  filterQuote(event) {
    if (event) {
      event.preventDefault();
    }

    const inputChangeCallback = () => {
      this.getQuotes();
    };

    this.setState(
      {
        ...this.state,
        search: "",
        pagination: {
          page: 1,
          total: 1,
        },
      },
      inputChangeCallback
    );
  }

  getQuotes(event) {
    showSpinner();

    if (event) {
      event.preventDefault();
    }

    const theUser = {
      accountInfo: {
        username: this.props.userDetails.accountInfo.username,
        email: this.props.userDetails.accountInfo.email,
      },
    };

    if (this.state.search && this.state.search != "") {
      theUser.search = this.state.search;
    }

    if (
      this.state.filter &&
      this.state.filter != "" &&
      this.state.filter != "all"
    ) {
      theUser.filter = this.state.filter;
    }

    if (this.state.sort && this.state.sort != "") {
      theUser.sort = this.state.sort;
    }

    if (this.state.pagination.page && this.state.pagination.page != "") {
      theUser.page = this.state.pagination.page;
    }

    if (this.state.totals && this.state.totals != "") {
      theUser.totals = {};
    }

    retrieveQuotes(
      theUser,
      this.getQuotes_done,
      this.props.userDetails.jwttoken
    );
  }

  getQuotes_done(resStatus, responseString, resJson) {
    closeSpinner();

    if (resStatus == 401) {
      modal(sessionExpired);
      toScreen(false, this.props.navigate, "/logout");
    } else if (resStatus == "400") {
      this.setState({
        ...this.state,
        quotes: {},
        pagination: {
          page: 1,
          total: 1,
        },
      });

      alert(responseString);
    } else if (resStatus == 200) {
      this.setState({
        ...this.state,
        pagination: {
          ...this.state.pagination,
          total: resJson.pagination.total,
        },
        quotes: resJson.theQuotes,
        totals: resJson.totals,
      });
    }
  }

  renderQuotes(key) {
    const quote = this.state.quotes[key];

    var originAddressShort = "";
    if (!quote.job?.originAddress?.formattedAddress) {
      originAddressShort = "";
    } else if (quote.job?.originAddress?.formattedAddress?.length > 18) {
      originAddressShort =
        quote.job?.originAddress?.formattedAddress?.substring(0, 15) + "...";
    } else {
      originAddressShort = quote.job?.originAddress?.formattedAddress;
    }

    var destinationAddressShort = "";
    if (!quote.job?.destinationAddress?.formattedAddress) {
      destinationAddressShort = "";
    } else if (quote.job?.destinationAddress?.formattedAddress.length > 18) {
      destinationAddressShort =
        quote.job?.destinationAddress?.formattedAddress.substring(0, 15) + "...";
    } else {
      destinationAddressShort = quote.job?.destinationAddress?.formattedAddress;
    }

    return (
      <div className="c-list-entry" key={key}>
        {/*
				<div className="c-list-entry__primary">
					<b>{quote._creator.accountInfo.username}</b> | {quote.job.jobSpec.date || 'ASAP'} | <b>{quote.short_id}</b> | {quote.status} {quote.status == 'open' && quote.archived ? '(archived)' : null}{quote.declined && quote.declined.reasonString ? ' (Declined)' : null} | {quote.creationDate}
				</div>
				*/}
        <div className="c-list-entry__name">
          <b>{quote._creator ? quote._creator.accountInfo.username : "-"}</b>
        </div>

        <div className="c-list-entry__jobdate">
          {" "}
          {quote.job?.jobSpec?.date || "ASAP"}
        </div>
        <div className="c-list-entry__location">
          {quote.job?.originAddress?.townCityName ||
            quote.job?.originAddress?.regionName ||
            quote.job?.originAddress?.formattedAddress}{" "}
          to{" "}
          {quote.job?.destinationAddress?.townCityName ||
            quote.job?.destinationAddress?.regionName ||
            quote.job?.destinationAddress?.formattedAddress}
        </div>
        <div className="c-list-entry__created">
          {quote.creationDate} at {quote.creationTime}
        </div>
        <button
          type="button"
          className="c-btn c-btn--teal c-btn--small"
          onClick={(e) =>
            toScreen(e, this.props.navigate, "/quote/" + quote.short_id)
          }
        >
          view
        </button>
      </div>
    );
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    var oldValue = false;

    const inputChangeCallback = () => {
      if (name === "filter" && oldValue != value) {
        this.filterQuote();
      }
    };

    if (name.split(".").length == 1) {
      oldValue = this.state[name];
      this.setState(
        {
          [name]: value,
        },
        inputChangeCallback
      );
    } else if (name.split(".").length == 2) {
      oldValue = this.state[name.split(".")[0]][name.split(".")[1]];
      this.setState(
        {
          [name.split(".")[0]]: {
            ...this.state[name.split(".")[0]],
            [name.split(".")[1]]: value,
          },
        },
        inputChangeCallback
      );
    } else if (name.split(".").length == 3) {
      oldValue =
        this.state[name.split(".")[0]][name.split(".")[1]][name.split(".")[2]];
      this.setState(
        {
          [name.split(".")[0]]: {
            ...this.state[name.split(".")[0]],
            [name.split(".")[1]]: {
              ...this.state[name.split(".")[0]][name.split(".")[1]],
              [name.split(".")[2]]: value,
            },
          },
        },
        inputChangeCallback
      );
    }
  }

  componentWillMount() {
    //
  }

  componentDidMount() {
    this.getQuotes();
  }

  componentWillUnmount() {
    //
  }

  render() {
    return (
      <div className="l-base-layout__content">
        <h1>Admin / Quotes</h1>

        <div className="c-hr"></div>

        <div className="c-subheader-info-panel">
          <div className="c-subheader-info-panel__primary">
            Total: {this.state.totals.total}
          </div>
          <div className="c-subheader-info-panel__search">
            <form onSubmit={(e) => this.searchQuote(e)}>
              <input
                type="text"
                value={this.state.search}
                onChange={this.handleInputChange}
                name="search"
                placeholder=""
                className="c-subheader-info-panel__input"
              />
              <button
                type="submit"
                className="c-btn-outline c-btn-outline--white c-btn-outline--small"
              >
                Search
              </button>
            </form>
          </div>
        </div>

        <div className="c-hr"></div>

        <div className="l-row">
          <div className="l-col-100 u-padding-top-0 u-padding-bottom-0">
            <div className="filter-bar">
              {/*
						<div className="c-checkbox-radio-block">
							<input type="radio" id="all" name="filter" value="all" onChange={this.handleInputChange} checked={this.state.filter === 'all'} />
							<label htmlFor="all">All non-closed</label>
						</div>
						*/}
              <div className="c-checkbox-radio-block">
                <input
                  type="radio"
                  id="open"
                  name="filter"
                  value="open"
                  onChange={this.handleInputChange}
                  checked={this.state.filter === "open"}
                />
                <label htmlFor="open">Open ({this.state.totals.open})</label>
              </div>
              {/*
							<div className="c-checkbox-radio-block">
								<input type="radio" id="agreed" name="filter" value="agreed" onChange={this.handleInputChange} checked={this.state.filter === 'agreed'} />
								<label htmlFor="agreed">Agreed ({this.state.totals.agreed})</label>
							</div>
							*/}
              <div className="c-checkbox-radio-block">
                <input
                  type="radio"
                  id="paid"
                  name="filter"
                  value="paid"
                  onChange={this.handleInputChange}
                  checked={this.state.filter === "paid"}
                />
                <label htmlFor="paid">Paid ({this.state.totals.paid})</label>
              </div>
              <div className="c-checkbox-radio-block">
                <input
                  type="radio"
                  id="complete"
                  name="filter"
                  value="complete"
                  onChange={this.handleInputChange}
                  checked={this.state.filter === "complete"}
                />
                <label htmlFor="complete">
                  Complete ({this.state.totals.complete})
                </label>
              </div>
              <div className="c-checkbox-radio-block">
                <input
                  type="radio"
                  id="closed"
                  name="filter"
                  value="closed"
                  onChange={this.handleInputChange}
                  checked={this.state.filter === "closed"}
                />
                <label htmlFor="closed">
                  Closed ({this.state.totals.closed})
                </label>
              </div>
            </div>
          </div>

          <div className="l-col-100">
            <div className="c-pagination">
              <button
                type="button"
                className="c-pagination__prev"
                onClick={(e) => this.prevPage(e)}
              >
                &lt;&lt; Prev
              </button>
              {this.state.pagination.page} / {this.state.pagination.total}
              <button
                type="button"
                className="c-pagination__next"
                onClick={(e) => this.nextPage(e)}
              >
                Next &gt;&gt;
              </button>
            </div>
            <div className="c-hr u-margin-bottom-0"></div>

            <div>
              <div className="c-list-entry c-list-entry__header">
                <div className="c-list-entry__name c-list-filter">
                  <button
                    className={
                      this.state.sort == '{"job.jobSpec.itemName":-1}'
                        ? "c-list-filter__button c-list-filter__button--icon-up"
                        : this.state.sort == '{"job.jobSpec.itemName":1}'
                        ? "c-list-filter__button c-list-filter__button--icon-down"
                        : "c-list-filter__button c-list-filter__button--faded"
                    }
                    onClick={(e) => this.sortQuotes(e, "name")}
                  >
                    Name
                  </button>
                </div>
                <div className="c-list-entry__jobdate c-list-filter">
                  <button
                    className={
                      this.state.sort == '{"job.jobSpec.standardDate":-1}'
                        ? "c-list-filter__button c-list-filter__button--icon-up"
                        : this.state.sort == '{"job.jobSpec.standardDate":1}'
                        ? "c-list-filter__button c-list-filter__button--icon-down"
                        : "c-list-filter__button c-list-filter__button--faded"
                    }
                    onClick={(e) => this.sortQuotes(e, "jobdate")}
                  >
                    Job date
                  </button>
                </div>
                <div className="c-list-entry__location c-list-filter">
                  <button
                    className={
                      this.state.sort ==
                      '{"job.originAddress.formattedAddress":-1}'
                        ? "c-list-filter__button c-list-filter__button--icon-up"
                        : this.state.sort ==
                          '{"job.originAddress.formattedAddress":1}'
                        ? "c-list-filter__button c-list-filter__button--icon-down"
                        : "c-list-filter__button c-list-filter__button--faded"
                    }
                    onClick={(e) => this.sortQuotes(e, "location")}
                  >
                    Location
                  </button>
                </div>
                <div className="c-list-entry__created c-list-filter">
                  <button
                    className={
                      this.state.sort == '{"creationDate":-1}'
                        ? "c-list-filter__button c-list-filter__button--icon-up"
                        : this.state.sort == '{"creationDate":1}'
                        ? "c-list-filter__button c-list-filter__button--icon-down"
                        : "c-list-filter__button c-list-filter__button--faded"
                    }
                    onClick={(e) => this.sortQuotes(e, "created")}
                  >
                    Created
                  </button>
                </div>
              </div>
            </div>

            <div>{Object.keys(this.state.quotes).map(this.renderQuotes)}</div>
            <div className="c-hr u-margin-bottom-0"></div>
            <div className="c-pagination">
              <button
                type="button"
                className="c-pagination__prev"
                onClick={(e) => this.prevPage(e)}
              >
                &lt;&lt; Prev
              </button>
              {this.state.pagination.page} / {this.state.pagination.total}
              <button
                type="button"
                className="c-pagination__next"
                onClick={(e) => this.nextPage(e)}
              >
                Next &gt;&gt;
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Quotes.contextTypes = {
  router: PropTypes.object,
};

Quotes.propTypes = {
  userDetails: PropTypes.object.isRequired,
  updateState: PropTypes.func.isRequired,
};

export default withNavigate(Quotes);
